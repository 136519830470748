import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private orderAsTemplateSource = new Subject<any>();
  currentOrderAsTemplate = this.orderAsTemplateSource.asObservable();

  constructor() {}

  changeOrderAsTemplate(value: any) {
    this.orderAsTemplateSource.next(value);
  }
}
