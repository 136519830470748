<div class="container mt-5">
  <div class="row d-flex align-items-center justify-content-center">
      <div class="card px-5 py-5">
        <div class="form-input">
          <h4 class="login-title">{{title}}</h4>
          <button class="btn btn-primary login" *ngIf="!loginDisplay" (click)="loginB2C()">Login for GLS Partners</button>
          <button class="btn btn-primary login" *ngIf="!loginDisplay" (click)="loginAD()">Login for GLS Countries</button>
        </div>
      </div>
  </div>
</div>
