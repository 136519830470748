import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CheckUserRolesService } from '../../services/check-user-roles.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  dangerAlert: boolean = false;
  title = "Landing Page"
  constructor(private router: Router,
    private checkUserRolesService: CheckUserRolesService
  ) { }

  ngOnInit(): void {
    this.dangerAlert = this.checkUserRolesService.notAuthorisedForEnvironment;
  }

  setDangerAlert(value: boolean) {
    this.dangerAlert = value;
  }

  /**closing the DangerAlert*/
  closeDangerAlert() {
    this.dangerAlert = false;
  }
}
