import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import tenantCountryRelations from './../../../files/tenantCountryRelations.json';
import validSenderRecipientTenantCombinations from './../../../files/tenantServiceCombinations.json';
import maxParcelWeightThresholds from './../../../files/maxParcelWeightThresholds.json';
import tenantTomorrowPickUpCutOffTimes from './../../../files/TenantTomorrowPickUpCutOffTimes.json';
import responsibleLocationKeyPerCountryKey from './../../../files/responsibleLocationKeyPerCountryKey.json';
import { PaxOrderService } from '../../services/pax-order.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { AuthService } from 'src/app/services/auth.service';
import { Store } from '@ngrx/store';
import { selectAccessToken } from 'src/app/store/auth.selectors';
import { CheckUserRolesService } from '../../services/check-user-roles.service';
import { Location } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-create-pax-order',
  templateUrl: './create-pax-order.component.html',
  styleUrls: ['./create-pax-order.component.scss'],
  providers: [PaxOrderService]
})
export class CreatePaxOrderComponent implements OnInit {
  public tenantsString: { tenantKey: string, countryKeys: string }[] = tenantCountryRelations;
  public validSenderRecipientTenantCombinationsString: { senderTenant: string, validRecipientTenant: string }[] = validSenderRecipientTenantCombinations;
  public maxParcelWeightThresholdsString: { tenantKey: string, maxParcelWeightThreshold: any }[] = maxParcelWeightThresholds;
  public tenantTomorrowPickUpCutOffTimesString: { tenantKey: string, tomorrowPickUpCutOffTime: any }[] = tenantTomorrowPickUpCutOffTimes;
  public responsibleLocationKeyPerCountryKeyString: { countryKey: string, responsibleLocationKey: any }[] = responsibleLocationKeyPerCountryKey;
  /**Initialization of form*/
  createOrderForm: UntypedFormGroup;
  showBackButton: boolean = false;
  incoterm = [10, 11, 12, 15, 17, 18, 20, 21, 30, 31, 33, 40, 41, 50, 60, 70, 80, 81, 83, 91, 98, 99];
  submitted = false;
  tenantKey: string;
  validMinOrderRequestedPickUpDate: any = "";
  validMaxOrderRequestedPickUpDate: any = "";
  currentTime: any = "";
  successAlert: boolean = false;
  dangerAlert: boolean = false;
  invalidAlert: boolean = false;
  invalidValidationAlert: boolean = false
  recipientCountryDisabled: boolean = false;
  errorMessage = [];
  PHONE_PATTERN: RegExp = /^(?:00|\+)[0-9\\s.\\/-]{4,}$/;
  requesterCountryList: any = [];
  senderCountryList: any = [];
  recipientCountryList: any = [];
  loginDisplay = false;
  maxParcelWeight: any = 32;
  selectedSenderTenant: string;
  selectedTenantCountryObject: any;
  selectedValidRecipientTenantsObject: any;
  validRecipientTenants: any;
  validRecipientCountrycodesOfEach: any;
  validTenantTomorrowPickUpCutOffTime: any;
  isWeekend: boolean = false;
  responsibleLocationString: any;
  paxOrder: any = [];
  paxOrderDetails: any = null;
  paxOrderData: any = null;
  paxOrderDetailsForEdit: any = null;


  orderAsTemplate: any;

  lastCreatedPaxOrderKey: any;
  slecetedPaxOrderKeyForEditOrder: string = '';
  editSelectedOrder: boolean = false;
  createNewOrder: boolean = true;
  paxOrderValdationsErrorList: any | null = '';

  //Fading popup message variables
  message: string = '';
  messageType: 'success' | 'error' = 'success';
  displayDuration: number = 3000;
  fadeDuration: number = 3000;
  accessToken$: any;

  constructor(private formBuilder: UntypedFormBuilder,
              private checkUserRolesService: CheckUserRolesService,
              private paxOrderService: PaxOrderService,
              private route: ActivatedRoute,
              private sharedService: SharedService,
              private router: Router,
              private location: Location,
              private store: Store) {
              this.accessToken$ = this.store.select(selectAccessToken);
              }

  /**Custom Validator for inputs to restrict whitespace*/
  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  public paxOrderErrorValidatior(control: UntypedFormControl) { //Only used for highlighting the wrong columns in the begining
    if (!control.parent) {
      return null;
    }
    return control.parent.dirty ? null : { 'validationError': true };
  }

  public noNationalPaxOrders(control: UntypedFormControl) {
    if (!control.parent) {
      return null;
    }
    return control.value === control.parent.get('requesterCountryCode')?.value ? { 'nationalPaxOrder': true } : null;
  }

  /**Custom Validator for inputs to restrict saturday and sunday for orderRequestedPickUpDate*/
  public weekendValidator(control: UntypedFormControl) {
    let date = new Date(control.value);
    if (date.getDay() == 0 || date.getDay() == 6) {
      const weekend = true;
      return { 'weekend': true }
    }
    return null;
  }

  ngOnInit(): void {
    this.sharedService.currentOrderAsTemplate.subscribe(orderAsTemplate => {
      this.orderAsTemplate = orderAsTemplate;
    });
    this.tenantKey = this.checkUserRolesService.tenantKey;
    this.initForm();
    this.getStandardDate();
    this.getCurrentTime();
    this.validRequesterList();
    this.validSenderList();
    this.setDefaultRequesterCountry();
    this.setDefaultSenderCountry();
    this.validRecipientList(this.senderCountryList[0]);
    this.fillFormWithEditPaxOrderData();
    this.fillFormWithStoredPaxOrderData();
    this.fillFormFromOrderDetailsTemplate();
    this.subscribeForCountryChanges();


    // Check if the user came from the "Use as template" action
    const fromTemplateAction = localStorage.getItem("fromTemplateAction");
    if (fromTemplateAction) {
      this.showBackButton = true;
      localStorage.removeItem("fromTemplateAction");
    }
  }

  // Check if requester country code changes and validate the sender country code
  private subscribeForCountryChanges() {
    this.createOrderForm.get('requesterCountryCode')?.valueChanges.subscribe(() => {
      this.createOrderForm.get('senderCountryCode')?.updateValueAndValidity();
    });
  }

  /** Function to get value of country code from Recipient Tenant and calling the getMaxWeightThreshold function */
  onChangeRecipientCountry(event: any) {
    this.getMaxWeightThreshold(event.target.value);
  }

  /** Function to get max weight threshold from Recipient Tenant */
  getMaxWeightThreshold(selectedRecipientCountry: any) {
    let selectedCountryObject = this.tenantsString.filter(tenantString => tenantString.countryKeys.includes(selectedRecipientCountry));
    let maxParcelWeightThresholdsObject = this.maxParcelWeightThresholdsString.filter(tenant => tenant.tenantKey == selectedCountryObject[0].tenantKey);
    this.maxParcelWeight = maxParcelWeightThresholdsObject[0].maxParcelWeightThreshold;
    this.addValidator(this.maxParcelWeight);
  }

  /** Function to get responsible Location per tenant key */
  getResponsibleLocationKey() {
    let responsibleLocationKeyPerCountryKeyObject = this.responsibleLocationKeyPerCountryKeyString.filter(country => country.countryKey === this.createOrderForm.controls['requesterCountryCode'].value);
    if (responsibleLocationKeyPerCountryKeyObject.length) {
      this.responsibleLocationString = responsibleLocationKeyPerCountryKeyObject[0].responsibleLocationKey;
    }
  }

  /** Function for adding max weight validator to maxParcelWeight form control */
  addValidator(maxParcelWeight) {
    this.createOrderForm.controls['maxParcelWeight'].setValidators([Validators.required, Validators.max(maxParcelWeight), Validators.min(0.00000000001)]);
    this.createOrderForm.controls['maxParcelWeight'].updateValueAndValidity();
  }

  /**Building a form model*/
  initForm() {
    this.createOrderForm = this.formBuilder.group(
      {
        requesterName: ['', Validators.compose([Validators.required, this.noWhitespaceValidator])],
        requesterStreet: ['', Validators.compose([Validators.required, this.noWhitespaceValidator])],
        requesterHouseNumber: [''],
        requesterZipCode: ['', Validators.compose([Validators.required, this.noWhitespaceValidator])],
        requesterCity: ['', Validators.compose([Validators.required, this.noWhitespaceValidator])],
        requesterCountryCode: ['', Validators.compose([Validators.required, this.noWhitespaceValidator])],
        requesterPhone: ['', Validators.compose([Validators.pattern(this.PHONE_PATTERN)])],
        requesterEmail: ['', Validators.email],
        senderName: ['', Validators.compose([Validators.required, this.noWhitespaceValidator])],
        senderStreet: ['', Validators.compose([Validators.required, this.noWhitespaceValidator])],
        senderHouseNumber: [''],
        senderZipCode: ['', Validators.compose([Validators.required, this.noWhitespaceValidator])],
        senderCity: ['', Validators.compose([Validators.required, this.noWhitespaceValidator])],
        senderCountryCode: ['', Validators.compose([Validators.required, this.noNationalPaxOrders])],
        senderContactPersonName: ['', Validators.compose([Validators.required, this.noWhitespaceValidator])],
        senderPhone: ['', Validators.compose([Validators.required, Validators.pattern(this.PHONE_PATTERN)])],
        senderEmail: ['', Validators.email],
        recipientName: ['', Validators.compose([Validators.required, this.noWhitespaceValidator])],
        maxParcelWeight: ['', this.createNewOrder ? Validators.compose([Validators.required, Validators.min(0.00000000001)]) : []],
        recipientStreet: ['', Validators.compose([Validators.required, this.noWhitespaceValidator])],
        recipientHouseNumber: [''],
        recipientZipCode: ['', Validators.compose([Validators.required, this.noWhitespaceValidator])],
        recipientCity: ['', Validators.compose([Validators.required, this.noWhitespaceValidator])],
        recipientCountryCode: ['', Validators.required],
        recipientCheckbox: [false],
        requesterReference: [''],
        orderRequestedPickUpDate: ['', Validators.compose([Validators.required, this.weekendValidator])],
        orderNumberOfParcels: ['', Validators.required],
        orderIncoterm: [''],
      },
    );

    // Update maxParcelWeight validation when form initializes or on change
    if (!this.createNewOrder) {
      this.createOrderForm.get('maxParcelWeight')?.clearValidators(); // Remove validators when not creating a new order
    }
  }

  /**orderRequestedPickUpDate functionality :
   without parameter sets standardDate to tomorrow,
   with parameter adds the toAddDays to current date.
   The result date will be yyyy-mm-dd */
   getStandardDate(toAddDays: number = 0): string {
    const date = new Date();
    date.setDate(date.getDate() + toAddDays);
    return date.toISOString().split('T')[0];
  }

  excludeWeekends = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    return day !== 0 && day !== 6;
  }

  /**Function to get current Time*/
  getCurrentTime() {
    var date: any = new Date();
    var hours: any = date.getHours();
    var minutes: any = date.getMinutes();
    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    this.currentTime = hours + ":" + minutes;
  }

  /**Function to get sender country list*/
  validSenderList() {
    const senderCountryCodeArray: any = [];
    let senderTenant = this.tenantsString.filter(tenant => this.validSenderRecipientTenantCombinationsString.some(validSenderRecipientTenant => tenant.tenantKey === validSenderRecipientTenant.senderTenant))
    senderTenant.forEach(tenant => {
      senderCountryCodeArray.push(...tenant.countryKeys)
    })
    this.senderCountryList = senderCountryCodeArray.sort();
  }

  /**Function to set Default sender country*/
  setDefaultSenderCountry() {
    this.createOrderForm.patchValue({
      senderCountryCode: ''
    })
    this.validTenantTomorrowPickUpCutOffTimes(this.senderCountryList[0]);
  }

  setDefaultRequesterCountry() {
    let tenant = this.tenantsString.find(tenant => tenant.tenantKey == this.tenantKey);
    if (tenant && tenant.countryKeys.length > 0) {
      this.createOrderForm.patchValue({
        requesterCountryCode: tenant.countryKeys[0]
      });
    }
  }

  /**Function to get requester country list*/
  validRequesterList() {
    const requesterCountryCodeArray: any = [];
    let requesterCountry = this.responsibleLocationKeyPerCountryKeyString.filter(country => country.countryKey);
    requesterCountry.forEach(country => {
      requesterCountryCodeArray.push(country.countryKey)
    })
    this.requesterCountryList = requesterCountryCodeArray.sort();
  }

  /**onChange function for sender country dropdown to get valid recipient countries & to get valid TenantTomorrowPickUpCutOffTimes*/
  onChangeSenderCountry(event: any) {
    const selectedCountry = event.target.value
    this.validRecipientList(selectedCountry);
    this.validTenantTomorrowPickUpCutOffTimes(selectedCountry);
  }

  /**Function to generate validMinOrderRequestedPickUpDate by clicking on orderRequestedPickUpDate*/
  onClickOrderRequestedPickUpDate() {
    this.validMaxOrderRequestedPickUpDate = this.getStandardDate(30);
    if (this.selectedSenderTenant == "GLS_DE") {
      if (this.currentTime <= this.validTenantTomorrowPickUpCutOffTime) {
        this.validMinOrderRequestedPickUpDate = this.getStandardDate(0);
      } else {
        this.validMinOrderRequestedPickUpDate = this.getStandardDate(1);
      }
    }
    else if (this.currentTime <= this.validTenantTomorrowPickUpCutOffTime) {
      this.validMinOrderRequestedPickUpDate = this.getStandardDate(1);
    } else {
      this.validMinOrderRequestedPickUpDate = this.getStandardDate(2);
    }
  }

  /** Getter  and Setter for date picker initial value */
  get orderRequestedPickUpDateValue() {
    return this.paxOrderData ? this.paxOrderData.requestedPickupDate : this.validMinOrderRequestedPickUpDate;
  }

  set orderRequestedPickUpDateValue(value: any) {
    if (this.paxOrderData) {
      this.paxOrderData.requestedPickupDate = value;
    } else {
      this.validMinOrderRequestedPickUpDate = value;
    }
  }

  /**Function to get valid TenantTomorrowPickUpCutOffTime*/
  validTenantTomorrowPickUpCutOffTimes(selectedSenderCountry: any) {
    let selectedTenantCountryObject = this.tenantsString.filter(tenantString => tenantString.countryKeys.includes(selectedSenderCountry));
    let selectedSenderTenant = selectedTenantCountryObject[0].tenantKey;
    let selectedValidSenderTenantsObject = this.tenantTomorrowPickUpCutOffTimesString.filter(tenantString => tenantString.tenantKey == selectedSenderTenant);
    this.validTenantTomorrowPickUpCutOffTime = selectedValidSenderTenantsObject[0].tomorrowPickUpCutOffTime;
  }

  /**Function to get recipient country list*/
  validRecipientList(selectedSenderCountry: any) {
    this.selectedTenantCountryObject = this.tenantsString.filter(tenantString => tenantString.countryKeys.includes(selectedSenderCountry));
    this.selectedSenderTenant = this.selectedTenantCountryObject[0].tenantKey;
    this.selectedValidRecipientTenantsObject = this.validSenderRecipientTenantCombinationsString.filter(tenantString => tenantString.senderTenant == this.selectedSenderTenant);
    this.validRecipientTenants = this.selectedValidRecipientTenantsObject[0].validRecipientTenant;
    this.recipientCountryList = [];
    for (var i of this.validRecipientTenants) {
      this.validRecipientCountrycodesOfEach = this.tenantsString.filter(tenantString => tenantString.tenantKey == i);
      for (var countryKey of this.validRecipientCountrycodesOfEach[0].countryKeys) {
        this.recipientCountryList.push(countryKey);
      }
    }
    this.recipientCountryList = this.recipientCountryList.sort().filter(
      (element: any, i: any) => i === this.recipientCountryList.indexOf(element)
    );
  }

  /**To get all createOrderForm controls*/
  get f(): { [key: string]: AbstractControl } {
    return this.createOrderForm.controls;
  }

  /**Function that sets the Recipient Input Fields disable*/
  setRecipientInputFieldsDisable() {
    this.createOrderForm.controls['recipientName'].disable();
    this.createOrderForm.controls['recipientStreet'].disable();
    this.createOrderForm.controls['recipientHouseNumber'].disable();
    this.createOrderForm.controls['recipientZipCode'].disable();
    this.createOrderForm.controls['recipientCity'].disable();
    this.createOrderForm.controls['recipientCountryCode'].disable();
    this.getResponsibleLocationKey();
  }

  /**Function that sets the Recipient Input Fields enable*/
  setRecipientInputFieldsEnable() {
    this.createOrderForm.controls['recipientName'].enable();
    this.createOrderForm.controls['recipientStreet'].enable();
    this.createOrderForm.controls['recipientHouseNumber'].enable();
    this.createOrderForm.controls['recipientZipCode'].enable();
    this.createOrderForm.controls['recipientCity'].enable();
    this.createOrderForm.controls['recipientCountryCode'].enable();
    this.getResponsibleLocationKey();
  }

  /**when clicking checkbox requesterEqualsRecipient, Recipient input fields will disable*/
  setRecipientEqualsRequesterCheckboxChecked() {
    if (this.createOrderForm.controls['recipientCheckbox'].value) {
      this.setRecipientInputFieldsDisable();
      this.createOrderForm.patchValue({
        recipientName: this.createOrderForm.controls['requesterName'].value,
        recipientStreet: this.createOrderForm.controls['requesterStreet'].value,
        recipientHouseNumber: this.createOrderForm.controls['requesterHouseNumber'].value,
        recipientZipCode: this.createOrderForm.controls['requesterZipCode'].value,
        recipientCity: this.createOrderForm.controls['requesterCity'].value,
        recipientCountryCode: this.createOrderForm.controls['requesterCountryCode'].value
      })
      this.checkSenderRecipientCombination();
    } else {
      this.setRecipientInputFieldsEnable();
    }
  }

  /**function to check if recipient sender combination is correct for selected requester country*/
  checkSenderRecipientCombination() {
    const recipientCountryCodeValue = this.createOrderForm.controls['recipientCountryCode'].value;
    if (this.recipientCountryList.includes(recipientCountryCodeValue)) {
      this.createOrderForm.controls['requesterCountryCode'].value
    } else {
      this.senderRecipientInvalidAlert()
    }
  }

  /**function to get Modal for Invalid Sender Recipient combination*/
  senderRecipientInvalidAlert() {
    $('#staticBackdrop').modal('show');
  }

  /**function when click ok for invalid Sender Recipient combination*/
  confirmModal() {
    this.createOrderForm.patchValue({
      recipientCheckbox: false

    })
    this.setRecipientInputFieldsEnable();
  }

  fillFormWithEditPaxOrderData() {
    this.paxOrderData = null;
    this.paxOrder = localStorage.getItem("paxOrderForEdit");
    this.paxOrderData = JSON.parse(this.paxOrder);
    if (this.paxOrderData !== null) {
      this.slecetedPaxOrderKeyForEditOrder = this.paxOrderData['paxOrderKey'];
      this.editSelectedOrder = true;
      this.createNewOrder = false;
      this.createOrderForm.patchValue({
        requesterName: this.paxOrderData.requester['name'],
        requesterStreet: this.paxOrderData.requester['address'].street,
        requesterHouseNumber: this.paxOrderData.requester['address'].houseNumber,
        requesterZipCode: this.paxOrderData.requester['address'].zipCode,
        requesterCity: this.paxOrderData.requester['address'].city,
        requesterCountryCode: this.paxOrderData.requester['address'].countryCode,
        requesterPhone: this.paxOrderData.requester['phone'],
        requesterEmail: this.paxOrderData.requester['email'],

        senderName: this.paxOrderData.sender['name'],
        senderStreet: this.paxOrderData.sender['address'].street,
        senderHouseNumber: this.paxOrderData.sender['address'].houseNumber,
        senderZipCode: this.paxOrderData.sender['address'].zipCode,
        senderCity: this.paxOrderData.sender['address'].city,
        senderCountryCode: this.paxOrderData.sender['address'].countryCode,
        senderContactPersonName: this.paxOrderData.sender['contactPerson'],
        senderPhone: this.paxOrderData.sender['phone'],
        senderEmail: this.paxOrderData.sender['email'],

        recipientName: this.paxOrderData.recipient['name'],
        recipientStreet: this.paxOrderData.recipient['address'].street,
        recipientHouseNumber: this.paxOrderData.recipient['address'].houseNumber,
        recipientZipCode: this.paxOrderData.recipient['address'].zipCode,
        recipientCity: this.paxOrderData.recipient['address'].city,
        recipientCountryCode: this.paxOrderData.recipient['address'].countryCode,

        requestedPickupDate: this.paxOrderData.requestedPickupDate,
        orderRequestedPickUpDate: new Date(this.paxOrderData.requestedPickupDate),
        requesterReference: this.paxOrderData.referenceRequester,
        incoterm: this.paxOrderData.incoterm,
        orderNumberOfParcels: this.paxOrderData.parcelCount,
      })
    }
  }

  /**function when click use Order as Template button in pax order list and append all the form values from list to inputs*/
  fillFormWithStoredPaxOrderData() {
    this.paxOrderData = null;
    this.paxOrder = localStorage.getItem("paxOrderToUseAsTemplate");
    this.paxOrderData = JSON.parse(this.paxOrder);
    if (this.paxOrderData !== null) {
      this.editSelectedOrder = false;
      this.createNewOrder = true;
      this.createOrderForm.patchValue({
        requesterName: this.paxOrderData.requester['name'],
        requesterStreet: this.paxOrderData.requester['address'].street,
        requesterHouseNumber: this.paxOrderData.requester['address'].houseNumber,
        requesterZipCode: this.paxOrderData.requester['address'].zipCode,
        requesterCity: this.paxOrderData.requester['address'].city,
        requesterCountryCode: this.paxOrderData.requester['address'].countryCode,
        requesterPhone: this.paxOrderData.requester['phone'],
        requesterEmail: this.paxOrderData.requester['email'],

        senderName: this.paxOrderData.sender['name'],
        senderStreet: this.paxOrderData.sender['address'].street,
        senderHouseNumber: this.paxOrderData.sender['address'].houseNumber,
        senderZipCode: this.paxOrderData.sender['address'].zipCode,
        senderCity: this.paxOrderData.sender['address'].city,
        senderCountryCode: this.paxOrderData.sender['address'].countryCode,
        senderContactPersonName: this.paxOrderData.sender['contactPerson'],
        senderPhone: this.paxOrderData.sender['phone'],
        senderEmail: this.paxOrderData.sender['email'],

        recipientName: this.paxOrderData.recipient['name'],
        recipientStreet: this.paxOrderData.recipient['address'].street,
        recipientHouseNumber: this.paxOrderData.recipient['address'].houseNumber,
        recipientZipCode: this.paxOrderData.recipient['address'].zipCode,
        recipientCity: this.paxOrderData.recipient['address'].city,
        recipientCountryCode: this.paxOrderData.recipient['address'].countryCode,

        requesterReference: this.paxOrderData.referenceRequester,
        incoterm: this.paxOrderData.incoterm,
        orderNumberOfParcels: this.paxOrderData.parcelCount,
      })
    }
    localStorage.removeItem("paxOrderToUseAsTemplate");
  }

  fillFormFromOrderDetailsTemplate() {
    this.paxOrderData = null;
    this.paxOrderDetailsForEdit = null;
    this.paxOrderDetails = localStorage.getItem("seePaxOrderDetails");
    if (this.paxOrderDetails) {
      this.editSelectedOrder = false;
      this.createNewOrder = true;
    }
    this.paxOrderData = JSON.parse(this.paxOrderDetails);
    if (this.paxOrderData !== null) {
      this.slecetedPaxOrderKeyForEditOrder = this.paxOrderData['paxOrderKey'];
      this.createOrderForm.patchValue({
        requesterName: this.paxOrderData.requester['name'],
        requesterStreet: this.paxOrderData.requester['address'].street,
        requesterHouseNumber: this.paxOrderData.requester['address'].houseNumber,
        requesterZipCode: this.paxOrderData.requester['address'].zipCode,
        requesterCity: this.paxOrderData.requester['address'].city,
        requesterCountryCode: this.paxOrderData.requester['address'].countryCode,
        requesterPhone: this.paxOrderData.requester['phone'],
        requesterEmail: this.paxOrderData.requester['email'],

        senderName: this.paxOrderData.sender['name'],
        senderStreet: this.paxOrderData.sender['address'].street,
        senderHouseNumber: this.paxOrderData.sender['address'].houseNumber,
        senderZipCode: this.paxOrderData.sender['address'].zipCode,
        senderCity: this.paxOrderData.sender['address'].city,
        senderCountryCode: this.paxOrderData.sender['address'].countryCode,
        senderContactPersonName: this.paxOrderData.sender['contactPerson'],
        senderPhone: this.paxOrderData.sender['phone'],
        senderEmail: this.paxOrderData.sender['email'],

        recipientName: this.paxOrderData.recipient['name'],
        recipientStreet: this.paxOrderData.recipient['address'].street,
        recipientHouseNumber: this.paxOrderData.recipient['address'].houseNumber,
        recipientZipCode: this.paxOrderData.recipient['address'].zipCode,
        recipientCity: this.paxOrderData.recipient['address'].city,
        recipientCountryCode: this.paxOrderData.recipient['address'].countryCode,

        requestedPickupDate: this.paxOrderData.requestedPickupDate,
        orderRequestedPickUpDate: new Date(this.paxOrderData.requestedPickupDate),
        requesterReference: this.paxOrderData.referenceRequester,
        incoterm: this.paxOrderData.incoterm,
        orderNumberOfParcels: this.paxOrderData.parcelCount,
      })
      if(this.editSelectedOrder) {
        this.paxOrderValdationsErrorList = JSON.parse(localStorage.getItem("paxOrderValdationsErrorList"));
        if(this.paxOrderValdationsErrorList) {
          this.invalidValidationAlert = true;
        }
        this.highlightinvalidtFields();
      }
    }
    // localStorage.removeItem("seePaxOrderDetails");
    localStorage.removeItem("paxOrderValdationsErrorList")
  }

  highlightinvalidtFields() {
    this.paxOrderValdationsErrorList['validationErrors']
      .filter(paxOrderValidationError =>
        !paxOrderValidationError['fieldName'].toLowerCase().includes('and') &&
        !paxOrderValidationError['fieldName'].toLowerCase().includes('Pick') &&
        !paxOrderValidationError['fieldName'].toLowerCase().includes('Authentication') &&
        !paxOrderValidationError['fieldName'].toLowerCase().includes('contactId')
      )
      .forEach(paxOrderValidationError => {
        const fieldName = this.transFieldNames(paxOrderValidationError['fieldName']);
        const control = this.createOrderForm.controls[fieldName];
        if (control) {
          control.addValidators(this.paxOrderErrorValidatior);
          control.updateValueAndValidity();
        } else {
          console.warn(`Control for ${fieldName} not found.`);
        }
      });
  }
  transFieldNames(input: string) : string{
    let tmp =  input.replace("recipient", "requester")
        .split(".")
        .filter(part => part !== "address")
        .map((part, index, array) => index === array.length - 1 ? part.charAt(0).toUpperCase() + part.slice(1) : part)
        .join("");
    return tmp;
  }


  formatShipItErrors(item: any): string {
    if (item['validationErrors'].length < 1) {
      return "Order is still being processed, check again later."
    }

    var formattedErrors = "The following values contain validation errors and need to be resolved: \n\n";

    return formattedErrors += item['validationErrors'].map((error: any) =>
      `${this.replaceTechnicalWithUINames(error.fieldName)}: ${error.fieldValue}`
    ).join('\n\n');
  }

  replaceTechnicalWithUINames(fieldName: string): string {
    return fieldName.replace("recipient", "requester").replace("sender", "pickup");
  }

  /**function to check the checkbox Recepient quals Requester when the both form values are eaual*/
  setRecipientEqualsRequesterChecked(paxOrderData: any) {
    if (paxOrderData.requesterName === paxOrderData.deliveryName &&
      paxOrderData.requesterStreet === paxOrderData.deliveryStreet &&
      paxOrderData.requesterHouseNumber === paxOrderData.deliveryHouseNumber &&
      paxOrderData.requesterZipCode === paxOrderData.deliveryZipCode &&
      paxOrderData.requesterCity === paxOrderData.deliveryCity &&
      paxOrderData.requesterCountry === paxOrderData.deliveryCountry) {
      this.createOrderForm.patchValue({
        recipientCheckbox: true
      })
      this.setRecipientInputFieldsDisable();
    }
  }
  /**Submitting createOderForm*/
  onSubmit(): void {
    var formData: any = this.createOrderForm.value;
    const nextOrderRequestedPickUpDate = new Date(formData.orderRequestedPickUpDate);
    const postData: any = {
      requester: {
        name: formData.requesterName ? formData.requesterName.trim() : '',
        address: {
          street: formData.requesterStreet ? formData.requesterStreet.trim() : '',
          houseNumber: formData.requesterHouseNumber ? formData.requesterHouseNumber.trim() : '',
          zipCode: formData.requesterZipCode ? formData.requesterZipCode.trim() : '',
          city: formData.requesterCity ? formData.requesterCity.trim() : '',
          countryCode: formData.requesterCountryCode,
        },
        email: formData.requesterEmail,
        phone: formData.requesterPhone,
      },
      sender: {
        name: formData.senderName ? formData.senderName.trim() : '',
        contactPerson: formData.senderContactPersonName ? formData.senderContactPersonName.trim() : '',
        address: {
          street: formData.senderStreet ? formData.senderStreet.trim() : '',
          houseNumber: formData.senderHouseNumber ? formData.senderHouseNumber.trim() : '',
          zipCode: formData.senderZipCode ? formData.senderZipCode.trim() : '',
          city: formData.senderCity ? formData.senderCity.trim() : '',
          countryCode: formData.senderCountryCode,
        },
        phone: formData.senderPhone,
        email: formData.senderEmail,
      },
      recipient: {
        name: formData.recipientCheckbox ? formData.requesterName.trim() : formData.recipientName.trim(),
        address: {
          street: formData.recipientCheckbox ? formData.requesterStreet.trim() : formData.recipientStreet.trim(),
          houseNumber: formData.recipientCheckbox ? formData.requesterHouseNumber.trim() : formData.recipientHouseNumber.trim(),
          zipCode: formData.recipientCheckbox ? formData.requesterZipCode.trim() : formData.recipientZipCode.trim(),
          city: formData.recipientCheckbox ? formData.requesterCity.trim() : formData.recipientCity.trim(),
          countryCode: formData.recipientCheckbox ? formData.requesterCountryCode : formData.recipientCountryCode,
        },
      },
      parcelCount: formData.orderNumberOfParcels,
      requestedPickupDate: formData.orderRequestedPickUpDate ? this.formatDate(nextOrderRequestedPickUpDate) : null,
      referenceRequester: typeof formData.requesterReference === 'string' ? formData.requesterReference.trim() : '',
      incoterm: formData.orderIncoterm ? formData.orderIncoterm.trim() : '',
      orderRequestLocation: this.responsibleLocationString,
    }
    this.submitted = true;
    // Ignore maxParcelWeight validation if createNewOrder is false
    if (!this.createNewOrder) {
      this.createOrderForm.get('maxParcelWeight')?.clearValidators();
      this.createOrderForm.get('maxParcelWeight')?.updateValueAndValidity(); // Update form validity after clearing validators
    }

    if (this.createOrderForm.valid) {
      if(this.createNewOrder) {
        this.paxOrderService.createOrder(postData).subscribe((response) => {
          this.dangerAlert = false;
          this.invalidAlert = false;
          this.invalidValidationAlert = false;
          this.createOrderForm.reset({
            requesterReference: '',
            orderIncoterm: ''
          });
          this.submitted = false;
          this.setRecipientEqualsRequesterCheckboxChecked();
          this.setDefaultSenderCountry();
          this.setDefaultRequesterCountry();

          this.lastCreatedPaxOrderKey = response;
          this.showFadingSuccessMessage('Order requested');
        }, errorResponse => {
          this.errorMessage = errorResponse.error.errors.map((senderCountryCode: any) => senderCountryCode)
          this.dangerAlert = true;
          this.successAlert = false;
          this.invalidAlert = false;
          this.invalidValidationAlert = false;
        });
      } else if (this.editSelectedOrder) {
        this.paxOrderService.editOrder(this.slecetedPaxOrderKeyForEditOrder, postData).subscribe((response) => {
          if (response === null) {
            this.showFadingSuccessMessage('The changes are successfully saved!');
            this.dangerAlert = false;
            this.invalidAlert = false;
            this.invalidValidationAlert = false;
            this.createOrderForm.reset({
            requesterReference: '',
            orderIncoterm: ''
          });
          this.submitted = false;
          this.setRecipientEqualsRequesterCheckboxChecked();
          this.setDefaultSenderCountry();
          this.setDefaultRequesterCountry();

          } else {
            this.showFadingErrorMessage()
          }
        }, error => {
          console.error('Error editting order:', error);
        });
      }
    } else {
      this.invalidAlert = true;
      this.successAlert = false;
      this.dangerAlert = false;
    }
  }

  /**closing the SuccessAlert*/
  closeSuccessAlert() {
    this.successAlert = false;
  }

  /**closing the DangerAlert*/
  closeDangerAlert() {
    this.dangerAlert = false;
  }

  /**closing the InvalidAlert*/
  closeInvalidAlert() {
    this.invalidAlert = false;
  }

  closeInvalidValidationAlert() {
    this.invalidValidationAlert = false;
  }

  formatDate(date: Date): string {
    let d = new Date(date.getTime() - date.getTimezoneOffset() * 60000); //Remove offset due to UTC and local time issue
    return d.toISOString().split('T')[0];
  }

  showFadingSuccessMessage(text): void {
    this.message = text;
    this.messageType = 'success';
    this.displayDuration = 10000;
    this.fadeDuration = 10000;
  }

  showFadingErrorMessage(): void {
    this.message = 'An error has occurred!';
    this.messageType = 'error';
    this.displayDuration = 10000;
    this.fadeDuration = 10000;
  }

  goBack() {
    this.location.back();
  }
}
