import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-popup-message',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './popup-message.component.html',
  styleUrl: './popup-message.component.scss'
})
export class PopupMessageComponent {
  @Input() message: string = '';
  show: boolean = false;

  showMessage(newMessage: string): void {
    this.message = newMessage;
    this.show = true;
  }

  closePopup(): void {
    this.show = false;
  }
}
