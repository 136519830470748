import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe, Location } from '@angular/common';
import { PaxOrderService } from '../../services/pax-order.service';

@Component({
  selector: 'app-edit-pax-order',
  templateUrl: './edit-pax-order.component.html',
  styleUrls: ['./edit-pax-order.component.scss'],
  providers: [DatePipe]
})
export class EditPaxOrderComponent implements OnInit {
  constructor(private router: Router,
              private datePipe: DatePipe,
              private location: Location,
              private paxOrderService: PaxOrderService) { }

  ngOnInit(): void {
    this.setFormItemsWithStoredPaxOrderData();
  }

  //Fading popup message variables
  message: string = '';
  messageType: 'success' | 'error' = 'success';
  displayDuration: number = 3000;
  fadeDuration: number = 3000;

  paxOrder: any = [];

  paxOrderKey: string = '';
  requesterName: string = '';
  requesterStreet: string = '';
  requesterHouseNumber: string = '';
  requesterZipCode: string = '';
  requesterCity: string = '';
  requesterCountryCode: string = '';
  requesterPhone: string = '';
  requesterEmail: string = '';
  senderName: string = '';
  senderStreet: string = '';
  senderHouseNumber: string = '';
  senderZipCode: string = '';
  senderCity: string = '';
  senderCountryCode: string = '';
  senderContactPersonName: string = '';
  senderPhone: string = '';
  senderEmail: string = '';
  recipientName: string = '';
  recipientStreet: string = '';
  recipientHouseNumber: string = '';
  recipientZipCode: string = '';
  recipientCity: string = '';
  recipientCountryCode: string = '';
  requestedPickUpDate: string = '';
  requesterReference: string = '';
  incoterm: string = '';
  orderNumberOfParcels: string = '';

  orderData: any = '';

  setFormItemsWithStoredPaxOrderData() {
    this.paxOrder = localStorage.getItem("seePaxOrderDetails");
    let paxOrderData = JSON.parse(this.paxOrder);
    if (paxOrderData !== null) {
      this.paxOrderKey = paxOrderData['paxOrderKey'];
      this.requesterName = paxOrderData.requester['name'];
      this.requesterStreet = paxOrderData.requester['address'].street;
      this.requesterHouseNumber = paxOrderData.requester['address'].houseNumber;
      this.requesterZipCode = paxOrderData.requester['address'].zipCode;
      this.requesterCity = paxOrderData.requester['address'].city;
      this.requesterCountryCode = paxOrderData.requester['address'].countryCode;
      this.requesterPhone = paxOrderData.requester['phone'];
      this.requesterEmail = paxOrderData.requester['email'];
      this.senderName = paxOrderData.sender['name'];
      this.senderStreet = paxOrderData.sender['address'].street;
      this.senderHouseNumber = paxOrderData.sender['address'].houseNumber;
      this.senderZipCode = paxOrderData.sender['address'].zipCode;
      this.senderCity = paxOrderData.sender['address'].city;
      this.senderCountryCode = paxOrderData.sender['address'].countryCode;
      this.senderContactPersonName = paxOrderData.sender['contactPerson'];
      this.senderPhone = paxOrderData.sender['phone'];
      this.senderEmail = paxOrderData.sender['email'];
      this.recipientName = paxOrderData.recipient['name'];
      this.recipientStreet = paxOrderData.recipient['address'].street;
      this.recipientHouseNumber = paxOrderData.recipient['address'].houseNumber;
      this.recipientZipCode = paxOrderData.recipient['address'].zipCode;
      this.recipientCity = paxOrderData.recipient['address'].city;
      this.recipientCountryCode = paxOrderData.recipient['address'].countryCode;
      this.requestedPickUpDate = paxOrderData.requestedPickupDate;
      this.requesterReference = paxOrderData.parcelDetails ? paxOrderData.parcelDetails[0]['referenceRequester'] : '';
      this.incoterm = paxOrderData.incoterm;
      this.orderNumberOfParcels = paxOrderData.parcelCount;
    }

    if (this.requestedPickUpDate) {
      this.requestedPickUpDate = this.datePipe.transform(this.requestedPickUpDate, 'dd.MM.yyyy');
    }
  }

  editOrder() {
    // Check if requesterCountryCode is the same as senderCountryCode
    if (this.requesterCountryCode === this.senderCountryCode) {
      this.showFadingErrorMessage('Requester country code cannot be the same as pickup country code.');
      return;
    }

    this.orderData = {
      requester: {
        name: this.requesterName,
        address: {
          street: this.requesterStreet,
          houseNumber: this.requesterHouseNumber,
          zipCode: this.requesterZipCode,
          city: this.requesterCity,
          countryCode: this.requesterCountryCode
        },
        email: this.requesterEmail,
        phone: this.requesterPhone
      },
      sender: {
        name: this.senderName,
        contactPerson: this.senderContactPersonName,
        address: {
          street: this.senderStreet,
          houseNumber: this.senderHouseNumber,
          zipCode: this.senderZipCode,
          city: this.senderCity,
          countryCode: this.senderCountryCode
        },
        phone: this.senderPhone,
        email: this.senderEmail
      },
      recipient: {
        name: this.recipientName,
        address: {
          street: this.recipientStreet,
          houseNumber: this.recipientHouseNumber,
          zipCode: this.recipientZipCode,
          city: this.recipientCity,
          countryCode: this.recipientCountryCode
        }
      },
      incoterm: this.incoterm
    };

    console.log(this.orderData);
    this.paxOrderService.editOrder(this.paxOrderKey, this.orderData).subscribe(response => {
      if (response === null) {
        console.log("Edited response: ", response);
        this.showFadingSuccessMessage();
      } else {
        this.showFadingErrorMessage('An error has occurred!')
      }
    }, error => {
      console.error('Error editting order:', error);
    });
  }

  goBack() {
    localStorage.removeItem("seePaxOrderDetails");
    this.location.back();
  }

  showFadingSuccessMessage(): void {
    this.message = '';
    setTimeout(() => {
      this.message = 'The changes are successfully saved!';
      this.messageType = 'success';
      this.displayDuration = 5000;
      this.fadeDuration = 5000;
    }, 100);
  }

  showFadingErrorMessage(message: string = "An error has occurred!"): void {
    this.message = '';
    setTimeout(() => {
      this.message = message;
      this.messageType = 'error';
      this.displayDuration = 5000;
      this.fadeDuration = 5000;
    }, 100);
  }
}
