import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseCommaTakeFirstElement',
  standalone: true
})
export class ParseCommaTakeFirstElementPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return '';
    const parts = value.split(',');
    return parts.length > 1 ? parts[0] : value;
  }
}
