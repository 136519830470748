import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PaxOrderService } from '../../services/pax-order.service';
import { NavigationEnd, Router } from '@angular/router';
import { CheckUserRolesService } from '../../services/check-user-roles.service';
import paxOrders from './../../../files/paxOrders.json'; import tenantCountryRelations from './../../../files/tenantCountryRelations.json';
import validSenderRecipientTenantCombinations from './../../../files/tenantServiceCombinations.json';
import { Observable, filter } from 'rxjs';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-my-pickups-view',
  templateUrl: './my-pickups-view.component.html',
  styleUrls: ['./my-pickups-view.component.scss'],
  providers: [PaxOrderService, DatePipe]
})
export class MyPickupsViewComponent implements OnInit {
  subscription: any;
  accessToken$: Observable<unknown>;

  showModalPrintAll: boolean = false;
  showModalPrintChecked: boolean = false;

  isChecked: boolean = false;
  checkAll: boolean = false;
  checkedOrdersList: any = [];
  checkedOrdersListWithDetails: any = [];

  // Reference of check-all checkbox
  @ViewChild('checkAll') checkAllRef: ElementRef;

  currentPage = 1;

  queryParameters: any = {};

  parcelNumber: string = '';
  tenantKey: string = null;
  pickUpDate: string = '';
  startDate: any | Date = '';
  endDate: any | Date = '';
  requesterName: string = '';
  requesterLocation: string = '';
  pickupLocation: string = '';
  deliveryLocation: string = ''; // TODO: double check
  orderStatus: string = 'NEW'; //Default value should be 'NEW'

  pickUpName: string = '';
  pickUpStreet: string = '';
  pickUpHouseNumber: string = '';
  pickUpZipCode: string = '';
  pickUpCity: string = '';
  pickUpCountry: string = '';

  deliveryName: string = '';
  deliveryStreet: string = '';
  deliveryHouseNumber: string = '';
  deliveryZipCode: string = '';
  deliveryCity: string = '';
  deliveryCountry: string = '';

  allowedLocations: string[] = [];

  requesterCountry: string = '';
  requesterReference: string = '';
  selectedRequestedPickupDateGt = '';
  selectedRequestedPickupDateLte: string = '';
  requestedPickupDateLte: string = '';
  requestedPickupDateGt: string = '';
  limit: number = 30;
  offset: number = 0;
  ordersList: any = [];
  adjustedFilteredOrdersList = [];
  adjustedOrder: any = {};
  totalFoundItems: number;
  dataNotLoaded: boolean = false;
  timerForTyping: any = false;

  isResizing: boolean = false;
  startingX: number = 0;
  startingWidth: number = 0;
  resizingMouseMove: any;
  resizingMouseUp: any;

  totalPages: number;

  currentPath: string = '';

  orderStatusList: string[] = ['NEW', 'IN_PROGRESS', 'PICKED_UP', 'COMPLETE', 'CANCELLED'];
  selectedStatuses: string[] = ['NEW'];
  filterChips: { key: string, value: string }[] = [];

  ordersWithCompleteStatus: any = [];
  ordersProperForPrintAllList: any = [];
  ordersProperForPrintSelectedList: any = [];
  isPrintOptionsAllowed: boolean = false;
  isGroupAdmin: boolean = false;
  deactivatePrintButtonInModalBox: boolean = false;
  authorizationFailed: boolean = false;

  expandedRows: { [key: number]: boolean } = {};


  constructor(private paxOrderService: PaxOrderService,
    private router: Router,
    private checkUserRolesService: CheckUserRolesService,
    private datePipe: DatePipe) {
  }

  ngOnInit() {
    this.loadFiltersFromLocalStorage();
    const pickupDateRemoved = localStorage.getItem('pickupDateRemoved');

    // Only set the start date if it hasn't been removed and not already set
  if (!this.selectedRequestedPickupDateGt && pickupDateRemoved !== 'true') {
    this.selectedRequestedPickupDateGt = this.getStandardDate(1);
  }

  // Don't set a default end date unless it was explicitly selected or already exists
  if (!this.selectedRequestedPickupDateLte && pickupDateRemoved !== 'true') {
    this.selectedRequestedPickupDateLte = this.getStandardDate(1);
  }

    this.requestedPickupDateGt = this.selectedRequestedPickupDateGt ? this.getStandardDate(0) : '';
    this.requestedPickupDateLte = this.selectedRequestedPickupDateLte ? this.getStandardDate(1) : '';

    this.tenantKey = this.checkUserRolesService.tenantKey;
    this.isPrintOptionsAllowed = this.checkUserRolesService.isPaxOrderPrinter;
    this.isGroupAdmin = this.checkUserRolesService.isGroupAccount;
    this.allowedLocations = this.checkUserRolesService.allowedLocationCodes;

    this.updateTable();
    this.getCurrentPath();
  }


  /** Add this method to stop event propagation */
  stopClickPropagation(event: MouseEvent): void {
    event.stopPropagation();
  }

  /** Funtion to get the current path */
  getCurrentPath() {
    this.subscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      let navigationEvent = event as NavigationEnd;
      this.currentPath = navigationEvent.url;
    });
  }

  /** Function to set query-params for search fields */
  setQueryParameters() {
    if (this.parcelNumber !== '') {
      this.queryParameters['parcel-key'] = this.parcelNumber.trim();
    }

    if (this.requestedPickupDateLte !== '') {
      this.queryParameters['requested-pickup-date-lte'] = this.requestedPickupDateLte.trim();
    }

    if (this.requestedPickupDateGt !== '') {
      this.queryParameters['requested-pickup-date-gt'] = this.requestedPickupDateGt.trim();
    }

    if (this.requesterName !== '') {
      this.queryParameters['requester-name'] = this.requesterName.trim();
    }

    if (this.requesterReference !== '') {
      this.queryParameters['requester-reference'] = this.requesterReference.trim();
    }

    if (this.requesterLocation !== '') {
      this.queryParameters['requester-location'] = this.requesterLocation.trim();
    }

    if (this.pickupLocation !== '' && this.isLocationAllowed(this.pickupLocation.trim())) {
        this.queryParameters['pickup-location'] = this.pickupLocation.trim();
    } else if (this.allowedLocations.length > 0) {
      this.queryParameters['pickup-location'] = this.allowedLocations[0];
    }

    if (this.deliveryLocation !== '') {
      this.queryParameters['delivery-location'] = this.deliveryLocation.trim();
    } else {
      this.queryParameters['delivery-location'] = ''; //Delivery Location is a required field
    }

    if (this.orderStatus !== '') {
      this.queryParameters['operational-order-status'] = this.orderStatus;
    }

    this.queryParameters['limit'] = this.limit;
    this.queryParameters['offset'] = this.offset;
    this.queryParameters['sort'] = "-upserttimestamp";
    if (!this.isGroupAdmin) {
      this.queryParameters['sender-tenant-keys'] = this.tenantKey;
    }

    this.updateDisplayFilterChips();
    this.saveFiltersToLocalStorage();
  }

  /** Function to check if search term is in allowed pickup location */
  isLocationAllowed(searchTerm: string): boolean {
    if (this.allowedLocations.length === 0) {
      return true;
    }

    let allowedSearchTerm = false;
    this.allowedLocations.forEach(location => {
      if (location.includes(searchTerm)) {
        allowedSearchTerm = true;
      }
    });
    return allowedSearchTerm;
  }

  updateDisplayFilterChips() {
    this.filterChips = [];

    const parametersToCheck = [
      { key: 'Parcel Number', value: this.parcelNumber },
      { key: 'Pick-up Date - Start', value: this.datePipe.transform(this.selectedRequestedPickupDateGt, 'dd.MM.yyyy') },
      { key: 'Pick-up Date - End', value: this.datePipe.transform(this.selectedRequestedPickupDateLte, 'dd.MM.yyyy') },
      { key: 'Pick-up Location', value: this.pickupLocation },
      { key: 'Requester Name', value: this.requesterName },
      { key: 'Requester Reference', value: this.requesterReference },
      { key: 'Requester Location', value: this.requesterLocation },
      { key: 'Delivery Location', value: this.deliveryLocation },
    ];

    // Add individual status chips
    this.selectedStatuses.forEach(status => {
      this.filterChips.push({ key: 'Order Status', value: status });
    });

    parametersToCheck.forEach(param => {
      if (param.value && param.value !== '') {
        this.filterChips.push({ key: param.key, value: param.value });
      }
    });
  }


  removeChip(chip: { key: string, value: string }) {
    switch (chip.key) {
      case 'Parcel Number':
        this.parcelNumber = '';
        break;
      case 'Pick-up Date - Start':
        this.requestedPickupDateGt = '';
        this.selectedRequestedPickupDateGt = '';
        localStorage.setItem('pickupDateRemoved', 'true');
        break;
      case 'Pick-up Date - End':
        this.requestedPickupDateLte = '';
        this.selectedRequestedPickupDateLte = '';
        localStorage.setItem('pickupDateRemoved', 'true');
        break;
      case 'Pick-up Location':
        this.pickupLocation = '';
        break;
      case 'Requester Name':
        this.requesterName = '';
        break;
      case 'Requester Reference':
        this.requesterReference = '';
        break;
      case 'Requester Location':
        this.requesterLocation = '';
        break;
      case 'Delivery Location':
        this.deliveryLocation = '';
        break;
      case 'Order Status':
        this.selectedStatuses = this.selectedStatuses.filter(status => status !== chip.value);
        this.orderStatus = this.selectedStatuses.join(',');
        break;
    }

    this.updateDisplayFilterChips();
    this.updateTable();
    this.saveFiltersToLocalStorage();
  }

  /**Function to adjust filtered ordered*/
  getAdjustedFilteredOrdersList() {
    this.adjustedFilteredOrdersList = [];
    this.checkedOrdersList = [];
    for (let order of this.ordersList) {
      const parcelDetailsExist = order.parcelDetails && order.parcelDetails.length > 0;
      this.adjustedFilteredOrdersList.push({
        paxOrderKey: order.paxOrderKey,
        parcelNumber: parcelDetailsExist ? order.parcelDetails[0]['parcelKey'] : '',
        pickUpDate: order.requestedPickupDate,
        requestedPickupDateGt: order.requestedPickupDateGt,
        requestedPickupDateLte: order.requestedPickupDateLte,
        requesterName: order.requester['name'],
        requesterReference: order['requesterReference'],
        requesterLocation: order['requesterLocation'],
        pickupLocation: order['pickupLocation'],
        deliveryLocation: order['deliveryLocation'],
        orderStatus: order['operationalOrderStatus'],

        pickUpName: order.sender['name'],
        pickUpStreet: order.sender['address'].street,
        pickUpHouseNumber: order.sender['address'].houseNumber,
        pickUpZipCode: order.sender['address'].zipCode,
        pickUpCity: order.sender['address'].city,
        pickUpCountry: order.sender['address'].countryCode,
        deliveryName: order.recipient['name'],
        deliveryStreet: order.recipient['address'].street,
        deliveryHouseNumber: order.recipient['address'].houseNumber,
        deliveryZipCode: order.recipient['address'].zipCode,
        deliveryCity: order.recipient['address'].city,
        deliveryCountry: order.recipient['address'].countryCode,
        parcelDetails: parcelDetailsExist ? order.parcelDetails : [],

        checked: this.isChecked
      });

      if (order.checked === true) {
        this.checkedOrdersList.push(this.ordersList);
      }
    }
    this.setQueryParameters();
  }

  onOrderWithMultipleParcelNumbersClick(index: number) {
    this.expandedRows[index] = !this.expandedRows[index];
  }

  getParcelStatus(item: any, parcel: string): string {
    if (!item['parcelDetails']) {
      return '';
    }
    const parcelDetail = item['parcelDetails'].find((detail: any) => detail.parcelKey === parcel);
    return parcelDetail ? parcelDetail.operationalParcelStatus : '';
  }

  /** Function to update and refresh the table by any search trigger or page change and so on... */
  updateTable(): void {
    this.currentPage = 1;
    this.queryParameters = {};
    this.setQueryParameters();
    this.queryParameters['offset'] = 0;
    this.queryParameters['limit'] = this.limit;
    console.log("params: ", this.queryParameters);
    this.offset = 0;
    this.expandedRows = {};
    this.paxOrderService.getOrders(this.queryParameters).subscribe(
      (response) => {
        this.ordersList = [];
        this.ordersList = response['data'];
        console.log("Response: ", this.ordersList);
        this.getAdjustedFilteredOrdersList();
        this.limit = response['limit'];
        this.totalFoundItems = response['total'];
        this.dataNotLoaded = false;
        this.totalPages = Math.ceil(this.totalFoundItems / this.limit);
      }, error => {
        if (error.status === 401) {
          this.authorizationFailed = true;
        }

        this.dataNotLoaded = true;
        this.ordersList = [];
        this.getAdjustedFilteredOrdersList();
        this.totalFoundItems = 0;
      });
  }

  /** Page changing events */
  pageChanged() {
    this.checkAllRef.nativeElement.checked = false;
    this.checkAll = false;
    this.onCheckAllChange(this.checkAll);
    this.offset = (this.currentPage - 1) * this.limit;
    this.queryParameters = {};
    this.queryParameters['limit'] = this.limit;
    this.queryParameters['offset'] = this.offset;
    this.limit = this.queryParameters['limit'];
    this.expandedRows = {};
    this.setQueryParameters();
    this.paxOrderService.getOrders(this.queryParameters).subscribe(
      (response) => {
        this.ordersList = [];
        this.ordersList = response['data'];
        this.getAdjustedFilteredOrdersList();
        this.limit = response['limit'];
        this.totalFoundItems = response['total'];
        this.totalPages = Math.ceil(this.totalFoundItems / this.limit);
        this.dataNotLoaded = false;
      }, error => {
        this.dataNotLoaded = true;
      });
  }

  goToPrevPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
    this.pageChanged();
  }

  goToNextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
    this.pageChanged();
  }

  /** Resizing logic */
  startResize(event: MouseEvent, th: HTMLElement): void {
    this.isResizing = true;
    this.startingX = event.pageX;
    this.startingWidth = th.offsetWidth;

    this.resizingMouseMove = this.performResize.bind(this, th);
    this.resizingMouseUp = this.stopResize.bind(this);

    document.addEventListener('mousemove', this.resizingMouseMove);
    document.addEventListener('mouseup', this.resizingMouseUp);
  }

  performResize(th: HTMLElement, event: MouseEvent): void {
    if (this.isResizing) {
      const movementX = event.pageX - this.startingX;
      th.style.width = `${this.startingWidth + movementX}px`;
    }
  }

  stopResize(): void {
    this.isResizing = false;

    if (this.resizingMouseMove) {
      document.removeEventListener('mousemove', this.resizingMouseMove);
    }
    if (this.resizingMouseUp) {
      document.removeEventListener('mouseup', this.resizingMouseUp);
    }
  }

  /**Function to trigger searching with a specific timeout. */
  onInputChange(event: any) {
    clearTimeout(this.timerForTyping);
    this.timerForTyping = setTimeout(() => {
      this.updateTable();
    }, 600);
  }


  /**Function to get pickup date input from user*/
  onChangePickUpDate() {
    this.requestedPickupDateGt = '';
    this.requestedPickupDateLte = '';

    if (this.selectedRequestedPickupDateGt) {
      this.requestedPickupDateGt = this.getPreviousDate(this.selectedRequestedPickupDateGt);
      this.requestedPickupDateGt = this.datePipe.transform(this.requestedPickupDateGt, 'yyyy-MM-dd');
    }

    if (this.selectedRequestedPickupDateLte) {
      this.requestedPickupDateLte = this.datePipe.transform(this.selectedRequestedPickupDateLte, 'yyyy-MM-dd');
    }

    this.timerForTyping = setTimeout(() => {
      this.updateTable();
    }, 600);
  }

  resetPickupDatePicker() {
    this.requestedPickupDateGt = '';
    this.requestedPickupDateLte = '';
    this.selectedRequestedPickupDateGt = '';
    this.selectedRequestedPickupDateLte = '';
    localStorage.setItem('pickupDateRemoved', 'true');
    this.updateTable();
    this.updateDisplayFilterChips();
    this.saveFiltersToLocalStorage();
  }

  /**Pick-up Date functionality :
   without parameter sets standardDate to today,
   with parameter adds the toAddDays to current date.
   The result date will be yyyy-mm-dd */
  getStandardDate(toAddDays: number = 0): string {
    let date = new Date();
    date.setDate(date.getDate() + toAddDays);

    // Adjust for weekends only if the resulting date falls on the weekend
    const dayOfWeek = date.getDay();
    if (dayOfWeek === 6) {
      // Saturday, move to Monday
      date.setDate(date.getDate() + 2);
    } else if (dayOfWeek === 0) {
      // Sunday, move to Monday
      date.setDate(date.getDate() + 1);
    }

    // Format as YYYY-MM-DD in local time (without time zone conversion)
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is 0-indexed
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  /**Function to return the previous date*/
  getPreviousDate(date: string) {
    let mainDate = new Date(date);
    let previousDay = new Date(mainDate.setDate(mainDate.getDate() - 1));
    let day: any = previousDay.getDate();
    let month: any = previousDay.getMonth() + 1;
    let year = previousDay.getFullYear();
    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }
    return year + "-" + month + "-" + day;
  }

  onCheckAllChange(isCheckAllChecked: boolean) {
    this.checkAll = isCheckAllChecked;
    this.adjustedFilteredOrdersList.forEach(item => {
      item.checked = isCheckAllChecked;
    });
    this.updateCheckedOrdersList();
  }
  /** Funtion to uncheck the check-all checkbox */
  uncheckAll(): void {
    this.checkAllRef.nativeElement.checked = false;
  }

  /** Function to distinguish which checkboxes are checked */
  onCheckboxChange(order: any, checkboxElement: HTMLInputElement): void {
    order.checked = checkboxElement.checked;
    this.updateCheckedOrdersList();
    this.uncheckAll();
  }

  /** Function to update checked orders list */
  updateCheckedOrdersList(): void {
    this.deactivatePrintButtonInModalBox = false;
    this.checkedOrdersList = this.adjustedFilteredOrdersList.filter(order => order.checked);

    const paxOrderKeys = this.checkedOrdersList.map(order => order.paxOrderKey);

    this.checkedOrdersListWithDetails = this.ordersList.filter(order =>
      paxOrderKeys.includes(order.paxOrderKey)
    );
  }

  /** Function to triger modal box for "All" orders*/
  onPrintAllOrders(): void {
    if (this.ordersList.length === 0) {
      alert('No orders to display.');
      return;
    }
    this.checkedOrdersList = [];
    this.showModalPrintAll = true;
    this.deactivatePrintButtonInModalBox = false;
  }

  /** Function to triger modal box for "selected" orders*/
  onPrintCheckedOrders(): void {
    if (this.checkedOrdersListWithDetails.length === 0) {
      alert('No orders selected.');
      return;
    }

    this.showModalPrintChecked = true;
  }

  /** Function to print the order label */
  printOrder(paxOrderKeys: Map<String, String>): void {
    const postData: any = {
      'paxOrderKeys': paxOrderKeys
    }

    this.paxOrderService.printOrders(postData).subscribe({
      next: (response: Blob) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const downloadURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = 'label.pdf';
        link.click();

        // Explicitly complete the observable
        this.messageText = 'Print is successfully done!';
        this.printSucessfull = true;
      },
      error: async (error) => {
        this.messageText = 'Print was not successfully';
        if (error instanceof HttpErrorResponse) {
          const errorResponse: HttpErrorResponse = error;
          this.messageText += ' because of ' + errorResponse.status + ' ' + errorResponse.statusText;
          this.printSucessfull = false;
        }
      },
      complete: () => {
        // This will be called if the observable completes naturally
        this.messageText = 'Print is successfully done!';
        this.printSucessfull = true;
      }
    });
  }

  /**Function to print */
  showSpinner: boolean = false;
  printSucessfull: boolean = false;
  messageVisible = false;
  fadeOut = false;
  messageText: string = '';
  spinnerDuration: number = 3000;

  onPrintAllOrdersInModalBox() {
    this.spinnerWithMessage();
    this.printOrder(this.getOrdersWithCompleteStatus(this.ordersList).ordersProperForPrintList.map(order => order.paxOrderKey))
  }

  onPrintCheckedOrdersInModalBox() {
    this.spinnerWithMessage();
    this.printOrder(this.checkedOrdersList.map(order => order.paxOrderKey));
  }

  spinnerWithMessage() {
    this.closeModal();
    this.showSpinner = true;
    setTimeout(() => {
      this.showSpinner = false;
      this.showMessage();
    }, this.spinnerDuration);
  }

  private showMessage() {
    this.messageVisible = true;
  }

  /** Function to clode modal after by clicking out of the modal */
  onBackdropClick(event: MouseEvent): void {
    this.closeModal();
  }

  /** Function to stop propagation on modal */
  onModalContentClick(event: MouseEvent): void {
    event.stopPropagation();
  }
  /** Function to close modal */
  closeModal(): void {
    this.showModalPrintAll = false;
    this.showModalPrintChecked = false;
    this.messageVisible = false;
  }

  /** Function to close modal */
  getOrdersWithCompleteStatus(orders): { ordersWithCompleteStatus, ordersProperForPrintList } {
    let ordersWithCompleteStatus = [];
    let ordersProperForPrintList = [];
    for (let order of orders) {
      if (order['operationalOrderStatus'] === 'COMPLETE') {
        ordersWithCompleteStatus.push(order);
      }
      else {
        ordersProperForPrintList.push(order);
      }
    }

    if (ordersProperForPrintList.length === 0) {
      this.deactivatePrintButtonInModalBox = true;
    }

    return { ordersWithCompleteStatus, ordersProperForPrintList }
  }

  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text).then(() => {
      console.log('Texted copied to clipboard!');
    }).catch(err => {
      console.error('Error while copying text to clipboard!: ', err);
    });
  }

  onSeeOrderDetailsClick(item: any) {
    let selectedOrder = this.ordersList.filter((order: any) => order['paxOrderKey'] === item.paxOrderKey);
    localStorage.setItem("seePaxOrderDetails", JSON.stringify(selectedOrder[0]));
    this.router.navigate(['/order-details'], { state: { fromMyPickups: true } });
  }

  onOrderStatusSelect(selectedStatuses: string[]) {
    this.selectedStatuses = selectedStatuses;
    this.orderStatus = this.selectedStatuses.join(',');
    this.updateDisplayFilterChips();
    this.updateTable();
  }

  stopEvent(event: Event) {
    event.stopPropagation();
  }

  saveFiltersToLocalStorage() {
    const filters = {
      parcelNumber: this.parcelNumber,
      selectedRequestedPickupDateGt: this.selectedRequestedPickupDateGt,
      selectedRequestedPickupDateLte: this.selectedRequestedPickupDateLte,
      requestedPickupDateGt: this.requestedPickupDateGt,
      requestedPickupDateLte: this.requestedPickupDateLte,
      requesterName: this.requesterName,
      requesterLocation: this.requesterLocation,
      pickupLocation: this.pickupLocation,
      deliveryLocation: this.deliveryLocation,
      selectedStatuses: this.selectedStatuses,
      orderStatus: this.orderStatus,
      requesterReference: this.requesterReference,
    };
    localStorage.setItem('pickupFilters', JSON.stringify(filters));
  }

  loadFiltersFromLocalStorage() {
    const storedFilters = localStorage.getItem('pickupFilters');
    if (storedFilters) {
      const filters = JSON.parse(storedFilters);
      this.parcelNumber = filters.parcelNumber || '';
      this.selectedRequestedPickupDateGt = filters.selectedRequestedPickupDateGt || '';
      this.selectedRequestedPickupDateLte = filters.selectedRequestedPickupDateLte || '';
      this.requestedPickupDateGt = filters.requestedPickupDateGt || '';
      this.requestedPickupDateLte = filters.requestedPickupDateLte || '';
      this.pickupLocation = filters.pickupLocation || '';
      this.requesterName = filters.requesterName || '';
      this.requesterReference = filters.requesterReference || '';
      this.requesterLocation = filters.requesterLocation || '';
      this.deliveryLocation = filters.deliveryLocation || '';
      this.selectedStatuses = filters.selectedStatuses || [];
      this.orderStatus = filters.orderStatus || '';
    }
  }

}
