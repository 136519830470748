export const environment = {
  production: false,
  postLogoutUrl: 'https://pax.dev.xbp.gls-group.net',
  baseUrl: 'https://api-dev.gls-group.net/pax/v1',
  names: {
    signUpSignIn: "b2c_1a_local_si"
  },
  authorities: {
    signUpSignInB2C: {
      authority: "https://glsgrouptest.b2clogin.com/glsgrouptest.onmicrosoft.com/b2c_1a_local_si",
      clientId: '87ef86e6-f11e-42cb-b8b6-e8e5ec49ce22',
      tenantId: '612ce2c0-5345-41f1-81b5-d37917851f5b'
    },
    signUpSignInAD: {
      authority: "https://login.microsoftonline.com/e6170c30-202d-4926-b525-b8b882873f3b",
      clientId: 'c6e49e29-bfba-425c-b92d-c211c7f59e3d',
      tenantId: 'e6170c30-202d-4926-b525-b8b882873f3b'
    }
  },
  authorityDomain: "https://glsgrouptest.b2clogin.com"
};
