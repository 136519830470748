import { Component, OnInit } from '@angular/core';
import { RehydrationService } from './modules/services/rehydration.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  constructor(private rehydrationService: RehydrationService) {}

  ngOnInit() {
    this.rehydrationService.rehydrateAuthState();
  }
}
