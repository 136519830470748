import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import paxOrders from './../../../files/paxOrders.json';
import tenantCountryRelations from './../../../files/tenantCountryRelations.json';
import validSenderRecipientTenantCombinations from './../../../files/tenantServiceCombinations.json';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { PeriodicElement } from '../../../interfaces/periodic-element';
import { Router } from '@angular/router';
import { PaxOrderService } from '../../services/pax-order.service';
import { CheckUserRolesService } from '../../services/check-user-roles.service';
@Component({
  selector: 'app-pax-list-orders-view',
  templateUrl: './pax-list-orders-view.component.html',
  styleUrls: ['./pax-list-orders-view.component.scss'],
  providers: [PaxOrderService]
})
export class PaxListOrdersViewComponent implements OnInit, AfterViewInit {
  constructor(private paxOrderService: PaxOrderService,
              private router: Router,
              private checkUserRolesService: CheckUserRolesService) { }

  requesterCountryList: any = [];
  senderCountryList: any = [];
  recipientCountryList: any = [];
  selectedOrder: any;

  ordersList: any = [];
  filteredOrdersList: any = [];
  adjustedFilteredOrdersList: any = [];
  queryParameters: any = {};
  limit: number = 30;
  offset: number = 0;
  totalFoundItems: number;
  pageSizeOptions: number[] = [5, 10, 15, 20, 30];
  dataNotLoaded: boolean = false;
  timerForTyping: any = false;

  requestedPickupDateLte: any | Date = '';
  requestedPickupDateGt: any | Date = '';
  insertTimeStampGt: any | Date = '';
  insertTimeStampLte: any | Date = '';

  parcelNumber: string = '';
  creationDate: string = '';
  pickUpDate: string = '';
  requesterName: string = '';
  requesterReference: string = '';
  orderStatus: string = '';
  pickupLocation: string = '';
  deliveryLocation: string = '';
  requesterLocation: string = '';

  requesterStreet: string = '';
  requesterHouseNumber: string = '';
  requesterZipCode: string = '';
  requesterCity: string = '';
  requesterCountry: string = '';
  pickUpName: string = '';
  pickUpStreet: string = '';
  pickUpHouseNumber: string = '';
  pickUpZipCode: string = '';
  pickUpCity: string = '';
  pickUpCountry: string = '';
  deliveryName: string = '';
  deliveryStreet: string = '';
  deliveryHouseNumber: string = '';
  deliveryZipCode: string = '';
  deliveryCity: string = '';
  deliveryCountry: string = '';

  currentAccessToken: string | null = '';
  quickFilterInput: string = '';
  currentPaxOrderViewer: any = [];
  isGroupAccount: boolean = false;
  isRequesterActive: boolean = false;
  isSenderActive: boolean = false;

  public paxOrdersString: {
    data: { parcelKeys: string, requester: any, sender: any, recipient: any, parcelCount: any, requestedPickupDate: any, referenceRequester: any, incoterm: any },
    limit: number,
    offset: number,
    total: number
  }[] = paxOrders;
  public tenantsString: { tenantKey: string, countryKeys: string }[] = tenantCountryRelations;
  public validSenderRecipientTenantCombinationsString: { senderTenant: string, validRecipientTenant: string }[] = validSenderRecipientTenantCombinations;

  initColumns: any[] = [
    { header: 'Parcel Number', name: 'parcelNumber' },
    { header: 'Creation Date', name: 'creationDate' },
    { header: 'Pick-Up Date', name: 'pickUpDate' },
    { header: 'Requester Name', name: 'requesterName' },
    { header: 'Requester Reference', name: 'requesterReference' },
    { header: 'Order Status', name: 'orderStatus' },
    { header: 'Pick-up Location', name: 'pickupLocation' },
    { header: 'Delivery Location', name: 'deliveryLocation' },
    { header: 'Requester Location', name: 'requesterLocation' },
  ];

  displayedColumns: any[] = this.initColumns.map(col => col.name);
  dataSource = new MatTableDataSource<PeriodicElement>(this.adjustedFilteredOrdersList);
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit() {
    this.validRequesterList();
    this.validSenderList();
    this.updateTable();
  }

  /**Function to get data for the next & previous page*/
  pageChanged(event) {
    this.queryParameters = {};
    this.offset = (event.pageIndex + 1) * event.pageSize - event.pageSize;
    this.limit = this.queryParameters['limit'] = event.pageSize;
    this.queryParameters['offset'] = this.offset;
    this.limit = this.queryParameters['limit'] = event.pageSize;
    this.dataSource = new MatTableDataSource<PeriodicElement>(this.adjustedFilteredOrdersList);
    this.setQueryParameters();
    this.paxOrderService.getOrders(this.queryParameters).subscribe(
      (response) => {
        this.ordersList = [];
        this.ordersList = response['data'];
        this.getAdjustedFilteredOrdersList();
        this.limit = response['limit'];
        this.dataSource = new MatTableDataSource<PeriodicElement>(this.adjustedFilteredOrdersList);
        this.dataSource.data = this.dataSource.filteredData;
        this.totalFoundItems = response['total'];
      }, error => {
        this.dataNotLoaded = true;
      });
  }

  /**Function to Navigate to Create Pax Order Screen*/
  goToCreatePaxOrder() {
    this.router.navigate(['/create-order']);
  }

  /**Function to go to createPaxOrder Page and use orders as a template to reduce the work by typing the data*/
  useExistingOrderAsTemplate(element: any) {
    let selectedOrder = this.ordersList.filter((order: any) => order.requester['name'].includes(element.requesterName));
    localStorage.setItem("paxOrder", JSON.stringify(selectedOrder[0]));
    this.router.navigate(['/create-order']);
  }

  /**Function to get valid requester country list*/
  validRequesterList() {
    const requesterCountryCodeArray: any = [];
    let senderTenant = this.tenantsString.filter(tenant => tenant.tenantKey);
    senderTenant.forEach(tenant => {
      requesterCountryCodeArray.push(...tenant.countryKeys)
    })
    this.requesterCountryList = requesterCountryCodeArray.sort();
  }

  /**Function to get valid sender country list*/
  validSenderList() {
    const senderCountryCodeArray: any = [];
    let senderTenant = this.tenantsString.filter(tenant => this.validSenderRecipientTenantCombinationsString.some(validSenderRecipientTenant => tenant.tenantKey === validSenderRecipientTenant.senderTenant))
    senderTenant.forEach(tenant => {
      senderCountryCodeArray.push(...tenant.countryKeys)
    })
    this.senderCountryList = senderCountryCodeArray.sort();
  }

  /**Function to get requester input country from user*/
  onChangeRequesterCountry(event: any) {
    this.requesterCountry = event.target.value;
    this.updateTable();
  }

  /**Function to get sender input country from user*/
  onChangePickUpCountry(event: any) {
    this.pickUpCountry = event.target.value;
    this.updateTable();
  }

  /**Function to get recipient input country from user*/
  onChangeDeliveryCountry(event: any) {
    this.deliveryCountry = event.target.value;
    this.updateTable();
  }

  /**Function to return the previous date*/
  getPreviousDate(date: string) {
    let mainDate = new Date(date);
    let previousDay = new Date(mainDate.setDate(mainDate.getDate() - 1));
    let day: any = previousDay.getDate();
    let month: any = previousDay.getMonth() + 1;
    let year = previousDay.getFullYear();
    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }
    return year + "-" + month + "-" + day;
  }

  /**Function to trigger searching with a specific timeout. 8 is key code for backspace and 46 for delete*/
  onInputChange(event: any, ref: any) {
    clearTimeout(this.timerForTyping);
    let key = event.keyCode || event.charCode;
    if (key == 8 || key == 46) {
      this.timerForTyping = setTimeout(()=> {
        this.updateTable();
      }, 600);
    }
    else if ((ref.dirty && event.target.value.length === 0) || event.target.value.length > 3) {
      this.timerForTyping = setTimeout(()=> {
        this.updateTable();
      }, 600);
    }
  }

  /**Function to get pickup date input from user*/
  onChangePickUpDate(event: any, ref: any) {
    this.requestedPickupDateLte = this.pickUpDate = event.target.value;
    this.requestedPickupDateGt = this.getPreviousDate(this.pickUpDate);
    this.onInputChange(event, ref);
  }

  /**Function to get order create date input from user*/
  onChangeCreationDate(event: any, ref: any) {
    this.creationDate = event.target.value;
    this.insertTimeStampLte = this.creationDate + "T23:59:59Z";
    this.insertTimeStampGt = this.getPreviousDate(this.creationDate) + "T23:59:59Z";
    this.onInputChange(event, ref);
  }

  /**Function to set the query queryParameters*/
  setQueryParameters() {
    if (this.parcelNumber !== '') {
      this.queryParameters['parcel-key'] = this.parcelNumber.trim();
    }

    if (this.creationDate !== '') {
      this.queryParameters['insert-timestamp-lte'] = this.insertTimeStampLte;
      this.queryParameters['insert-timestamp-gt'] = this.insertTimeStampGt;
    }

    if (this.pickUpDate !== '') {
      this.queryParameters['requested-pickup-date-lte'] = this.requestedPickupDateLte.trim();
      this.queryParameters['requested-pickup-date-gt'] = this.requestedPickupDateGt.trim();
    }

    if (this.requesterName !== '') {
      this.queryParameters['requester-name'] = this.requesterName.trim();
    }

    if (this.requesterReference !== '') {
      this.queryParameters['requester-reference'] = this.requesterReference.trim();
    }

    if (this.orderStatus !== '') {
      this.queryParameters['order-status'] = this.orderStatus.trim();
    }

    if (this.requesterZipCode !== '') {
      this.queryParameters['requester-zip-code'] = this.requesterZipCode.trim();
    }

    if (this.requesterCity !== '') {
      this.queryParameters['requester-city'] = this.requesterCity.trim();
    }

    if (this.requesterCountry !== '') {
      this.queryParameters['requester-country-keys'] = this.requesterCountry.trim();
    }

    if (this.pickUpName !== '') {
      this.queryParameters['sender-name'] = this.pickUpName.trim();
    }

    if (this.pickUpZipCode !== '') {
      this.queryParameters['sender-zip-code'] = this.pickUpZipCode.trim();
    }

    if (this.pickUpCity !== '') {
      this.queryParameters['sender-city'] = this.pickUpCity.trim();
    }

    if (this.pickUpCountry !== '') {
      this.queryParameters['sender-country-keys'] = this.pickUpCountry.trim();
    }

    if (this.deliveryName !== '') {
      this.queryParameters['recipient-name'] = this.deliveryName.trim();
    }

    if (this.deliveryZipCode !== '') {
      this.queryParameters['recipient-zip-code'] = this.deliveryZipCode.trim();
    }

    if (this.deliveryCity !== '') {
      this.queryParameters['recipient-city'] = this.deliveryCity.trim();
    }

    if (this.deliveryCountry !== '') {
      this.queryParameters['recipient-country-keys'] = this.deliveryCountry.trim();
    }

    if (this.quickFilterInput === 'requester' &&  this.isRequesterActive) {
      this.queryParameters['requester-tenant-keys'] = this.currentPaxOrderViewer;
    }

    if (this.quickFilterInput === 'sender' && this.isSenderActive) {
      this.queryParameters['sender-tenant-keys'] = this.currentPaxOrderViewer;
    }

    this.queryParameters['limit'] = this.limit;
    this.queryParameters['offset'] = this.offset;
    this.queryParameters['sort'] = "upserttimestamp";
  }

  /**Function to get only date from the response insertTimestamp*/
  dateOfinsertTimestamp(insertTimestamp: any) {
    return insertTimestamp ? insertTimestamp.substr(0, 10) : '';
  }

  /**Function to adjust filtered ordered*/
  getAdjustedFilteredOrdersList() {
    this.adjustedFilteredOrdersList = [];
    for (let order of this.ordersList) {
      this.adjustedFilteredOrdersList.push({
        parcelNumber: order.parcelKeys,
        creationDate: this.dateOfinsertTimestamp(order.insertTimestamp),
        pickUpDate: order.requestedPickupDate,
        requesterName: order.requester['name'],
        requesterReference: order['referenceRequester'],
        orderStatus: order['operationalOrderStatus'],
        pickupLocation: order['pickupLocation'],
        deliveryLocation: order['deliveryLocation'],
        requesterLocation: order['requesterLocation'],
        requesterStreet: order.requester['address'].street,
        requesterHouseNumber: order.requester['address'].houseNumber,
        requesterZipCode: order.requester['address'].zipCode,
        requesterCity: order.requester['address'].city,
        requesterCountry: order.requester['address'].countryCode,
        pickUpName: order.sender['name'],
        pickUpStreet: order.sender['address'].street,
        pickUpHouseNumber: order.sender['address'].houseNumber,
        pickUpZipCode: order.sender['address'].zipCode,
        pickUpCity: order.sender['address'].city,
        pickUpCountry: order.sender['address'].countryCode,
        deliveryName: order.recipient['name'],
        deliveryStreet: order.recipient['address'].street,
        deliveryHouseNumber: order.recipient['address'].houseNumber,
        deliveryZipCode: order.recipient['address'].zipCode,
        deliveryCity: order.recipient['address'].city,
        deliveryCountry: order.recipient['address'].countryCode
      });
    }

    this.setQueryParameters();
  }

  /**Function to update table */
  updateTable() {
    this.queryParameters = {};
    this.dataSource = new MatTableDataSource<PeriodicElement>(this.adjustedFilteredOrdersList);
    this.setQueryParameters();
    this.queryParameters['offset'] = 0;
    this.offset = 0;
    this.paxOrderService.getOrders(this.queryParameters).subscribe(
      (response) => {
        this.ordersList = [];
        this.ordersList = response['data'];
        this.getAdjustedFilteredOrdersList();
        this.limit = response['limit'];
        this.totalFoundItems = response['total'];
        this.paginator.pageIndex = 0;
        this.dataSource = new MatTableDataSource<PeriodicElement>(this.adjustedFilteredOrdersList);
        this.dataNotLoaded = false;
      }, error => {
        this.dataNotLoaded = true;
        this.ordersList = [];
        this.getAdjustedFilteredOrdersList();
        this.dataSource = new MatTableDataSource<PeriodicElement>(this.adjustedFilteredOrdersList);
        this.totalFoundItems = 0;
      });
  }

  /**Function for quick filter buttons */
  quickfilter(tenantRole: string){
    if(tenantRole === 'requester') {
      if(this.queryParameters['requester-tenant-keys'] === 'requester'){
        delete this.queryParameters['requester-tenant-keys'];
      }
      this.isRequesterActive = !this.isRequesterActive;
      this.isSenderActive = false;
      this.getPaxOrderViewer(tenantRole);
      this.updateTable();
    }

    if(tenantRole === 'sender') {
      if(this.queryParameters['sender-tenant-keys'] === 'sender'){
        delete this.queryParameters['sender-tenant-keys'];
      }
      this.isSenderActive = !this.isSenderActive;
      this.isRequesterActive = false;
      this.getPaxOrderViewer(tenantRole);
      this.updateTable();
    }
  }

  /**Function for getting the PaxOrderViewer from the user roles from current token */
  getPaxOrderViewer(tenantRole: string) {
    this.currentPaxOrderViewer = []
    this.currentPaxOrderViewer.push(this.checkUserRolesService.tenantKey);
    this.quickFilterInput = tenantRole;
  }
}
