import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { msalConfigB2C, protectedResources as b2cProtectedResources } from './auth-b2c-config';
import { AppComponent } from './app.component';
import { LoginComponent } from './modules/components/login/login.component';
import { HeaderComponent } from './modules/components/header/header.component';
import { NotFoundComponent } from './modules/components/not-found/not-found.component';
import { CreatePaxOrderComponent } from './modules/components/create-pax-order/create-pax-order.component';
import { PaxListOrdersViewComponent } from './modules/components/pax-list-orders-view/pax-list-orders-view.component';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './materials/material.module';
import { LandingPageComponent } from './modules/components/landing-page/landing-page.component';
import { MyPickupsViewComponent } from './modules/components/my-pickups-view/my-pickups-view.component';
import { MyOrdersViewComponent } from './modules/components/my-orders-view/my-orders-view.component';
import { DateAdapter, NativeDateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { ParseCommaTakeFirstElementPipe } from './modules/pipes/parse-comma-take-first-element.pipe';
import { MultiSelectDropdownComponent } from './modules/components/multi-select-dropdown/multi-select-dropdown.component';
import { FadingMessagePopupComponent } from './modules/components/fading-message-popup/fading-message-popup.component';
import { PopupMessageComponent } from './modules/components/popup-message/popup-message.component';
import { EditPaxOrderComponent } from './modules/components/edit-pax-order/edit-pax-order.component';
import { StoreModule } from '@ngrx/store';
import { authReducer } from './store/auth.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SessionPopupComponent } from './modules/components/session-popup/session-popup.component';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    CreatePaxOrderComponent,
    NotFoundComponent,
    PaxListOrdersViewComponent,
    LandingPageComponent,
    MyPickupsViewComponent,
    MyOrdersViewComponent,
    FadingMessagePopupComponent,
    EditPaxOrderComponent,
    SessionPopupComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    RouterModule,
    MatDatepickerModule,
    MatInputModule,
    MultiSelectDropdownComponent,
    PopupMessageComponent,
    StoreModule.forRoot({ auth: authReducer }),
    MsalModule.forRoot(new PublicClientApplication(msalConfigB2C),
      {
        /**The routing guard configuration*/
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: b2cProtectedResources.paxApi.scopes
        }
      },
      {
        /**MSAL interceptor configuration*/
        /**The protected resource mapping maps your web API with the corresponding app scopes. If your code needs to call another web API, add the URI mapping here*/
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          [b2cProtectedResources.paxApi.endpoint, b2cProtectedResources.paxApi.scopes]
        ])
      }),
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
    {provide: DateAdapter, useClass: NativeDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalGuard
  ],
  bootstrap: [
    AppComponent,
    MsalRedirectComponent
  ]
})
export class AppModule { }
