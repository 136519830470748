import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { environment } from '../environments/environment';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export const msalConfigB2C: Configuration = {
  auth: {
    clientId: environment.authorities.signUpSignInB2C.clientId,
    authority: environment.authorities.signUpSignInB2C.authority,
    knownAuthorities: [environment.authorityDomain],
    redirectUri: '/',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
    storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false
    }
  }
}

export const b2cProtectedResources = {
  paxApi: {
    endpoint: environment.postLogoutUrl,
    scopes: ['']
  }
}

export const loginRequest = {
  scopes: [''],
};

export const AuthTypeValue = 'B2C';
