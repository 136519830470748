<div class="container">
  <div *ngIf="dataNotLoaded" class="alert alert-danger  alert-dismissible fade show" role="alert">
    <strong>No orders found!</strong>
  </div>
  <table class="table">
    <thead>
      <tr>
        <th scope="col">Parcel Number</th>
        <th scope="col">Creation Date</th>
        <th scope="col">Pick-Up Date</th>
        <th scope="col">Requester Name</th>
        <th scope="col">Requester Reference</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td><input type="text" class="form-control" [(ngModel)]="parcelNumber"
            (keyup)="onInputChange($event, parcelNumberRef)" #parcelNumberRef="ngModel" (keyup.enter)="updateTable()">
        </td>
        <td><input type="date" class="form-control" ngModel (change)="onChangeCreationDate($event, CreationDateRef)"
            #CreationDateRef="ngModel">
        </td>
        <td><input type="date" class="form-control" ngModel (change)="onChangePickUpDate($event, pickUpDateRef)"
            #pickUpDateRef="ngModel"></td>
        <td><input type="text" class="form-control" [(ngModel)]="requesterName"
            (keyup)="onInputChange($event, requesterNameRef)" #requesterNameRef="ngModel" (keyup.enter)="updateTable()">
        </td>
        <td><input type="text" class="form-control" [(ngModel)]="requesterReference"
          (keyup)="onInputChange($event, requesterReferenceRef)" #requesterReferenceRef="ngModel" (keyup.enter)="updateTable()">
        </td>
      </tr>
    </tbody>
  </table>
  <table class="table">
    <thead>
      <tr>
        <th scope="col">Order Status</th>
        <th scope="col">Pick-Up Location</th>
        <th scope="col">Delivery Location</th>
        <th scope="col">Requester Location</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td><input type="text" class="form-control" [(ngModel)]="orderStatus"
          (keyup)="onInputChange($event, orderStatusRef)" #orderStatusRef="ngModel" (keyup.enter)="updateTable()">
        </td>
        <td><input type="text" class="form-control" [(ngModel)]="pickupLocation"
          (keyup)="onInputChange($event, pickupLocationRef)" #pickupLocationRef="ngModel" (keyup.enter)="updateTable()">
        </td>
        <td><input type="text" class="form-control" [(ngModel)]="deliveryLocation"
          (keyup)="onInputChange($event, deliveryLocationRef)" #deliveryLocationRef="ngModel" (keyup.enter)="updateTable()">
        </td>
        <td><input type="text" class="form-control" [(ngModel)]="requesterLocation"
          (keyup)="onInputChange($event, requesterLocationRef)" #requesterLocationRef="ngModel" (keyup.enter)="updateTable()">
        </td>
      </tr>
    </tbody>
  </table>
  <div class="d-grid gap-2 d-md-flex justify-content-md-end"><b>{{ totalFoundItems }}</b> Orders found </div>
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" class="full-width-table" matSort aria-label="Elements"
      multiTemplateDataRows>
      <ng-container [matColumnDef]="column.name" *ngFor="let column of initColumns;">
        <th mat-header-cell *matHeaderCellDef> {{ column.header }}</th>
        <td mat-cell *matCellDef="let element" id="{{ column.name }}">
          <span class="material-icons add_template_btn" *ngIf="column.name === 'parcelNumber'"
            (click)="useExistingOrderAsTemplate(element)">post_add</span>
          <ng-container *ngIf="column.name !== 'parcelNumber'">
            {{ element[column.name] }}
          </ng-container>
          <ng-container *ngIf="column.name === 'parcelNumber' && element[column.name].length === 1">
            {{ element[column.name] }}
          </ng-container>
          <ng-container *ngIf="column.name === 'parcelNumber' && element[column.name].length > 1">
            <select>
              <option *ngFor="let item of element[column.name]">{{ item }}</option>
            </select>
          </ng-container>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator #paginator [length]="totalFoundItems" [pageIndex]="0" [pageSize]="limit"
      [pageSizeOptions]="pageSizeOptions" aria-label="Select page" (page)="pageChanged($event)">
    </mat-paginator>
  </div>
  <div class="d-grid gap-2 d-md-flex justify-content-md-end">
    <button class="btn btn-primary shadow-lg my-2" (click)="goToCreatePaxOrder()">Create New Order</button>
  </div>
</div>
