<div class="feature-button-container">
  <div class="oder-details-header"><span>Order Details</span></div>
</div>
<div class="container mt-5">
  <div class="row">
    <form>
      <div class="createOrderFormContainer">
        <div class="rowContainer">
          <!--- Requester section--->
          <div class="requesterContainer">
            <div class="oderSectionsHeader requesterHeader">Requester</div>
            <div class="createOrderSectionBody requesterBody">
              <!-- Name -->
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="requesterName" class="custom-label">Name*</label>
                  <div class="inputAndErrorContainer">
                    <input id="requesterName" type="text" class="custom-input" formControlName="requesterName" value="{{requesterName}}" disabled="true">
                  </div>
                </div>
              </div>
              <!-- Street -->
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="requesterStreet" class="custom-label">Street*, House Number</label>
                  <div class="inputAndErrorContainer">
                    <input id="requesterStreet" type="text" maxlength="255" class="custom-input"
                      formControlName="requesterStreet" value="{{requesterStreet}}" disabled="true">
                  </div>
                  <div class="inputAndErrorContainer">
                    <input id="requesterHouseNumber" type="text" class="custom-input-small" formControlName="requesterHouseNumber" value="{{requesterHouseNumber}}" disabled="true">
                  </div>
                </div>
              </div>
              <!-- Zip Code, City -->
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="requesterZipCode" class="custom-label">Zip Code*, City*</label>
                  <div class="inputAndErrorContainer">
                    <input id="requesterZipCode" type="text" maxlength="255" class="custom-input-small"
                    formControlName="requesterZipCode" value="{{requesterZipCode}}" disabled="true">
                  </div>
                  <div class="inputAndErrorContainer">
                    <input id="requesterCity" type="text" maxlength="255" class="custom-input"
                    formControlName="requesterCity" value="{{requesterCity}}" disabled="true">
                  </div>
                </div>
              </div>
              <!-- Country Code -->
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="requesterCountryCode" class="custom-label">Country Code*</label>
                  <div class="inputAndErrorContainer">
                    <input id="requesterCountryCode" class="custom-input" type="tex" value="{{requesterCountryCode}}" disabled="true">
                  </div>
                </div>
              </div>
              <!-- Phone -->
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="requesterPhone" class="custom-label">Phone</label>
                  <div class="inputAndErrorContainer">
                    <input id="requesterPhone" maxlength="255" class="custom-input"
                    formControlName="requesterPhone" value="{{requesterPhone}}" disabled="true">
                  </div>
                </div>
              </div>
              <!-- Email -->
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="requesterEmail" class="custom-label">Email</label>
                  <div class="inputAndErrorContainer">
                    <input id="requesterEmail" type="text" maxlength="255" class="custom-input"
                    formControlName="requesterEmail" value="{{requesterEmail}}" disabled="true">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--- Pickup section--->
          <div class="senderContainer">
            <div class="oderSectionsHeader senderHeader">
              Pickup
            </div>
            <div class="createOrderSectionBody senderBody">
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="senderName" class="custom-label">Name*</label>
                  <div class="inputAndErrorContainer">
                    <input id="senderName" type="text" class="custom-input" formControlName="senderName"
                    value="{{senderName}}" disabled="true">
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="senderStreet" class="custom-label">Street*, House
                    Number</label>
                  <div class="inputAndErrorContainer">
                    <input id="senderStreet" type="text" maxlength="255" class="custom-input"
                    formControlName="senderStreet" value="{{senderStreet}}" disabled="true">
                  </div>
                  <div class="inputAndErrorContainer">
                    <input id="senderHouseNumber" type="text" maxlength="255" class="custom-input-small"
                    formControlName="senderHouseNumber" value="{{senderHouseNumber}}" disabled="true">
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="senderZipCode" class="custom-label">Zip Code*, City*</label>
                  <div class="inputAndErrorContainer">
                    <input id="senderZipCode" type="text" maxlength="255" class="custom-input-small"
                    formControlName="senderZipCode" value="{{senderZipCode}}" disabled="true">
                  </div>
                  <div class="inputAndErrorContainer">
                    <input id="senderCity" type="text" maxlength="255" class="custom-input"
                    formControlName="senderCity" value="{{senderCity}}" disabled="true">
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="senderCountryCode" class="custom-label">Country Code*</label>
                  <div class="inputAndErrorContainer">
                    <input id="senderCountryCode" class="custom-input" type="text" value="{{senderCountryCode}}" disabled="true">
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="contactPersonName" class="custom-label">Contact Person Name*</label>
                  <div class="inputAndErrorContainer">
                    <input id="senderContactPersonName" type="text" maxlength="255" class="custom-input"
                    formControlName="senderContactPersonName" value="{{senderContactPersonName}}" disabled="true">
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="senderPhone" class="custom-label">Phone*</label>
                  <div class="inputAndErrorContainer">
                    <input id="senderPhone" type="text" maxlength="255" class="custom-input"
                    formControlName="senderPhone" value="{{senderPhone}}" disabled="true">
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="senderEmail" class="custom-label">Email</label>
                  <div class="inputAndErrorContainer">
                    <input id="senderEmail" type="text" maxlength="255" class="custom-input"
                    formControlName="senderEmail" value="{{senderEmail}}" disabled="true">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="rowContainer">
          <!--- Delivery section--->
          <div class="recipientContainer">
            <div class="oderSectionsHeader recipientHeader">
              Delivery
            </div>
            <div class="createOrderSectionBody recipientBody">
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="recipientName" class="custom-label">Name* </label>
                  <div class="inputAndErrorContainer">
                    <input id="recipientName" type="text" maxlength="255" class="custom-input"
                    formControlName="recipientName" value="{{recipientName}}" disabled="true">
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="recipientStreet" class="custom-label">Street*, House Number</label>
                  <div class="inputAndErrorContainer">
                    <input id="recipientStreet" type="text" maxlength="255" class="custom-input"
                    formControlName="recipientStreet" value="{{recipientStreet}}" disabled="true">
                  </div>
                  <div class="inputAndErrorContainer">
                    <input id="recipientHouseNumber" type="text" maxlength="255" class="custom-input-small"
                    formControlName="recipientHouseNumber" value="{{recipientHouseNumber}}" disabled="true">
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="recipientZipCode" class="custom-label">Zip Code*,
                    City*</label>
                  <div class="inputAndErrorContainer">
                    <input id="recipientZipCode" type="text" maxlength="255" class="custom-input-small"
                    formControlName="recipientZipCode" value="{{recipientZipCode}}" disabled="true">
                  </div>
                  <div class="inputAndErrorContainer">
                    <input id="recipientCity" type="text" maxlength="255" class="custom-input"
                    formControlName="recipientCity" value="{{recipientCity}}" disabled="true">
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="recipientCountryCode" class="custom-label">Country Code*</label>
                  <div class="inputAndErrorContainer">
                    <input id="recipientCountryCode" class="custom-input" type="text" value="{{recipientCountryCode}}" disabled="true">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--- Order section --->
          <div class="orderContainer">
            <div class="oderSectionsHeader orderHeader">
              Order
            </div>
            <div class="createOrderSectionBody orderBody">
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="requestedPickUpDate" class="custom-label">Requested Pick Up Date*</label>
                  <div class="inputAndErrorContainer">
                    <input class="custom-input" type="text" value="{{requestedPickUpDate}}" disabled="true">
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="requesterReference" class="custom-label">Requester Reference</label>
                  <div class="inputAndErrorContainer">
                    <input id="requesterReference" type="text" value="{{requesterReference}}" disabled="true" maxlength="255" class="custom-input"
                    formControlName="requesterReference">
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="incoterm" class="custom-label">Incoterm</label>
                  <div class="inputAndErrorContainer">
                    <input class="custom-input" type="text" value="{{incoterm}}" disabled="true">
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="orderNumberOfParcels" class="custom-label">Number Of Parcels*</label>
                  <div class="inputAndErrorContainer">
                    <input id="orderNumberOfParcels" type="text" value="{{orderNumberOfParcels}}" disabled="true" maxlength="255" class="custom-input" formControlName="orderNumberOfParcels">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Create order button -->
      <div class="submit-container">
        <button class="btn btn-primary" type="button" (click)="goBack()">Back</button>
        <button class="btn btn-primary" *ngIf="showUseAsTemplateButton" type="button" (click)="useExistingOrderAsTemplate()">Use as template</button>
      </div>
    </form>
  </div>
</div>
