import { Component, Input, OnChanges } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-fading-message-popup',
  templateUrl: './fading-message-popup.component.html',
  styleUrls: ['./fading-message-popup.component.scss'],
  animations: [
    trigger('fadeOut', [
      state('visible', style({
        opacity: 1,
      })),
      state('hidden', style({
        opacity: 0,
      })),
      transition('visible => hidden', [
        animate('{{ fadeDuration }}ms ease-in-out')
      ], { params: { fadeDuration: 3000 } })
    ])
  ]
})
export class FadingMessagePopupComponent implements OnChanges {
  @Input() message: string = '';
  @Input() type: 'success' | 'error' = 'success';
  @Input() displayDuration: number = 3000;
  @Input() fadeDuration: number = 3000;
  show: boolean = false;

  ngOnChanges(): void {
    if (this.message) {
      this.show = true;
      setTimeout(() => {
        this.show = false;
      }, this.displayDuration);
    }
  }
}
