import { Injectable, ComponentFactoryResolver, Injector, ApplicationRef, ComponentRef } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { selectAccessToken } from '../../store/auth.selectors';
import { clearAccessToken, setAccessToken } from 'src/app/store/auth.actions';
import { MsalService } from '@azure/msal-angular';
import { protectedResources } from 'src/app/auth-b2c-config';
import { environment } from 'src/environments/environment';
import { SessionPopupComponent } from 'src/app/modules/components/session-popup/session-popup.component';
import { protectedResources as adProtectedResources, } from 'src/app/auth-ad-config';
import { protectedResources as b2cProtectedResources, } from 'src/app/auth-b2c-config';
import * as crypto from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private accessToken: string | null = null;
  private tokenExpirationTime: any = '';
  private tokenRemainingTimeInMinutes: number = 5;
  private sessionPopupRef: ComponentRef<SessionPopupComponent> | null = null;

  constructor(
    private router: Router,
    private store: Store,
    private msalService: MsalService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef
  ) {
    this.initAccessTokenSubscription();
    this.checkTokenExpirationOnInit();
    this.addVisibilityChangeListener();
  }

  /** This function returns an Observable of the current access token */
  getToken(): Observable<string | null> {
    return this.store.select(selectAccessToken);
  }

  /** This function returns an Observable of the boolean value for logged in user */
  isLoggedIn(): Observable<boolean> {
    return this.getToken().pipe(
      map(token => token !== null && token !== '')
    );
  }

  /** Initialize subscription to access token */
  private initAccessTokenSubscription(): void {
    this.store.select(selectAccessToken).pipe(
      tap(accessToken => {
        if (accessToken) {
          this.accessToken = accessToken;
          this.extractTokenExpirationTime();
        }
      })
    ).subscribe();
  }

  /** Extracts the token expiration time from the access token and stores it in local storage */
  private extractTokenExpirationTime() {
    if (this.accessToken !== null) {
      const jwtData = this.accessToken.split('.')[1];
      const decodedJwtJsonData = window.atob(jwtData);
      const decodedJwtData = JSON.parse(decodedJwtJsonData);
      this.tokenExpirationTime = decodedJwtData['exp'];
      const hashedToken = this.hashTokenExpirationTime(this.tokenExpirationTime);
      localStorage.setItem('tokenExpirationTime', this.tokenExpirationTime.toString());
      localStorage.setItem('tokenExpirationHash', hashedToken);

      const currentTime = Math.floor(Date.now() / 1000);
      const timeUntilExpiration = this.tokenExpirationTime - currentTime;

      const minutesUntilExpiration = Math.floor(timeUntilExpiration / 60);
      const secondsUntilExpiration = timeUntilExpiration % 60;
      console.log(`Token expires in ${minutesUntilExpiration} minutes and ${secondsUntilExpiration} seconds.`);

      if (timeUntilExpiration > 0) {
        setTimeout(() => {
          this.showSessionPopup(timeUntilExpiration);
        }, (timeUntilExpiration - this.tokenRemainingTimeInMinutes * 60) * 1000);
      } else {
        this.clearTokenAndRedirect();
      }
    }
  }

  /** Displays the session popup with the remaining time */
  private showSessionPopup(remainingTimeInSeconds: number) {
    const currentPopup = document.querySelector('.popup');
    if (currentPopup) return; // Prevent multiple popups

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(SessionPopupComponent);
    this.sessionPopupRef = componentFactory.create(this.injector);
    this.sessionPopupRef.instance.remainingTimeInSeconds = remainingTimeInSeconds;
    this.sessionPopupRef.instance.extendSession.subscribe(() => this.extendSession());
    this.sessionPopupRef.instance.dismissPopup.subscribe(() => this.closePopup());
    this.sessionPopupRef.instance.countdownFinished.subscribe(() => this.clearTokenAndRedirect());
    this.appRef.attachView(this.sessionPopupRef.hostView);
    document.body.appendChild((this.sessionPopupRef.hostView as any).rootNodes[0]);
  }

  private closePopup() {
    if (this.sessionPopupRef) {
      this.appRef.detachView(this.sessionPopupRef.hostView);
      this.sessionPopupRef.destroy();
      this.sessionPopupRef = null;
    }
  }

  /** Extends the session by acquiring a new token silently */
  private extendSession() {
    const tokenRequest = {
      account: this.msalService.instance.getAllAccounts()[0],
      scopes: this.isADLogin() ? adProtectedResources.adApi.scopes : b2cProtectedResources.paxApi.scopes,
      authority: this.isADLogin() ? environment.authorities.signUpSignInAD.authority : environment.authorities.signUpSignInB2C.authority
    };
    this.msalService.instance.acquireTokenSilent(tokenRequest).then(response => {
      if (response.idToken) {
        localStorage.setItem('accessToken', response.idToken);
        this.store.dispatch(setAccessToken({ accessToken: response.idToken }));
        this.extractTokenExpirationTime();
      }
    }).catch(error => {
      console.error('Error extending session:', error);
      this.clearTokenAndRedirect();
    }).finally(() => this.closePopup());
  }

  /** Clears the token and redirects to the login page */
  private clearTokenAndRedirect() {
    this.closePopup();
    this.msalService.logoutRedirect({ postLogoutRedirectUri: protectedResources.paxApi.endpoint });
    this.clearLocalAndSessionStorage();
    this.router.navigate(['/login']);
  }

  private clearLocalAndSessionStorage() {
    localStorage.clear();
    sessionStorage.clear();
    this.clearCookies();
    this.accessToken = null;
    this.store.dispatch(clearAccessToken());
  }

  /** Checks the token expiration time on application initialization */
  private checkTokenExpirationOnInit() {
    const tokenExpirationTime = localStorage.getItem('tokenExpirationTime');
    const tokenExpirationHash = localStorage.getItem('tokenExpirationHash');

    if (tokenExpirationTime && tokenExpirationHash) {
      const isValid = this.verifyTokenExpirationTime(tokenExpirationTime, tokenExpirationHash);
      const currentTime = Math.floor(Date.now() / 1000);

      if (!isValid || parseInt(tokenExpirationTime, 10) <= currentTime) {
        this.clearTokenAndRedirect();
      }
    }
  }

  /** Generates a hash of the token expiration time using SHA-256 */
  private hashTokenExpirationTime(expirationTime: number): string {
    return crypto.SHA256(expirationTime.toString()).toString();
  }

  /** Verifies the token expiration time by comparing the hash */
  private verifyTokenExpirationTime(expirationTime: string, hash: string): boolean {
    return crypto.SHA256(expirationTime).toString() === hash;
  }

  /** Adds a listener for the visibilitychange event to check token expiration when the user returns */
  private addVisibilityChangeListener() {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.checkTokenExpirationOnInit();
        if (this.sessionPopupRef) {
          this.sessionPopupRef.instance.updateCountdown();
        }
      }
    });
  }

  /** Clears the cache storage of the browser */
  async clearCacheStorage() {
    const cacheKeys = await caches.keys();
    for (const key of cacheKeys) {
      await caches.delete(key);
    }
  }

  /** Clears the cookis */
  clearCookies() {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const cookieName = cookie.split('=')[0].trim();
      document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
    }
  }

  isADLogin(): boolean {
    const authType = localStorage.getItem('auth-type');
    return authType === 'AD';
  }

}
