import { Component, OnInit, Inject } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest, EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/modules/services/auth.service';
import { Store } from '@ngrx/store';
import { setAccessToken } from 'src/app/store/auth.actions';
import { environment } from 'src/environments/environment';
import { b2cProtectedResources , AuthTypeValue as AuthTypeB2C } from 'src/app/auth-b2c-config';
import { msalConfigAd, adProtectedResources, AuthTypeValue as AuthTypeAD } from 'src/app/auth-ad-config';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  title = 'Login';
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  private readonly authType = 'auth-type';

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private broadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private router: Router,
    private authService: AuthService,
    private store: Store
  ) {
    const authType = localStorage.getItem(this.authType);

    if (authType !== null && authType === AuthTypeAD) {
      this.msalService.instance = new PublicClientApplication(msalConfigAd);
      this.msalService.instance.initialize();
    }
  }

  ngOnInit() {
    if (this.authService.isLoggedIn()) {
      this.router.navigateByUrl('/home');
    }

    this.broadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });

    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: EventMessage) => {
        if (result) {
          return this.handleAuthRedirect();
        }
      });

    /**
   * Handle the redirect flow. Ensures that handleRedirectObservable is called
   * only once, ideally before the Angular app fully initializes or before
   * the router processes any routes. This is critical to process authentication
   * responses from the redirect flow.
   */
    this.msalService.handleRedirectObservable().subscribe({
      next: (authResult) => {
        if (authResult?.account) {
          this.msalService.instance.setActiveAccount(authResult.account);
        }
        this.handleAuthRedirect();
      },
      error: (error) => console.error("Error during handleRedirectObservable:", error),
    });

  }

  private handleAuthRedirect() {
    if (this.msalService.instance.getAllAccounts()[0]) {
      const tokenRequest = {
        account: this.msalService.instance.getAllAccounts()[0],
        scopes: b2cProtectedResources.paxApi.scopes,
      };
      this.msalService.instance
        .acquireTokenSilent(tokenRequest)
        .then((response) => {
          if (response.idToken) {
            sessionStorage.setItem('accessToken', response.idToken);
            this.store.dispatch(
              setAccessToken({ accessToken: response.idToken })
            );
            this.router.navigateByUrl('/home');
          }
        })
        .catch((error) => {
          console.error('Error during handleRedirectObservable', error);
        });
    }
  }

  loginB2C() {
    localStorage.clear();
    sessionStorage.clear();
    this.authService.clearCacheStorage();
    this.authService.clearCookies();
    localStorage.setItem(this.authType, AuthTypeB2C);

    const b2cAuthRequest: RedirectRequest = {
      authority: environment.authorities.signUpSignInB2C.authority,
      scopes: b2cProtectedResources.paxApi.scopes,
    };

    if (this.msalGuardConfig.authRequest) {
      this.msalService.instance.loginRedirect({
        ...this.msalGuardConfig.authRequest,
        ...b2cAuthRequest,
      });
    } else {
      this.msalService.instance.loginRedirect(b2cAuthRequest);
    }
  }

  async loginAD() {
    localStorage.clear();
    sessionStorage.clear();
    this.authService.clearCacheStorage();
    this.authService.clearCookies();
    localStorage.setItem(this.authType, AuthTypeAD);

    this.msalService.instance = new PublicClientApplication(msalConfigAd);

    const adAuthRequest: RedirectRequest = {
      authority: environment.authorities.signUpSignInAD.authority,
      scopes: adProtectedResources.adApi.scopes,
    };

    this.msalService.instance = new PublicClientApplication(msalConfigAd);
    await this.msalService.instance.initialize();
    await this.msalService.instance.loginRedirect(adAuthRequest);

    // Check if an interaction is already in progress
    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.msalService.instance.loginRedirect(adAuthRequest);
    });
  }

  setLoginDisplay() {
    this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
