import { Component, OnInit, ChangeDetectorRef, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CommonModule, DatePipe, Location } from '@angular/common';
import { ParseCommaTakeFirstElementPipe } from '../../pipes/parse-comma-take-first-element.pipe';
import { filter, pairwise } from 'rxjs';
import { CheckUserRolesService } from '../../services/check-user-roles.service';

@Component({
  selector: 'app-order-details',
  standalone: true,
  imports: [CommonModule, ParseCommaTakeFirstElementPipe],
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
  providers: [DatePipe, ParseCommaTakeFirstElementPipe, CommonModule]
})
export class OrderDetailsComponent implements OnInit, AfterViewInit  {
  @ViewChild('commentTextarea', { static: false }) commentTextarea!: ElementRef;
  @ViewChild('partnerParcelNumber', { static: false }) partnerParcelNumberTextarea!: ElementRef;
  showUseAsTemplateButton: boolean = true;
  previousUrl: string = '';
  currentUrl: string = '';

  paxOrder: any = [];

  requesterName: string = '';
  requesterStreet: string = '';
  requesterHouseNumber: string = '';
  requesterZipCode: string = '';
  requesterCity: string = '';
  requesterCountryCode: string = '';
  requesterPhone: string = '';
  requesterEmail: string = '';
  senderName: string = '';
  senderStreet: string = '';
  senderHouseNumber: string = '';
  senderZipCode: string = '';
  senderCity: string = '';
  senderCountryCode: string = '';
  senderContactPersonName: string = '';
  senderPhone: string = '';
  senderEmail: string = '';
  senderComment: string = '';
  recipientName: string = '';
  recipientStreet: string = '';
  recipientHouseNumber: string = '';
  recipientZipCode: string = '';
  recipientCity: string = '';
  recipientCountryCode: string = '';
  requestedPickUpDate: string = '';
  requesterReference: string = '';
  incoterm: string = '';
  orderNumberOfParcels: string = '';
  partnerParcelNumbers: string = '';
  paxOrderKey: string = '';
  isPaxOrderCreator: boolean = false;

  constructor(private router: Router,
              private datePipe: DatePipe,
              private checkUserRolesService: CheckUserRolesService,
              private location: Location) {

    // Immediate check in constructor
    const currentState = this.router.getCurrentNavigation();
    if (currentState && currentState.previousNavigation && currentState.previousNavigation.finalUrl) {
      this.previousUrl = currentState.previousNavigation.finalUrl.toString();
      if (this.previousUrl.includes('/my-pickups')) {
        this.showUseAsTemplateButton = false;
      }
    }
  }

  ngOnInit(): void {
    this.setFormItemsWithStoredPaxOrderData();
    this.isPaxOrderCreator = this.checkUserRolesService.getIsPaxOrderCreator();
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.adjustTextareaHeight(), 50);
  }

  /** Function to populate the form with stored Pax order data */
  setFormItemsWithStoredPaxOrderData() {
    this.paxOrder = localStorage.getItem("seePaxOrderDetails");
    let paxOrderData = JSON.parse(this.paxOrder);
    if (paxOrderData !== null) {
      this.paxOrderKey = paxOrderData.paxOrderKey;
      this.requesterName = paxOrderData.requester['name'];
      this.requesterStreet = paxOrderData.requester['address'].street;
      this.requesterHouseNumber = paxOrderData.requester['address'].houseNumber;
      this.requesterZipCode = paxOrderData.requester['address'].zipCode;
      this.requesterCity = paxOrderData.requester['address'].city;
      this.requesterCountryCode = paxOrderData.requester['address'].countryCode;
      this.requesterPhone = paxOrderData.requester['phone'];
      this.requesterEmail = paxOrderData.requester['email'];
      this.senderName = paxOrderData.sender['name'];
      this.senderStreet = paxOrderData.sender['address'].street;
      this.senderHouseNumber = paxOrderData.sender['address'].houseNumber;
      this.senderZipCode = paxOrderData.sender['address'].zipCode;
      this.senderCity = paxOrderData.sender['address'].city;
      this.senderCountryCode = paxOrderData.sender['address'].countryCode;
      this.senderContactPersonName = paxOrderData.sender['contactPerson'];
      this.senderPhone = paxOrderData.sender['phone'];
      this.senderEmail = paxOrderData.sender['email'];
      this.senderComment = paxOrderData.parcelDetails?.length > 0 ? paxOrderData.parcelDetails[0]['comment'] : '';
      this.recipientName = paxOrderData.recipient['name'];
      this.recipientStreet = paxOrderData.recipient['address'].street;
      this.recipientHouseNumber = paxOrderData.recipient['address'].houseNumber;
      this.recipientZipCode = paxOrderData.recipient['address'].zipCode;
      this.recipientCity = paxOrderData.recipient['address'].city;
      this.recipientCountryCode = paxOrderData.recipient['address'].countryCode;
      this.requestedPickUpDate = paxOrderData.requestedPickupDate;
      this.requesterReference = paxOrderData.referenceRequester;
      this.incoterm = paxOrderData.incoterm;
      this.orderNumberOfParcels = paxOrderData.parcelCount;
      this.partnerParcelNumbers = paxOrderData.partnerParcelNumber?.length > 0
        ? paxOrderData.partnerParcelNumber
        : paxOrderData.parcelDetails?.length > 0
          ? paxOrderData.parcelDetails
              .flatMap(parcel =>
                Array.isArray(parcel.partnerParcelNumbers)
                  ? parcel.partnerParcelNumbers.map(ppn => ppn.partnerParcelNumber)
                  : []
              ).join(',')
          : '';
    }

    if (this.requestedPickUpDate) {
      this.requestedPickUpDate = this.datePipe.transform(this.requestedPickUpDate, 'dd.MM.yyyy');
    }

    setTimeout(() => this.adjustTextareaHeight(), 50);
  }

  /** Function to go to createPaxOrder Page and use orders as a template */
  useExistingOrderAsTemplate() {
    this.router.navigate(['/create-order']);
  }

  /** Function to navigate back to the previous page */
  goBack() {
    localStorage.removeItem("seePaxOrderDetails");
    this.location.back();
  }

  adjustTextareaHeight() {
    if (this.commentTextarea) {
      const textarea = this.commentTextarea.nativeElement;
      textarea.value = this.senderComment;
      textarea.style.height = "auto";
      textarea.style.height = Math.min(textarea.scrollHeight, 150) + "px";
    }
    if (this.partnerParcelNumberTextarea) {
      const textarea = this.partnerParcelNumberTextarea.nativeElement;
      textarea.value = this.partnerParcelNumbers;
      textarea.style.height = "auto";
      textarea.style.height = Math.min(textarea.scrollHeight, 150) + "px";
    }
  }
}
