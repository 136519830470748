import { Component, OnInit, OnDestroy } from '@angular/core';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { b2cProtectedResources } from '../../../auth-b2c-config';
import { Router, NavigationEnd } from '@angular/router';
import { CheckUserRolesService } from '../../services/check-user-roles.service';
import { AuthService } from 'src/app/modules/services/auth.service';
import { Store } from '@ngrx/store';
import { clearAccessToken } from 'src/app/store/auth.actions';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy  {
  /** TODO Refactor logic if all guards are defined so depending on route the right combination is selected  */
  isLoggedIn = false;
  ordersDisplay = true;
  pickupsDisplay = true;
  createDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  currentPath: string = '';
  private subscription: Subscription;

  constructor(
    private msalService: MsalService,
    private authService: AuthService,
    private router: Router,
    private store: Store,
    private userRolesSerivce: CheckUserRolesService) { }

  ngOnInit() {
    this.getCurrentPath();

    /**This function used to check the user is loggedin. If so it will set the boolean value of logout button */
    this.authService.isLoggedIn()
      .pipe(takeUntil(this._destroying$))
      .subscribe((loggedIn: boolean) => {
        this.isLoggedIn = loggedIn;
      });
  }

  getCurrentPath() {
    this.subscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      let navigationEvent = event as NavigationEnd;
      this.currentPath = navigationEvent.url;
    });
  }

  logout() {
    const isConfirmed = confirm("Are you sure you want to log out?");
    if (isConfirmed) {
      // Clear storage and authentication state
      this.authService.clearCacheStorage();
      this.authService.clearCookies();
      localStorage.clear();
      sessionStorage.clear();
      this.store.dispatch(clearAccessToken());
      this.msalService.instance.clearCache();

      // Prevent back button navigation
      history.pushState(null, '', location.href);
      window.addEventListener('popstate', function () {
        history.pushState(null, '', location.href);
      });

      this.msalService.logoutRedirect({ postLogoutRedirectUri: b2cProtectedResources.paxApi.endpoint }).subscribe({
        next: () => {
          location.replace('/login');
        },
        error: (error) => {
          console.error("Logout error:", error);
          location.replace('/login');
        }
      });
    }
  }



  /**This function used to set login logout button display */
  setLogoutDisplay() {
    return this.authService.isLoggedIn();
  }

  /**Getter function for the user role PaxOrderCreator */
  isPaxOrderCreator() {
    return this.userRolesSerivce.isPaxOrderCreator
  }

  /**Getter function for the user role PaxOrderViewer */
  isPaxOrderViewer() {
    return this.userRolesSerivce.isPaxOrderViewer;
  }

  /** TODO add functionality when role for printer is added */
  isPaxOrderPrinter() {
    return true;
  }

  /**This function used to destroy the subscription to avoid duplicates */
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
