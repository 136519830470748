import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { selectAccessToken } from '../store/auth.selectors';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private router: Router, private store: Store) { }

  /** This function returns an Observable of the current access token */
  getToken(): Observable<string | null> {
    return this.store.select(selectAccessToken);
  }

  /** This function returns an Observable of the boolean value for logged in user */
  isLoggedIn(): Observable<boolean> {
    return this.getToken().pipe(
      map(token => token !== null && token !== '')
    );
  }
}
