import { Component, EventEmitter, Output, OnInit, OnDestroy, Input } from '@angular/core';

@Component({
  selector: 'app-session-popup',
  templateUrl: './session-popup.component.html',
  styleUrls: ['./session-popup.component.scss']
})
export class SessionPopupComponent implements OnInit, OnDestroy {
  @Output() extendSession = new EventEmitter<void>();
  @Output() dismissPopup = new EventEmitter<void>();
  @Output() countdownFinished = new EventEmitter<void>();
  @Input() remainingTimeInSeconds: number;

  countdown: number;
  private intervalId: any;

  ngOnInit() {
    this.updateCountdown();
    this.startCountdown();
    this.addVisibilityChangeListener();
  }

  ngOnDestroy() {
    this.clearCountdown();
    this.removeVisibilityChangeListener();
  }

  startCountdown() {
    this.intervalId = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        this.clearCountdown();
        this.countdownFinished.emit();
      }
    }, 1000);
  }

  clearCountdown() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  onExtendSession() {
    this.extendSession.emit();
    this.clearCountdown();
  }

  onDismissPopup() {
    this.dismissPopup.emit();
    this.clearCountdown();
  }

  updateCountdown() {
    const currentTime = Math.floor(Date.now() / 1000);
    const expirationTime = parseInt(localStorage.getItem('tokenExpirationTime') || '0', 10);
    this.countdown = expirationTime - currentTime;
    if (this.countdown <= 0) {
      this.clearCountdown();
      this.countdownFinished.emit();
    }
  }
  addVisibilityChangeListener() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  }
  removeVisibilityChangeListener() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  }

  handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      this.updateCountdown();
      if (this.countdown <= 0) {
        this.countdownFinished.emit();
      }
    }
  };
  get formattedCountdown() {
    const minutes = Math.floor(this.countdown / 60);
    const seconds = this.countdown % 60;
    if (minutes > 0) {
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds} minutes`;
    } else {
      return `${seconds} second${seconds > 1 ? 's' : ''}`;
    }
  }
}
