import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { CheckUserRolesService } from '../modules/services/check-user-roles.service';

@Injectable({
  providedIn: 'root'
})

export class MyPickupsAllowedGuard {

  constructor(
    private checkUserRolesService: CheckUserRolesService,
    private router: Router
  ) {};

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!this.checkUserRolesService.isPaxOrderViewer) {
      this.router.navigate(['/home']);
    }

    return this.checkUserRolesService.isPaxOrderViewer;
  }
}
