import { Component, OnInit } from '@angular/core';
import { RehydrationService } from './modules/services/rehydration.service';
import { AuthService } from './modules/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private rehydrationService: RehydrationService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.rehydrationService.rehydrateAuthState();

    // Listen for browser back/forward navigation
    window.addEventListener('pageshow', (event) => {
      if (event.persisted) {
        this.authService.isLoggedIn().subscribe(isLoggedIn => {
          if (!isLoggedIn) {
            this.router.navigateByUrl('/login');
          }
        });
      }
    });
  }
}
