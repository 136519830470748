import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Params } from '@angular/router';
import { environment } from '../../../environments/environment';
import { selectAccessToken } from 'src/app/store/auth.selectors';

@Injectable({
  providedIn: 'root'
})
export class PaxOrderService {
  private baseURL = environment.baseUrl;

  constructor(private http: HttpClient, private store: Store) {}

  private getHeaders(): Observable<HttpHeaders> {
    return this.store.select(selectAccessToken).pipe(
      map(accessToken => new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Expires': '0'
      }))
    );
  }

  /** HttpClient API post() method => Create Order */
  createOrder(postData: any): Observable<any> {
    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.post(`${this.baseURL}/pax-orders`, postData, { headers })
      )
    );
  }

  /** Function to get orders list */
  getOrders(params: Params): Observable<any> {
    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.get(`${this.baseURL}/pax-orders`, { headers, params })
      )
    );
  }

  /** Function to get the order label */
  printOrders(postData: any, labelFormat: string): Observable<Blob> {
    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.post(`${this.baseURL}/pax-orders/labels/${labelFormat}`, postData, {
          headers,
          responseType: 'blob'
        })
      )
    );
  }

  /** Function to get ShipIt response */
  getShipItResponse(lastCreatedPaxOrderKey: any): Observable<any> {
    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.get(`${this.baseURL}/pax-orders/${lastCreatedPaxOrderKey}/status`, { headers })
      )
    );
  }

  /** Function to edit an existing order */
  editOrder(paxOrderKey: string, orderData: any): Observable<any> {
    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.patch(`${this.baseURL}/pax-orders/${paxOrderKey}`, orderData, { headers })
      )
    );
  }
}
