<div *ngIf="editSelectedOrder" class="feature-button-container">
  <div class="oder-details-header"><span>Edit Order</span></div>
</div>
<div class="container mt-5">
  <div class="row">
    <form class="createOrderForm" [formGroup]="createOrderForm" (ngSubmit)="onSubmit()">
      <!---TODO will change it later to toaster message--->
      <!--- Danger Alert--->
      <div *ngIf="dangerAlert" class="alert alert-danger  alert-dismissible fade show" role="alert">
        <strong>Invalid !</strong> {{errorMessage | json}}
        <button (click)="closeDangerAlert()" type="button" class="btn-close" data-bs-dismiss="alert"
          aria-label="Close"></button>
      </div>
      <!--- Alert for Invalid pattern--->
      <div *ngIf="invalidAlert" class="alert alert-danger  alert-dismissible fade show" role="alert">
        <strong>Pattern is invalid</strong>
        <button (click)="closeInvalidAlert()" type="button" class="btn-close" data-bs-dismiss="alert"
          aria-label="Close"></button>
      </div>
      <div *ngIf="invalidValidationAlert" class="alert alert-danger  alert-dismissible fade show" role="alert">
        <div class=""><pre>{{ formatShipItErrors(paxOrderValdationsErrorList) }}</pre></div>
        <button (click)="closeInvalidValidationAlert()" type="button" class="btn-close" data-bs-dismiss="alert"
          aria-label="Close"></button>
      </div>
      <!--- Modal for Invalid sender recipient combination--->
      <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              Selected Recipient country is not valid for sender country
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                (click)="confirmModal()">Okay</button>
            </div>
          </div>
        </div>
      </div>
      <div class="createOrderFormContainer">
        <div class="rowContainer">
          <!--- Requester section--->
          <div class="requesterContainer">
            <div class="createOrderFormHeaders requesterHeader">Requester</div>
            <div class="createOrderSectionBody requesterBody">
              <!-- Name -->
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="requesterName" class="custom-label">Name*</label>
                  <div class="inputAndErrorContainer">
                    <input id="requesterName" type="text" class="custom-input" formControlName="requesterName"
                    [ngClass]="{ 'is-invalid': submitted && f['requesterName'].errors || f['requesterName'].hasError('validationError')}"
                    (input)="setRecipientEqualsRequesterCheckboxChecked()">
                    <div *ngIf="submitted && f['requesterName'].errors" class="invalid-feedback">
                      <div *ngIf="f['requesterName'].errors['required'] || f['requesterName'].hasError('whitespace')">Requester Name is required</div>
                    </div>
                    <div class="invalid-feedback">
                      <div *ngIf="f['requesterName'].hasError('validationError')">Requester Name is invalid</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Street -->
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="requesterStreet" class="custom-label">Street*, House Number</label>
                  <div class="inputAndErrorContainer">
                    <input id="requesterStreet" type="text" maxlength="255" class="custom-input"
                      formControlName="requesterStreet"
                      [ngClass]="{ 'is-invalid': submitted && f['requesterStreet'].errors || f['requesterStreet'].hasError('validationError')}"
                      (input)="setRecipientEqualsRequesterCheckboxChecked()">
                    <div *ngIf="submitted && f['requesterStreet'].errors" class="invalid-feedback">
                      <div *ngIf="f['requesterStreet'].errors['required'] || f['requesterStreet'].hasError('whitespace')">Requester Street is required</div>
                    </div>
                    <div class="invalid-feedback">
                      <div *ngIf="f['requesterStreet'].hasError('validationError')">Requester Street is invalid</div>
                    </div>
                  </div>
                  <div class="inputAndErrorContainer">
                    <input id="requesterHouseNumber" type="text" class="custom-input-small" formControlName="requesterHouseNumber">
                  </div>
                </div>
              </div>
              <!-- Zip Code, City -->
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="requesterZipCode" class="custom-label">Zip Code*, City*</label>
                  <div class="inputAndErrorContainer">
                    <input id="requesterZipCode" type="text" maxlength="255" class="custom-input-small"
                    formControlName="requesterZipCode"
                    [ngClass]="{ 'is-invalid': submitted && f['requesterZipCode'].errors || f['requesterZipCode'].hasError('validationError')}"
                    (input)="setRecipientEqualsRequesterCheckboxChecked()">
                    <div *ngIf="submitted && f['requesterZipCode'].errors" class="invalid-feedback">
                      <div
                        *ngIf="f['requesterZipCode'].errors['required'] || f['requesterZipCode'].hasError('whitespace')">ZipCode is required</div>
                    </div>
                    <div class="invalid-feedback">
                      <div *ngIf="f['requesterZipCode'].hasError('validationError')">ZipCode is invalid</div>
                    </div>
                  </div>
                  <div class="inputAndErrorContainer">
                    <input id="requesterCity" type="text" maxlength="255" class="custom-input"
                    formControlName="requesterCity"
                    [ngClass]="{ 'is-invalid': submitted && f['requesterCity'].errors || f['requesterCity'].hasError('validationError')}"
                    (input)="setRecipientEqualsRequesterCheckboxChecked()">
                    <div *ngIf="submitted && f['requesterCity'].errors" class="invalid-feedback">
                      <div *ngIf="f['requesterCity'].errors['required'] || f['requesterCity'].hasError('whitespace')">Requester City is required</div>
                    </div>
                    <div class="invalid-feedback">
                      <div *ngIf="f['requesterCity'].hasError('validationError')">Requester City is invalid</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Country Code -->
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="requesterCountryCode" class="custom-label">Country Code*</label>
                  <div class="inputAndErrorContainer">
                    <select id="requesterCountryCode" class="custom-select"
                      formControlName="requesterCountryCode"
                      [ngClass]="{ 'is-invalid': submitted && f['requesterCountryCode'].errors || f['requesterCountryCode'].hasError('validationError')}"
                      (change)="setRecipientEqualsRequesterCheckboxChecked()">
                      <option value="">Select Requester Country Code</option>
                      <option *ngFor="let requesterCountry of requesterCountryList" [value]="requesterCountry"> {{requesterCountry }}</option>
                    </select>
                    <div *ngIf="submitted && f['requesterCountryCode'].errors" class="invalid-feedback">
                      <div *ngIf="f['requesterCountryCode'].errors['required']">CountryCode is required</div>
                    </div>
                    <div class="invalid-feedback">
                      <div *ngIf="f['requesterCountryCode'].hasError('validationError')">CountryCode is invalid</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Phone -->
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="requesterPhone" class="custom-label">Phone</label>
                  <div class="inputAndErrorContainer">
                    <input id="requesterPhone" maxlength="255" class="custom-input"
                    formControlName="requesterPhone"
                    [ngClass]="{ 'is-invalid': submitted && f['requesterPhone'].errors || f['requesterPhone'].hasError('validationError')}">
                    <div *ngIf="submitted && f['requesterPhone'].errors" class="invalid-feedback">
                      <div *ngIf="f['requesterPhone'].errors['pattern']">Invalid value (example: +496677646907000)</div>
                    </div>
                    <div class="invalid-feedback">
                      <div *ngIf="f['requesterCountryCode'].hasError('validationError')">CountryCode is invalid</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Email -->
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="requesterEmail" class="custom-label">Email</label>
                  <div class="inputAndErrorContainer">
                    <input id="requesterEmail" type="text" maxlength="255" class="custom-input"
                    formControlName="requesterEmail"
                    [ngClass]="{ 'is-invalid': submitted && f['requesterEmail'].errors || f['requesterEmail'].hasError('validationError')}">
                    <div *ngIf="submitted && f['requesterEmail'].errors" class="invalid-feedback">
                    </div>
                    <div class="invalid-feedback">
                      <div *ngIf="f['requesterEmail'].hasError('validationError')">Email is invalid</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--- Pickup section--->
          <div class="senderContainer">
            <div class="createOrderFormHeaders senderHeader">
              Pickup
            </div>
            <div class="createOrderSectionBody senderBody">
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="senderName" class="custom-label">Name*</label>
                  <div class="inputAndErrorContainer">
                    <input id="senderName" type="text" class="custom-input" formControlName="senderName"
                    [ngClass]="{'is-invalid': submitted && f['senderName'].errors || f['senderName'].hasError('validationError')}">
                    <div *ngIf="submitted && f['senderName'].errors" class="invalid-feedback">
                      <div *ngIf="f['senderName'].errors['required']">Name is required</div>
                    </div>
                    <div class="invalid-feedback">
                      <div *ngIf="f['senderName'].hasError('validationError')">Name is invalid</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="senderStreet" class="custom-label">Street*, House
                    Number</label>
                  <div class="inputAndErrorContainer">
                    <input id="senderStreet" type="text" maxlength="255" class="custom-input"
                    formControlName="senderStreet"
                    [ngClass]="{ 'is-invalid': submitted && f['senderStreet'].errors || f['senderStreet'].hasError('validationError')}">
                    <div *ngIf="submitted && f['senderStreet'].errors" class="invalid-feedback">
                      <div *ngIf="f['senderStreet'].errors['required'] || f['senderStreet'].hasError('whitespace')">Street is required</div>
                    </div>
                    <div class="invalid-feedback">
                      <div *ngIf="f['senderStreet'].hasError('validationError')">Street is invalid</div>
                    </div>
                  </div>
                  <div class="inputAndErrorContainer">
                    <input id="senderHouseNumber" type="text" maxlength="255" class="custom-input-small"
                    formControlName="senderHouseNumber">
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="senderZipCode" class="custom-label">Zip Code*, City*</label>
                  <div class="inputAndErrorContainer">
                    <input id="senderZipCode" type="text" maxlength="255" class="custom-input-small"
                    formControlName="senderZipCode"
                    [ngClass]="{ 'is-invalid': submitted && f['senderZipCode'].errors || f['senderZipCode'].hasError('validationError')}">
                    <div *ngIf="submitted && f['senderZipCode'].errors" class="invalid-feedback">
                      <div *ngIf="f['senderZipCode'].errors['required'] || f['senderZipCode'].hasError('whitespace')">ZipCode is required</div>
                    </div>
                    <div class="invalid-feedback">
                      <div *ngIf="f['senderZipCode'].hasError('validationError')">ZipCode is invalid</div>
                    </div>
                  </div>
                  <div class="inputAndErrorContainer">
                    <input id="senderCity" type="text" maxlength="255" class="custom-input"
                    formControlName="senderCity"
                    [ngClass]="{ 'is-invalid': submitted && f['senderCity'].errors || f['senderCity'].hasError('validationError')}">
                    <div *ngIf="submitted && f['senderCity'].errors" class="invalid-feedback">
                      <div *ngIf="f['senderCity'].errors['required'] || f['senderCity'].hasError('whitespace')">City is required</div>
                    </div>
                    <div class="invalid-feedback">
                      <div *ngIf="f['senderCity'].hasError('validationError')">City is invalid</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="senderCountryCode" class="custom-label">Country Code*</label>
                  <div class="inputAndErrorContainer">
                    <select id="senderCountryCode" class="custom-select"
                      formControlName="senderCountryCode"
                      [ngClass]="{ 'is-invalid': submitted && f['senderCountryCode'].errors || f['senderCountryCode'].hasError('validationError')}"
                      (change)="onChangeSenderCountry($event)">
                      <option value="">Select Pickup Country Code</option>
                      <option *ngFor="let senderCountry of senderCountryList" [value]="senderCountry"> {{senderCountry}}</option>
                    </select>
                    <div *ngIf="submitted && f['senderCountryCode'].errors" class="invalid-feedback">
                      <div *ngIf="f['senderCountryCode'].errors['required']">CountryCode is required</div>
                      <div *ngIf="f['senderCountryCode'].hasError('nationalPaxOrder')">Only international Requester and Pickup country combination is allowed</div>
                    </div>
                    <div class="invalid-feedback">
                      <div *ngIf="f['senderCountryCode'].hasError('validationError')">CountryCode is invalid</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="contactPersonName" class="custom-label">Contact Person Name*</label>
                  <div class="inputAndErrorContainer">
                    <input id="senderContactPersonName" type="text" maxlength="255" class="custom-input"
                    formControlName="senderContactPersonName"
                    [ngClass]="{ 'is-invalid': submitted && f['senderContactPersonName'].errors || f['senderContactPersonName'].hasError('validationError')}">
                    <div *ngIf="submitted && f['senderContactPersonName'].errors" class="invalid-feedback">
                      <div *ngIf="f['senderContactPersonName'].errors['required'] || f['senderContactPersonName'].hasError('whitespace')">Contact PersonName is required</div>
                    </div>
                    <div class="invalid-feedback">
                      <div *ngIf="f['senderContactPersonName'].hasError('validationError')">Contact PersonName is invalid</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="senderPhone" class="custom-label">Phone*</label>
                  <div class="inputAndErrorContainer">
                    <input id="senderPhone" type="text" maxlength="255" class="custom-input"
                    formControlName="senderPhone"
                    [ngClass]="{ 'is-invalid': submitted && f['senderPhone'].errors || f['senderPhone'].hasError('validationError')}">
                    <div *ngIf="submitted && f['senderPhone'].errors" class="invalid-feedback">
                      <div *ngIf="f['senderPhone'].errors['pattern']">Invalid value (example: +496677646907000)</div>
                      <div *ngIf="f['senderPhone'].errors['required']">Phone Number is required</div>
                    </div>
                    <div class="invalid-feedback">
                      <div *ngIf="f['senderPhone'].hasError('validationError')">Phone Number is invalid</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="senderEmail" class="custom-label">Email</label>
                  <div class="inputAndErrorContainer">
                    <input id="senderEmail" type="text" maxlength="255" class="custom-input"
                    formControlName="senderEmail"
                    [ngClass]="{ 'is-invalid': submitted && f['senderEmail'].errors || f['senderEmail'].hasError('validationError')}">
                    <div *ngIf="submitted && f['senderEmail'].errors" class="invalid-feedback">
                    </div>
                    <div class="invalid-feedback">
                      <div *ngIf="f['senderEmail'].hasError('validationError')">Mail is invalid</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="rowContainer">
          <!--- Delivery section--->
          <div class="recipientContainer">
            <div class="createOrderFormHeaders recipientHeader">
              Delivery
            </div>
            <div class="createOrderSectionBody recipientBody">
              <div class="form-check">
                <label class="custom-label" for="recipientCheckbox">
                  Delivery equals Pickup
                </label>
                <input class="form-check-input" type="checkbox" id="recipientCheckbox"
                  formControlName="recipientCheckbox" (change)="setRecipientEqualsRequesterCheckboxChecked()">
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="recipientName" class="custom-label">Name* </label>
                  <div class="inputAndErrorContainer">
                    <input id="recipientName" type="text" maxlength="255" class="custom-input"
                    formControlName="recipientName"
                    [ngClass]="{ 'is-invalid': submitted && f['recipientName'].errors || f['recipientName'].hasError('validationError')}">
                    <div *ngIf="submitted && f['recipientName'].errors" class="invalid-feedback">
                      <div *ngIf="f['recipientName'].errors['required'] || f['recipientName'].hasError('whitespace')">Name is required</div>
                    </div>
                    <div class="invalid-feedback">
                      <div *ngIf="f['recipientName'].hasError('validationError')">Name is invalid</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="recipientStreet" class="custom-label">Street*, House Number</label>
                  <div class="inputAndErrorContainer">
                    <input id="recipientStreet" type="text" maxlength="255" class="custom-input"
                    formControlName="recipientStreet"
                    [ngClass]="{ 'is-invalid': submitted && f['recipientStreet'].errors || f['recipientStreet'].hasError('validationError')}">
                    <div *ngIf="submitted && f['recipientStreet'].errors" class="invalid-feedback">
                      <div *ngIf="f['recipientStreet'].errors['required'] || f['recipientStreet'].hasError('whitespace')">Street is required</div>
                    </div>
                  </div>
                  <div class="inputAndErrorContainer">
                    <input id="recipientHouseNumber" type="text" maxlength="255" class="custom-input-small"
                    formControlName="recipientHouseNumber">
                  </div>
                  <div class="invalid-feedback">
                    <div *ngIf="f['recipientHouseNumber'].hasError('validationError')">House Number is invalid</div>
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="recipientZipCode" class="custom-label">Zip Code*,
                    City*</label>
                  <div class="inputAndErrorContainer">
                    <input id="recipientZipCode" type="text" maxlength="255" class="custom-input-small"
                    formControlName="recipientZipCode"
                    [ngClass]="{ 'is-invalid': submitted && f['recipientZipCode'].errors || f['recipientZipCode'].hasError('validationError')}">
                    <div *ngIf="submitted && f['recipientZipCode'].errors" class="invalid-feedback">
                      <div
                        *ngIf="f['recipientZipCode'].errors['required'] || f['recipientZipCode'].hasError('whitespace')">ZipCode is required</div>
                    </div>
                    <div class="invalid-feedback">
                      <div *ngIf="f['recipientZipCode'].hasError('validationError')">ZipCode is invalid</div>
                    </div>
                  </div>
                  <div class="inputAndErrorContainer">
                    <input id="recipientCity" type="text" maxlength="255" class="custom-input"
                    formControlName="recipientCity"
                    [ngClass]="{ 'is-invalid': submitted && f['recipientCity'].errors || f['recipientCity'].hasError('validationError')}">
                    <div *ngIf="submitted && f['recipientCity'].errors" class="invalid-feedback">
                      <div *ngIf="f['recipientCity'].errors['required'] || f['recipientCity'].hasError('whitespace')">City is required</div>
                    </div>
                    <div class="invalid-feedback">
                      <div *ngIf="f['recipientCity'].hasError('validationError')">City is invalid</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="countryCode" class="custom-label">Country Code*</label>
                  <div class="inputAndErrorContainer">
                    <select id="recipientCountryCode" class="custom-select"
                    formControlName="recipientCountryCode"
                    [ngClass]="{ 'is-invalid': submitted && f['recipientCountryCode'].errors || f['recipientCountryCode'].hasError('validationError')}"
                    (change)="onChangeRecipientCountry($event)">
                    <option value="">Select Delivery Country Code</option>
                    <option *ngFor="let recipientCountryCode of recipientCountryList" [value]="recipientCountryCode"> {{ recipientCountryCode }}</option>
                    </select>
                    <div *ngIf="submitted && f['recipientCountryCode'].errors" class="invalid-feedback">
                      <div *ngIf="f['recipientCountryCode'].errors['required']">CountryCode is required</div>
                    </div>
                    <div class="invalid-feedback">
                      <div *ngIf="f['recipientCountryCode'].hasError('validationError')">CountryCode is invalid</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--- Order section --->
          <div class="orderContainer">
            <div class="createOrderFormHeaders orderHeader">
              Order
            </div>
            <div class="createOrderSectionBody orderBody">
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="requestedPickUpDate" class="custom-label">Requested Pick Up Date*</label>
                  <div class="inputAndErrorContainer">
                    <mat-form-field id="orderRequestedPickUpDate"
                      [ngClass]="{'is-invalid': submitted && f['orderRequestedPickUpDate'].errors || f['orderRequestedPickUpDate'].hasError('validationError')}">
                      <input matInput
                        class="mat-datepicker-input"
                        [min]="validMinOrderRequestedPickUpDate"
                        [max]="validMaxOrderRequestedPickUpDate"
                        [matDatepicker]="picker"
                        [matDatepickerFilter]="excludeWeekends"
                        formControlName="orderRequestedPickUpDate"
                        [(ngModel)]="orderRequestedPickUpDateValue"
                        (dateChange)="onClickOrderRequestedPickUpDate()"
                        [ngClass]="{ 'is-invalid': submitted && f['orderRequestedPickUpDate'].errors || f['orderRequestedPickUpDate'].hasError('validationError')}">
                      <mat-datepicker-toggle matSuffix [for]="picker" (click)="onClickOrderRequestedPickUpDate()"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <div *ngIf="submitted && f['orderRequestedPickUpDate'].errors" class="invalid-feedback">
                      <div *ngIf="f['orderRequestedPickUpDate'].errors['required']">PickUpDate is required</div>
                      <div *ngIf="f['orderRequestedPickUpDate'].errors['weekend']">Weekend is not allowed</div>
                    </div>
                    <div class="invalid-feedback">
                      <div *ngIf="f['recipientCountryCode'].hasError('validationError')">PickUpDate is invalid</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="requesterReference" class="custom-label">Requester Reference</label>
                  <div class="inputAndErrorContainer">
                    <input id="requesterReference" type="text" maxlength="255" class="custom-input"
                    formControlName="requesterReference">
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="incoterm" class="custom-label">Incoterm</label>
                  <div class="inputAndErrorContainer">
                    <select id="orderIncoterm" class="custom-select"
                      formControlName="orderIncoterm">
                      <option value="">Select Incoterm</option>
                      <option *ngFor="let incoterm of incoterm"> {{ incoterm }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="orderNumberOfParcels" class="custom-label">Number Of Parcels*</label>
                  <div class="inputAndErrorContainer">
                    <input type="number" min="1" max="99" id="orderNumberOfParcels" class="custom-input"
                    formControlName="orderNumberOfParcels"
                    [ngClass]="{ 'is-invalid': submitted && f['orderNumberOfParcels'].errors || f['orderNumberOfParcels'].hasError('validationError')}">
                    <div *ngIf="submitted && f['orderNumberOfParcels'].errors" class="invalid-feedback">
                      <div *ngIf="f['orderNumberOfParcels'].errors['required']">Order Number Of Parcels is required
                      </div>
                    </div>
                    <div class="invalid-feedback">
                      <div *ngIf="f['orderNumberOfParcels'].hasError('validationError')">Order Number Of Parcels is invalid</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="custom-row my-3" *ngIf="createNewOrder">
                <div class="labelAndInputContainer">
                  <label for="maxParcelWeight" class="custom-label">Max. approx. weight per Parcel (kg)*</label>
                  <div class="inputAndErrorContainer">
                    <input id="maxParcelWeight" type="number" min="0" [max]="maxParcelWeight" class="custom-input"
                    formControlName="maxParcelWeight"
                    [ngClass]="{ 'is-invalid': submitted && f['maxParcelWeight'].errors || f['maxParcelWeight'].hasError('validationError')}">
                    <div *ngIf="submitted && f['maxParcelWeight'].errors" class="invalid-feedback">
                      <div *ngIf="f['maxParcelWeight'].errors['required'] || f['maxParcelWeight'].hasError('whitespace')">Max Parcel Weight is required</div>
                      <div *ngIf="f['maxParcelWeight'].errors['max']"> Max Parcel Weight should be less than {{maxParcelWeight}}</div>
                      <div *ngIf="f['maxParcelWeight'].errors['min']"> Max Parcel Weight should have a positive value</div>
                    </div>
                    <div class="invalid-feedback">
                      <div *ngIf="f['orderNumberOfParcels'].hasError('validationError')">Max Parcel Weight is invalid</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Create order button -->
      <div class="submit-container">
        <button class="btn btn-primary" type="button" (click)="goBack()">Back</button>
        <button *ngIf="editSelectedOrder" class="btn btn-primary" type="submit">Save Changes</button>
        <button *ngIf="createNewOrder" class="btn btn-primary" type="submit">Create Order</button>
      </div>
    </form>
  </div>
</div>

<app-fading-message-popup
  [message]="message"
  [type]="messageType"
  [displayDuration]="displayDuration"
  [fadeDuration]="fadeDuration">
</app-fading-message-popup>
