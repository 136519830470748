import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-multi-select-dropdown',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './multi-select-dropdown.component.html',
  styleUrl: './multi-select-dropdown.component.scss'
})
export class MultiSelectDropdownComponent implements OnInit, OnChanges {
  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}
  @Input() dropdownItems:any = [];
  @Input() selectPlaceHolder: string = 'Select some...';
  @Input() defaultSelectedItems: string[] = [];
  @Output() selectedOption: any = new EventEmitter<string>();

  selectedItems: string[] = [];
  expanded = false;
  allCheckboxesSelected = false;

  ngOnInit() {
    this.selectedItems = [...this.defaultSelectedItems];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['defaultSelectedItems']) {
      this.selectedItems = [...this.defaultSelectedItems];
    }
  }

  onSelect(event: any) {
    const item = event.target.id;
    if (event.target.checked) {
      this.selectedItems.push(item);
    } else {
      this.selectedItems = this.selectedItems.filter((r) => r !== item);
    }
    this.selectedOption.emit(this.selectedItems);
  }

  @ViewChild('multiselect') multiselect: ElementRef;

  @HostListener('document:click', ['$event.target'])
  onClick(target: HTMLElement) {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    const clickedMultiselectDiv = this.multiselect.nativeElement.contains(target);
    if (!clickedInside && !clickedMultiselectDiv) {
      this.closeDropdown();
    }
  }

 showCheckboxes() {
  const checkboxes = document.getElementById("checkboxes") as HTMLElement;
  const dropDownContainer = document.getElementById("dropDownContainerId") as HTMLElement;
  if (!this.expanded) {
    checkboxes.style.display = "block";
    dropDownContainer.style.display = "block";
    this.expanded = true;
  } else {
    checkboxes.style.display = "none";
    dropDownContainer.style.display = "none";
    this.expanded = false;
    }
  }

closeDropdown() {
  const checkboxes = document.getElementById("checkboxes") as HTMLElement;
  checkboxes.style.display = "none";
  this.expanded = false;
}

CheckIfAllAreSelected() {
  const checkboxes = document.getElementById("checkboxes") as HTMLElement;
  const inputs = checkboxes.querySelectorAll("input");
  let validation = [];
  for (let i= 0; i< inputs.length; i++) {
    validation.push(inputs[i].checked);
    }
  if(validation.every(Boolean)) {
    this.allCheckboxesSelected = true;
  } else {
    this.allCheckboxesSelected = false;
  }
}

selectAllToggle() {
  this.allCheckboxesSelected = !this.allCheckboxesSelected;
  if (this.allCheckboxesSelected === true) {
    const checkboxes = document.getElementById("checkboxes") as HTMLElement;
    const inputs = checkboxes.querySelectorAll("input");
    inputs.forEach((input) => {
      input.checked = true;
    });
    this.selectedItems = this.dropdownItems;
    } else {
      const checkboxes = document.getElementById("checkboxes") as HTMLElement;
      const inputs = checkboxes.querySelectorAll("input");
      inputs.forEach((input) => {
        input.checked = false;
      });
      this.selectedItems = [];
      }
    this.selectedOption.emit(this.selectedItems);
  }

  selectAll() {
    this.allCheckboxesSelected = true;
    this.selectAllToggle();
  }

}
