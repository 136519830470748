<div class="feature-button-container">
  <div class="oder-details-header"><span>Edit Order</span></div>
</div>
<div class="container mt-5">
  <div class="row">
    <form>
      <div class="createOrderFormContainer">
        <div class="rowContainer">
          <!--- Requester section--->
          <div class="requesterContainer">
            <div class="oderSectionsHeader requesterHeader">Requester</div>
            <div class="createOrderSectionBody requesterBody">
              <!-- Name -->
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="requesterName" class="custom-label">Name*</label>
                  <div class="inputAndErrorContainer">
                    <input id="requesterName" type="text" class="custom-input" [(ngModel)]="requesterName" name="requesterName">
                  </div>
                </div>
              </div>
              <!-- Street -->
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="requesterStreet" class="custom-label">Street*, House Number</label>
                  <div class="inputAndErrorContainer">
                    <input id="requesterStreet" type="text" maxlength="255" class="custom-input" [(ngModel)]="requesterStreet" name="requesterStreet">
                  </div>
                  <div class="inputAndErrorContainer">
                    <input id="requesterHouseNumber" type="text" class="custom-input-small" [(ngModel)]="requesterHouseNumber" name="requesterHouseNumber">
                  </div>
                </div>
              </div>
              <!-- Zip Code, City -->
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="requesterZipCode" class="custom-label">Zip Code*, City*</label>
                  <div class="inputAndErrorContainer">
                    <input id="requesterZipCode" type="text" maxlength="255" class="custom-input-small" [(ngModel)]="requesterZipCode" name="requesterZipCode">
                  </div>
                  <div class="inputAndErrorContainer">
                    <input id="requesterCity" type="text" maxlength="255" class="custom-input" [(ngModel)]="requesterCity" name="requesterCity">
                  </div>
                </div>
              </div>
              <!-- Country Code -->
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="requesterCountryCode" class="custom-label">Country Code*</label>
                  <div class="inputAndErrorContainer">
                    <input id="requesterCountryCode" class="custom-input" type="text" [(ngModel)]="requesterCountryCode" name="requesterCountryCode">
                  </div>
                </div>
              </div>
              <!-- Phone -->
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="requesterPhone" class="custom-label">Phone</label>
                  <div class="inputAndErrorContainer">
                    <input id="requesterPhone" maxlength="255" class="custom-input" [(ngModel)]="requesterPhone" name="requesterPhone">
                  </div>
                </div>
              </div>
              <!-- Email -->
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="requesterEmail" class="custom-label">Email</label>
                  <div class="inputAndErrorContainer">
                    <input id="requesterEmail" type="text" maxlength="255" class="custom-input" [(ngModel)]="requesterEmail" name="requesterEmail">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--- Pickup section--->
          <div class="senderContainer">
            <div class="oderSectionsHeader senderHeader">Pickup</div>
            <div class="createOrderSectionBody senderBody">
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="senderName" class="custom-label">Name*</label>
                  <div class="inputAndErrorContainer">
                    <input id="senderName" type="text" class="custom-input" [(ngModel)]="senderName" name="senderName">
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="senderStreet" class="custom-label">Street*, House Number</label>
                  <div class="inputAndErrorContainer">
                    <input id="senderStreet" type="text" maxlength="255" class="custom-input" [(ngModel)]="senderStreet" name="senderStreet">
                  </div>
                  <div class="inputAndErrorContainer">
                    <input id="senderHouseNumber" type="text" maxlength="255" class="custom-input-small" [(ngModel)]="senderHouseNumber" name="senderHouseNumber">
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="senderZipCode" class="custom-label">Zip Code*, City*</label>
                  <div class="inputAndErrorContainer">
                    <input id="senderZipCode" type="text" maxlength="255" class="custom-input-small" [(ngModel)]="senderZipCode" name="senderZipCode">
                  </div>
                  <div class="inputAndErrorContainer">
                    <input id="senderCity" type="text" maxlength="255" class="custom-input" [(ngModel)]="senderCity" name="senderCity">
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="senderCountryCode" class="custom-label">Country Code*</label>
                  <div class="inputAndErrorContainer">
                    <input id="senderCountryCode" class="custom-input" type="text" [(ngModel)]="senderCountryCode" name="senderCountryCode">
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="senderContactPersonName" class="custom-label">Contact Person Name*</label>
                  <div class="inputAndErrorContainer">
                    <input id="senderContactPersonName" type="text" maxlength="255" class="custom-input" [(ngModel)]="senderContactPersonName" name="senderContactPersonName">
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="senderPhone" class="custom-label">Phone*</label>
                  <div class="inputAndErrorContainer">
                    <input id="senderPhone" type="text" maxlength="255" class="custom-input" [(ngModel)]="senderPhone" name="senderPhone">
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="senderEmail" class="custom-label">Email</label>
                  <div class="inputAndErrorContainer">
                    <input id="senderEmail" type="text" maxlength="255" class="custom-input" [(ngModel)]="senderEmail" name="senderEmail">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="rowContainer">
          <!--- Delivery section--->
          <div class="recipientContainer">
            <div class="oderSectionsHeader recipientHeader">Delivery</div>
            <div class="createOrderSectionBody recipientBody">
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="recipientName" class="custom-label">Name* </label>
                  <div class="inputAndErrorContainer">
                    <input id="recipientName" type="text" maxlength="255" class="custom-input" [(ngModel)]="recipientName" name="recipientName">
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="recipientStreet" class="custom-label">Street*, House Number</label>
                  <div class="inputAndErrorContainer">
                    <input id="recipientStreet" type="text" maxlength="255" class="custom-input" [(ngModel)]="recipientStreet" name="recipientStreet">
                  </div>
                  <div class="inputAndErrorContainer">
                    <input id="recipientHouseNumber" type="text" maxlength="255" class="custom-input-small" [(ngModel)]="recipientHouseNumber" name="recipientHouseNumber">
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="recipientZipCode" class="custom-label">Zip Code*, City*</label>
                  <div class="inputAndErrorContainer">
                    <input id="recipientZipCode" type="text" maxlength="255" class="custom-input-small" [(ngModel)]="recipientZipCode" name="recipientZipCode">
                  </div>
                  <div class="inputAndErrorContainer">
                    <input id="recipientCity" type="text" maxlength="255" class="custom-input" [(ngModel)]="recipientCity" name="recipientCity">
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="recipientCountryCode" class="custom-label">Country Code*</label>
                  <div class="inputAndErrorContainer">
                    <input id="recipientCountryCode" class="custom-input" type="text" [(ngModel)]="recipientCountryCode" name="recipientCountryCode">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--- Order section --->
          <div class="orderContainer">
            <div class="oderSectionsHeader orderHeader">Order</div>
            <div class="createOrderSectionBody orderBody">
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="requestedPickUpDate" class="custom-label">Requested Pick Up Date*</label>
                  <div class="inputAndErrorContainer">
                    <input id="requestedPickUpDate" class="custom-input" type="text" [(ngModel)]="requestedPickUpDate" name="requestedPickUpDate">
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="requesterReference" class="custom-label">Requester Reference</label>
                  <div class="inputAndErrorContainer">
                    <input id="requesterReference" type="text" maxlength="255" class="custom-input" [(ngModel)]="requesterReference" name="requesterReference">
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="incoterm" class="custom-label">Incoterm</label>
                  <div class="inputAndErrorContainer">
                    <input id="incoterm" class="custom-input" type="text" [(ngModel)]="incoterm" name="incoterm">
                  </div>
                </div>
              </div>
              <div class="custom-row my-3">
                <div class="labelAndInputContainer">
                  <label for="orderNumberOfParcels" class="custom-label">Number Of Parcels*</label>
                  <div class="inputAndErrorContainer">
                    <input id="orderNumberOfParcels" type="text" maxlength="255" class="custom-input" [(ngModel)]="orderNumberOfParcels" name="orderNumberOfParcels">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Create order button -->
      <div class="submit-container">
        <button class="btn btn-primary" type="button" (click)="goBack()">Back</button>
        <button class="btn btn-primary" type="button" (click)="editOrder()">Save Changes</button>
      </div>
    </form>
  </div>
</div>

<app-fading-message-popup
  [message]="message"
  [type]="messageType"
  [displayDuration]="displayDuration"
  [fadeDuration]="fadeDuration">
</app-fading-message-popup>
