// azure-ad-auth-config.ts
import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { environment } from '../environments/environment';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export const msalConfigAd: Configuration = {
  auth: {
    clientId: environment.authorities.signUpSignInAD.clientId,
    authority: environment.authorities.signUpSignInAD.authority,
    redirectUri: '/',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE,
  },
  system: {
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {},
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false,
    },
  },
};

export const protectedResources = {
  adApi: {
    endpoint: environment.postLogoutUrl,
    scopes: [''], //c6e49e29-bfba-425c-b92d-c211c7f59e3d/PAX.default
  },
};

export const adLoginRequest = {
  scopes: [''],
};

export const AuthTypeValue = 'AD'; 
