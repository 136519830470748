<div class="features-button-container">
  <p class="totalFoundItems"><span>{{totalFoundItems}}</span>Pickups</p>
  <button *ngIf="isPrintOptionsAllowed" class="gls-button print-selected btn btn-primary" (click)="onPrintCheckedOrders()">Print &nbsp;
    <span class="material-symbols-outlined">
      Print
    </span>
  </button>
</div>
<div class="table-options-wrapper">
  <div class="alert alert-danger  alert-dismissible fade show" *ngIf="authorizationFailed"><strong>Authorization Failed!</strong></div>
  <div class="expandable-filter-area">
    <div class="input-wrapper">
      <input
        type="text"
        class="search-input requester-reference-search-input"
        placeholder="Requester Reference "
        id="search-requester-reference"
        [(ngModel)]="requesterReference"
        #requesterReferenceRef="ngModel"
        (keyup)="onInputChange($event)"
        (keyup.enter)="updateTable()"
        maxlength="100"
      />
    </div>
    <div class="input-wrapper partner-parcel-number-search-input-wrapper">
      <input
        type="text"
        class="search-input partner-parcel-number-search-input"
        placeholder="Partner Parcel Number"
        id="search-partner-parcel-number"
        [(ngModel)]="partnerParcelNumber"
        #requesterReferenceRef="ngModel"
        (keyup)="onInputChange($event)"
        (keyup.enter)="updateTable()"
        maxlength="100"
      />
    </div>
  </div>
  <div class="default-filters-button-container">
    <div class="default-filter-button" (click)="onResetToDefaultFiltersClick()">Reset Filters to Default</div>
  </div>
  <div class="show-active-filters-container">
    <div class="remove-all-filters-button" *ngIf="filterChips.length > 1" (click)="onRemoveAllFiltersClick()">Remove All Filters</div>
    <div *ngFor="let chip of filterChips" class="filter-chips" [ngClass]="{'hidden': chip.key === 'Remove All Filters'}">
      {{ chip.key }}<span *ngIf="chip.value">: {{ chip.value }}</span>
      <span>
        <img class="x-icon" src="assets/gls-xbp-x-icon.svg" (click)="removeFilterChip(chip)" (click)="stopEvent($event)">
      </span>
    </div>
  </div>
  <div class="show-active-sortings-container">
    <div class="remove-all-sortings-button" *ngIf="activeSortings.length > 1" (click)="onRemoveAllSortingsClick()">Remove All Sortings</div>
    <div *ngFor="let sort of activeSortings" class="sort-chips" [ngClass]="{'hidden': sort.key === 'Remove All Sortings'}">
      {{ sort.key }}
      <span *ngIf="sort.key !== 'Remove All Sortings'">
        <img *ngIf="sort.order === 'desc'" class="gls-sorting-arrow-down" src="assets/gls-arrow-down-black.svg" alt="" title="Descending order">
        <img *ngIf="sort.order === 'asc'" class="gls-sorting-arrow-up" src="assets/gls-arrow-up-black.svg" alt="" title="Ascending order">
      </span>
      <span>
        <img class="x-icon" src="assets/gls-xbp-x-icon.svg" (click)="removeSortingChips(sort)" (click)="stopEvent($event)">
      </span>
    </div>
  </div>
</div>
<div class="table-container">
  <div class="table-scroll-container" (scroll)="onTableScroll($event)">
    <table>
      <thead>
        <tr>
          <th class="checkbox-all">
            <input type="checkbox" class="checkbox-input" #checkAll (change)="onCheckAllChange(checkAll.checked)">
          </th>
          <th class="parcelNumberColumnHeader" #th1 (mousedown)="startResize($event, th1)" (click)="onParcelNumberSortingClick()">
            Parcel Number
            <span>
              <img class="gls-sorting-arrow-up" src="assets/gls-arrow-up.svg" [class.active]="isParcelNumberAscendingSortingActive" alt="" title="Ascending order">
              <img class="gls-sorting-arrow-down" src="assets/gls-arrow-down.svg" [class.active]="isParcelNumberDescendingSortingActive" alt="" title="Descending order">
            </span>
            <div class="resizer"></div>
          </th>
          <th class="pickupDateColumnHeader" #th2 (mousedown)="startResize($event, th2)" (click)="onPickupDateSortingClick()">
            Pick-up Date
            <span>
              <img class="gls-sorting-arrow-up" src="assets/gls-arrow-up.svg" [class.active]="isPickupDateAscendingSortingActive" alt="" title="Ascending order">
              <img class="gls-sorting-arrow-down" src="assets/gls-arrow-down.svg" [class.active]="isPickupDateDescendingSortingActive" alt="" title="Descending order">
            </span>
            <div class="resizer"></div>
          </th>
          <th #th3 (mousedown)="startResize($event, th3)">Requester Name<div class="resizer"></div></th>
          <th class="requesterLocationColumnHeader" #th4 (mousedown)="startResize($event, th4)" (click)="onRequesterLocationSortingClick()">
            Requester Location
            <span>
              <img class="gls-sorting-arrow-up" src="assets/gls-arrow-up.svg" [class.active]="isRequesterLocationAscendingSortingActive" alt="" title="Ascending order">
              <img class="gls-sorting-arrow-down" src="assets/gls-arrow-down.svg" [class.active]="isRequesterLocationDescendingSortingActive" alt="" title="Descending order">
            </span>
            <div class="resizer"></div>
          </th>
          <th class="pickupLocationColumnHeader" #th5 (mousedown)="startResize($event, th5)" (click)="onPickupLocationSortingClick()">
            Pick-up Location
            <span>
              <img class="gls-sorting-arrow-up" src="assets/gls-arrow-up.svg" [class.active]="isPickupLocationAscendingSortingActive" alt="" title="Ascending order">
              <img class="gls-sorting-arrow-down" src="assets/gls-arrow-down.svg" [class.active]="isPickupLocationDescendingSortingActive" alt="" title="Descending order">
            </span>
            <div class="resizer"></div>
          </th>
          <th class="deliveryLocationColumnHeader" #th6 (mousedown)="startResize($event, th6)" (click)="onDeliveryLocationSortingClick()">
            Delivery Location
            <span>
              <img class="gls-sorting-arrow-up" src="assets/gls-arrow-up.svg" [class.active]="isDeliveryLocationAscendingSortingActive" alt="" title="Ascending order">
              <img class="gls-sorting-arrow-down" src="assets/gls-arrow-down.svg" [class.active]="isDeliveryLocationDescendingSortingActive" alt="" title="Descending order">
            </span>
            <div class="resizer"></div>
          </th>
          <th #th7 (mousedown)="startResize($event, th4)">Order Status<div class="resizer"></div></th>
          <th class="settings-icon-container"></th>
          <!-- <th class="settings-icon-container"><img class="settings-icon" src="assets/settings-icon-white.png"></th> -->
        </tr>
      </thead>
      <tbody>
        <tr class="search-row">
          <td></td>
          <td><input type="text" class="search-input" placeholder="Search..."
            id="search-parcel-number"
            [(ngModel)]="parcelNumber"
            (keyup)="onInputChange($event)"
            #parcelNumberRef="ngModel"
            (keyup.enter)="updateTable()"
            maxlength="100"></td>
          <td>
            <mat-form-field id="search-pickup-date">
              <mat-date-range-input [rangePicker]="picker">
                <input id="searchRequestedPickupDateGt" matStartDate placeholder="Start date" [(ngModel)]="selectedRequestedPickupDateGt" (dateChange)="onChangePickUpDate()">
                <input id="searchRequestedPickupDateLte" matEndDate placeholder="End date" [(ngModel)]="selectedRequestedPickupDateLte" (dateChange)="onChangePickUpDate()">
              </mat-date-range-input>
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker [startAt]="selectedRequestedPickupDateGt" (closed)="adjustTextFieldWidth()"></mat-date-range-picker>
            </mat-form-field>
            <button class="reset-pickup-date-picker" (click)="resetPickupDatePicker()">x</button>
          </td>
          <td><input type="text" class="search-input" placeholder="Search..."
            id="search-requester-name"
            [(ngModel)]="requesterName"
            #requesterNameRef="ngModel"
            (keyup)="onInputChange($event)"
            (keyup.enter)="updateTable()"
            maxlength="100"></td>
          <td><input type="text" class="search-input" placeholder="Search..."
            id="search-requester-location"
            [(ngModel)]="requesterLocation"
            #requesterLocationRef="ngModel"
            (keyup)="onInputChange($event)"
            (keyup.enter)="updateTable()"
            maxlength="100"></td>
          <td><input type="text" class="search-input" placeholder="Search..."
            id="search-pickup-location"
            [(ngModel)]="pickupLocation"
            #pickupLocationRef="ngModel"
            (keyup)="onInputChange($event)"
            (keyup.enter)="updateTable()"
            maxlength="100"></td>
          <td><input type="text" class="search-input" placeholder="Search..."
            id="search-delivery-location"
            [(ngModel)]="deliveryLocation"
            #deliveryLocationRef="ngModel"
            (keyup)="onInputChange($event)"
            (keyup.enter)="updateTable()"
            maxlength="100"></td>
            <td class="status-multi-select">
              <app-multi-select-dropdown
                [dropdownItems]="orderStatusList"
                [selectPlaceHolder]="'Select Status'"
                [defaultSelectedItems]="selectedStatuses"
                (selectedOption)="onOrderStatusSelect($event)">
              </app-multi-select-dropdown>
            </td>
          <td></td>
        </tr>
        <ng-container *ngFor="let item of adjustedFilteredOrdersList; let i = index">
          <tr [attr.id]="'my-pickups-' + (i + 1)"
          [ngClass]="{'clickable-row': item['parcelDetails']?.length > 1, 'expanded-row': expandedRows[i]}"
          (click)="item['parcelDetails']?.length > 1 ? onOrderWithMultipleParcelNumbersClick(i) : null">
            <td class="options-icons-wrapper">
              <input type="checkbox" class="checkbox-input" #checkbox [(ngModel)]="item.checked" (click)="stopClickPropagation($event)" (change)="onCheckboxChange(item, checkbox)">
            </td>
            <td class="parcel-number-column">
              <div class="parcel-number-container">
                <ng-container *ngIf="item['parcelDetails'] && item['parcelDetails'].length === 1; else hasMultipleParcels">
                  {{ item['parcelDetails'][0]['parcelKey'] }}
                </ng-container>
              <ng-template #hasMultipleParcels>
                <ng-container *ngIf="item['parcelDetails'] && item['parcelDetails'].length > 1; else emptyField">
                  <img [src]="expandedRows[i] ? 'assets/gls_caret_up_white.png' : 'assets/gls_caret_down.png'" class="caret-icon">
                  <span class="with-caret">{{item['parcelDetails'][0]['parcelKey']}}</span>
                </ng-container>
                <ng-template #emptyField>
                </ng-template>
              </ng-template>
              </div>
            </td>
            <td>{{ item['pickUpDate'] | date:'dd.MM.yyyy'}}</td>
            <td>{{ item['requesterName']}}</td>
            <td>{{ item['requesterLocation']}}</td>
            <td>{{ item['pickupLocation']}}</td>
            <td>{{ item['deliveryLocation']}}</td>
            <td>{{ item['orderStatus']}}</td>
            <td class="options-icons-wrapper">
              <img class="view-detail-icon"  (click)="onSeeOrderDetailsClick(item)" src="assets/view-detail-icon-eye.svg">
              <!-- <img class="three-points-settings-icon" src="assets/three-points-settings-icon.png"> -->
            </td>
          </tr>
          <ng-container *ngIf="expandedRows[i]">
            <tr *ngFor="let parcel of item['parcelDetails']" class="sub-row">
              <td class="options-icons-wrapper">
                <input type="checkbox" class="checkbox-input" #checkbox [(ngModel)]="item.checked" (click)="stopClickPropagation($event)" (change)="onCheckboxChange(item, checkbox)">
              </td>
              <td>{{ parcel.parcelKey }}</td>
              <td>{{ item['pickUpDate'] | date:'dd.MM.yyyy'}}</td>
              <td>{{ item['requesterName']}}</td>
              <td>{{ item['requesterLocation']}}</td>
              <td>{{ item['pickupLocation']}}</td>
              <td>{{ item['deliveryLocation']}}</td>
              <td>{{ getParcelStatus(item, parcel.parcelKey) }}</td>
              <td class="options-icons-wrapper">
                <img class="view-detail-icon"  (click)="onSeeOrderDetailsClick(item)" src="assets/view-detail-icon-eye.svg">
                <!-- <img class="three-points-settings-icon" src="assets/three-points-settings-icon.png"> -->
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>

<!-- Temporary: Modal for popup of printing selected pickups -->
<div class="modal" *ngIf="showModalPrintChecked" (click)="onBackdropClick($event)">
  <div class="modal-content" (click)="onModalContentClick($event)">
    <div *ngIf="checkedOrdersListWithDetails.length === 0">No Pickups selected!</div>

    <div class="summary-title-container"><span class="material-symbols-outlined">summarize</span><span class="summary-title">Summary</span></div>
    <div class="summary-content">
      <div class="summary-message">
        <p class="summary-message" *ngIf="getOrdersWithCompleteStatus(checkedOrdersListWithDetails).ordersWithCompleteStatus.length === 1">
          <span class="material-symbols-outlined check-circle-cancel-icon">
            cancel
          </span>
          {{getOrdersWithCompleteStatus(checkedOrdersListWithDetails).ordersWithCompleteStatus.length}} Pickup with status COMPLETE will not be printed.
        </p>
      </div>
      <div class="summary-message">
        <p class="summary-message" *ngIf="getOrdersWithCompleteStatus(checkedOrdersListWithDetails).ordersWithCompleteStatus.length > 1">
          <span class="material-symbols-outlined check-circle-cancel-icon">
            cancel
          </span>
          {{getOrdersWithCompleteStatus(checkedOrdersListWithDetails).ordersWithCompleteStatus.length}} Pickups with status COMPLETE will not be printed.
        </p>
      </div>
      <div class="summary-message">
        <p class="summary-message" *ngIf="getOrdersWithCompleteStatus(checkedOrdersListWithDetails).ordersProperForPrintList.length ===1">
          <span class="material-symbols-outlined check-circle-icon">
            check_circle
          </span>
          {{getOrdersWithCompleteStatus(checkedOrdersListWithDetails).ordersProperForPrintList.length}} Pickup ready to print.
        </p>
      </div>
      <div class="summary-message">
        <p class="summary-message" *ngIf="getOrdersWithCompleteStatus(checkedOrdersListWithDetails).ordersProperForPrintList.length > 1">
          <span class="material-symbols-outlined check-circle-icon">
            check_circle
          </span>
          {{getOrdersWithCompleteStatus(checkedOrdersListWithDetails).ordersProperForPrintList.length}} Pickups ready to print.
        </p>
      </div>
    </div>
    <div class="format-selection">
      Select printing format:
      <div class="radio-group">
        <label for="pdfFormat">PDF</label>
        <input type="radio"
               id="pdfFormat"
               name="format"
               value="pdf"
               [(ngModel)]="selectedFormat"
               [checked]="true">

        <label for="zplFormat">ZPL</label>
        <input type="radio"
               id="zplFormat"
               name="format"
               value="zpl"
               [(ngModel)]="selectedFormat">
      </div>
    </div>
    <div class="modal-buttons-container">
      <button class="btn modal-button" [class.deactivated]="deactivatePrintButtonInModalBox" [disabled]="deactivatePrintButtonInModalBox" (click)="onPrintCheckedOrdersInModalBox()">Print</button>
      <button button class="btn modal-button" (click)="closeModal()">Cancel</button>
    </div>
  </div>
</div>

<div *ngIf="showSpinner" class="spinner-container">
  <div class="ring"></div>
  <div class="ring"></div>
  <div class="ring"></div>
  <span class="printing">Printing...</span>
</div>

<div *ngIf="messageVisible" class="modal">
  <div class="modal-content" (click)="onModalContentClick($event)">
    <div class="summary-title-container"><span class="material-symbols-outlined">summarize</span><span class="summary-title">Print status</span></div>
    <div class="summary-content">
      <div class="summary-message message-wrap" >
        <span class="material-symbols-outlined" [ngClass]="{'check-circle-icon': printSucessfull, 'check-circle-cancel-icon': !printSucessfull}">
          {{ printSucessfull ? 'check_circle' : 'cancel' }}
        </span>
        {{ messageText }}
      </div>
    </div>

    <div class="modal-buttons-container">
      <button class="btn modal-button" [class.deactivated]="deactivatePrintButtonInModalBox" [disabled]="deactivatePrintButtonInModalBox" (click)="copyToClipboard(messageText)">Copy message</button>
      <button button class="btn modal-button" (click)="closeModal()">Close</button>
    </div>
  </div>
</div>
