import { Component, OnInit, Inject } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest, EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Store } from '@ngrx/store';
import { setAccessToken } from 'src/app/store/auth.actions';
import { environment } from 'src/environments/environment';
import { protectedResources as b2cProtectedResources, AuthTypeValue as AuthTypeB2C } from 'src/app/auth-b2c-config';
import { msalConfigAd, protectedResources as adProtectedResources, AuthTypeValue as AuthTypeAD } from 'src/app/auth-ad-config';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  title = 'Login';
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  private readonly authType = 'auth-type';

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private broadcastService: MsalBroadcastService,
    private authService: MsalService,
    private router: Router,
    private auth: AuthService,
    private store: Store
  ) {
    const authType = localStorage.getItem(this.authType);

    if (authType !== null && authType === AuthTypeAD) {
      this.authService.instance = new PublicClientApplication(msalConfigAd);
      this.authService.instance.initialize();
    }
  }

  ngOnInit() {
    if (this.auth.isLoggedIn()) {
      this.router.navigateByUrl('/home');
    }

    this.broadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });

    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: EventMessage) => {
        if (result) {
          return this.handleAuthRedirect();
        }
      });

    this.authService.handleRedirectObservable().subscribe(() => {
      return this.handleAuthRedirect();
    });
  }

  private handleAuthRedirect() {
    if (this.authService.instance.getAllAccounts()[0]) {
      const tokenRequest = {
        account: this.authService.instance.getAllAccounts()[0],
        scopes: b2cProtectedResources.paxApi.scopes,
      };
      this.authService.instance
        .acquireTokenSilent(tokenRequest)
        .then((response) => {
          if (response.idToken) {
            localStorage.setItem('accessToken', response.idToken);
            this.store.dispatch(
              setAccessToken({ accessToken: response.idToken })
            );
            this.router.navigateByUrl('/home');
          }
        })
        .catch((error) => {
          console.error('handleRedirectObservable error:', error);
        });
    }
  }

  loginB2C() {
    localStorage.setItem(this.authType, AuthTypeB2C);

    const b2cAuthRequest: RedirectRequest = {
      authority: environment.authorities.signUpSignInB2C.authority,
      scopes: b2cProtectedResources.paxApi.scopes,
    };

    if (this.msalGuardConfig.authRequest) {
      this.authService.instance.loginRedirect({
        ...this.msalGuardConfig.authRequest,
        ...b2cAuthRequest,
      });
    } else {
      this.authService.instance.loginRedirect(b2cAuthRequest);
    }
  }

  async loginAD() {
    localStorage.setItem(this.authType, AuthTypeAD);

    this.authService.instance = new PublicClientApplication(msalConfigAd);

    const adAuthRequest: RedirectRequest = {
      authority: environment.authorities.signUpSignInAD.authority,
      scopes: adProtectedResources.adApi.scopes,
    };

    this.authService.instance = new PublicClientApplication(msalConfigAd);
    await this.authService.instance.initialize();
    await this.authService.instance.loginRedirect(adAuthRequest);
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
