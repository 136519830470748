import { createReducer, on } from '@ngrx/store';
import { setAccessToken, clearAccessToken } from './auth.actions';

export interface AuthState {
  accessToken: string;
}

export const initialState: AuthState = {
  accessToken: ''
};

export const authReducer = createReducer(
  initialState,
  on(setAccessToken, (state, { accessToken }) => ({ ...state, accessToken })),
  on(clearAccessToken, (state) => ({ ...state, accessToken: '' }))
);
