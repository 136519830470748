import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { CreatePaxOrderComponent } from './modules/components/create-pax-order/create-pax-order.component';
import { LoginComponent } from './modules/components/login/login.component';
import { NotFoundComponent } from './modules/components/not-found/not-found.component';
import { CreateOrderAllowedGuard } from './guards/create-order-allowed.guard';
import { IsPaxOrderViewerGuard } from './guards/is-pax-order-viewer.guard';
import { LandingPageComponent } from './modules/components/landing-page/landing-page.component';
import { MyPickupsViewComponent } from './modules/components/my-pickups-view/my-pickups-view.component';
import { MyOrdersViewComponent } from './modules/components/my-orders-view/my-orders-view.component';
import { MyPickupsAllowedGuard } from './guards/my-pickups-allowed.guard';
import { MyOrdersAllowedGuard } from './guards/my-orders-allowed.guard';
import { OrderDetailsComponent } from './modules/components/order-details/order-details.component';

const routes: Routes = [
  {
    path: 'home',
    canActivate: [AuthGuard],
    component: LandingPageComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'create-order',
    canActivate: [AuthGuard, CreateOrderAllowedGuard],
    component: CreatePaxOrderComponent
  },
  {
    path: 'my-pickups',
    canActivate: [AuthGuard, MyPickupsAllowedGuard],
    component: MyPickupsViewComponent
  },
  {
    path: 'my-orders',
    canActivate: [AuthGuard, MyOrdersAllowedGuard],
    component: MyOrdersViewComponent
  },
  {
    path: 'order-details',
    canActivate: [AuthGuard, MyOrdersAllowedGuard],
    component: OrderDetailsComponent
  },
  {
    path: 'edit-order',
    canActivate: [AuthGuard, MyOrdersAllowedGuard],
    component: CreatePaxOrderComponent
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: '**',
    component: NotFoundComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
