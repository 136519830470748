<div class="features-button-container">
  <p class="totalFoundItems"><span>{{totalFoundItems}}</span>Orders</p>
  <button *ngIf="isPaxOrderCreator" class="gls-button btn-primary" routerLink="/create-order" (click)="onNewOrderClick()">
    New Order &nbsp;
    <span class="material-symbols-outlined">
      box_edit
    </span>
  </button>
</div>
<div class="table-options-wrapper">
  <div class="alert alert-danger  alert-dismissible fade show" *ngIf="authorizationFailed"><strong>Authorization Failed!</strong></div>
  <div class="expandable-filter-area">
    <div class="input-wrapper">
      <input
        type="text"
        class="search-input requester-reference-search-input"
        placeholder="Requester Reference "
        id="search-requester-reference"
        [(ngModel)]="requesterReference"
        #requesterReferenceRef="ngModel"
        (keyup)="onInputChange($event)"
        (keyup.enter)="updateTable()"
      />
    </div>
  </div>
  <div class="show-active-filters-container">
    <div class="remove-all-filters-button" *ngIf="filterChips.length > 1" (click)="removeAllFilters()">Remove All Filters</div>
    <div *ngFor="let chip of filterChips" class="filter-chips" [ngClass]="{'hidden': chip.key === 'Remove All Filters'}">
      {{ chip.key }}<span *ngIf="chip.value">: {{ chip.value }}</span>
      <span>
        <img class="x-icon" src="assets/gls-xbp-x-icon.svg" (click)="removeFilterChip(chip)" (click)="stopEvent($event)">
      </span>
    </div>
  </div>
  <div class="show-active-sortings-container">
    <div class="remove-all-sortings-button" *ngIf="activeSortings.length > 1" (click)="removeAllSorting()">Remove All Sortings</div>
    <div *ngFor="let sort of activeSortings" class="sort-chips" [ngClass]="{'hidden': sort.key === 'Remove All Sortings'}">
      {{ sort.key }}
      <span *ngIf="sort.key !== 'Remove All Sortings'">
        <img *ngIf="sort.order === 'desc'" class="gls-sorting-arrow-down" src="assets/gls-arrow-down-black.svg" alt="" title="Descending order">
        <img *ngIf="sort.order === 'asc'" class="gls-sorting-arrow-up" src="assets/gls-arrow-up-black.svg" alt="" title="Ascending order">
      </span>
      <span>
        <img class="x-icon" src="assets/gls-xbp-x-icon.svg" (click)="removeSortingChips(sort)" (click)="stopEvent($event)">
      </span>
    </div>
  </div>
  <div class="pagination-wrapper pagination-wrapper-top">
    <div class="pagination-controls">
      <button class="pagination-button previous-button" (click)="goToFirstPage()" title="Go to first page">
        <span class="material-symbols-outlined goToFirstPageSecondArrow">
          arrow_left
        </span>
        <span class="material-symbols-outlined goToFirstPageFirstArrow">
          arrow_left
        </span>
      </button>
      <button class="pagination-button previous-button" (click)="goToPrevPage()">
        <span class="material-symbols-outlined">
          arrow_left
        </span>
      </button>
      <span class="page-number">{{ currentPage }} of {{ totalPages }}</span>
      <button class="pagination-button next-button" (click)="goToNextPage()">
        <span class="material-symbols-outlined">
          arrow_right
        </span>
      </button>
      <button class="pagination-button next-button" (click)="goToLastPage()" title="Go to last page">
        <span class="material-symbols-outlined goToLastPageFirstArrow">
          arrow_right
        </span>
        <span class="material-symbols-outlined goToLastPageSecondArrow">
          arrow_right
        </span>
      </button>
    </div>
  </div>
</div>
<div class="table-container">
  <table>
    <thead>
      <tr>
        <th class="parcelNumberColumnHeader" #th1 (mousedown)="startResize($event, th1)" (click)="onParcelNumberSortingClick()">
          Parcel Number
          <span>
            <img class="gls-sorting-arrow-up" src="assets/gls-arrow-up.svg" [class.active]="isParcelNumberAscendingSortingActive" alt="" title="Ascending order">
            <img class="gls-sorting-arrow-down" src="assets/gls-arrow-down.svg" [class.active]="isParcelNumberDescendingSortingActive" alt="" title="Descending order">
          </span>
          <div class="resizer"></div>
        </th>
        <th class="creationDateColumnHeader" #th2 (mousedown)="startResize($event, th2)" (click)="onCreationDateSortingClick()">
          Creation Date
          <span>
            <img class="gls-sorting-arrow-up" src="assets/gls-arrow-up.svg" [class.active]="isCreationDateAscendingSortingActive" alt="" title="Ascending order">
            <img class="gls-sorting-arrow-down" src="assets/gls-arrow-down.svg" [class.active]="isCreationDateDescendingSortingActive" alt="" title="Descending order">
          </span>
          <div class="resizer"></div>
        </th>
        <th #th2 (mousedown)="startResize($event, th2)">Pick-up Date<div class="resizer"></div></th>
        <th #th5 (mousedown)="startResize($event, th5)">Requester Name<div class="resizer"></div></th>
        <th class="requesterLocationColumnHeader" #th3 (mousedown)="startResize($event, th3)" (click)="onRequesterLocationSortingClick()">
          Requester Location
          <span>
            <img class="gls-sorting-arrow-up" src="assets/gls-arrow-up.svg" [class.active]="isRequesterLocationAscendingSortingActive" alt="" title="Ascending order">
            <img class="gls-sorting-arrow-down" src="assets/gls-arrow-down.svg" [class.active]="isRequesterLocationDescendingSortingActive" alt="" title="Descending order">
          </span>
          <div class="resizer"></div>
        </th>
        <th class="pickupLocationColumnHeader" #th4 (mousedown)="startResize($event, th4)" (click)="onPickupLocationSortingClick()">
          Pickup Location
          <span>
            <img class="gls-sorting-arrow-up" src="assets/gls-arrow-up.svg" [class.active]="isPickupLocationAscendingSortingActive" alt="" title="Ascending order">
            <img class="gls-sorting-arrow-down" src="assets/gls-arrow-down.svg" [class.active]="isPickupLocationDescendingSortingActive" alt="" title="Descending order">
          </span>
          <div class="resizer"></div>
        </th>
        <th class="deliveryLocationColumnHeader" #th5 (mousedown)="startResize($event, th5)" (click)="onDeliveryLocationSortingClick()">
          Delivery Location
          <span>
            <img class="gls-sorting-arrow-up" src="assets/gls-arrow-up.svg" [class.active]="isDeliveryLocationAscendingSortingActive" alt="" title="Ascending order">
            <img class="gls-sorting-arrow-down" src="assets/gls-arrow-down.svg" [class.active]="isDeliveryLocationDescendingSortingActive" alt="" title="Descending order">
          </span>
          <div class="resizer"></div>
        </th>
        <th #th4 (mousedown)="startResize($event, th4)">Order Status<div class="resizer"></div></th>
        <th class="settings-icon-container"></th>
        <!-- <th class="settings-icon-container"><img class="settings-icon" src="assets/settings-icon-white.png"></th> -->
      </tr>
    </thead>
    <tbody>
      <tr class="search-row">
        <td><input type="text" class="search-input" placeholder="Search..."
          id="search-parcel-number"
          [(ngModel)]="parcelNumber"
          (keyup)="onInputChange($event)"
          #parcelNumberRef="ngModel"
          (keyup.enter)="updateTable()"></td>
        <td>
          <mat-form-field id="search-creation-date">
            <mat-date-range-input [rangePicker]="picker">
              <input id="searchRequestedCreationDateGt" matStartDate placeholder="Start date" [(ngModel)]="selectedRequestedCreationDateGt" (dateChange)="onChangeCreationDate()">
              <input id="searchRequestedCreationLte" matEndDate placeholder="End date" [(ngModel)]="selectedRequestedCreationDateLte" (dateChange)="onChangeCreationDate()">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker [startAt]="selectedRequestedCreationDateGt" (closed)="adjustTextFieldWidth()"></mat-date-range-picker>
          </mat-form-field>
          <button class="reset-pickup-date-picker" (click)="resetCreationDatePicker()">x</button>
        </td>
        <td>
          <mat-form-field id="search-pickup-date">
            <mat-date-range-input [rangePicker]="pickupPicker">
              <input id="searchRequestedPickupDateGt" matStartDate placeholder="Start date" [(ngModel)]="selectedRequestedPickupDateGt" (dateChange)="onChangePickUpDate()">
              <input id="searchRequestedPickupDateLte" matEndDate placeholder="End date" [(ngModel)]="selectedRequestedPickupDateLte" (dateChange)="onChangePickUpDate()">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="pickupPicker"></mat-datepicker-toggle>
            <mat-date-range-picker #pickupPicker [startAt]="selectedRequestedPickupDateGt" (closed)="adjustTextFieldWidth()"></mat-date-range-picker>
          </mat-form-field>
          <button class="reset-pickup-date-picker" (click)="resetPickupDatePicker()">x</button>
        </td>
        <td><input type="text" class="search-input" placeholder="Search..."
          id="search-requester-name"
          [(ngModel)]="requesterName"
          #requesterNameRef="ngModel"
          (keyup)="onInputChange($event)"
          (keyup.enter)="updateTable()"></td>
        <td><input type="text" class="search-input" placeholder="Search..."
          id="search-requester-location"
          [(ngModel)]="requesterLocation"
          #requesterLocationRef="ngModel"
          (keyup)="onInputChange($event)"
          (keyup.enter)="updateTable()"></td>
        <td><input type="text" class="search-input" placeholder="Search..."
          id="search-pickup-location"
          [(ngModel)]="pickupLocation"
          #requesterLocationRef="ngModel"
          #pickupLocationRef="ngModel"
          (keyup)="onInputChange($event)"
          (keyup.enter)="updateTable()"></td>
        <td><input type="text" class="search-input" placeholder="Search..."
          id="search-delivery-location"
          [(ngModel)]="deliveryLocation"
          #deliveryLocationRef="ngModel"
          (keyup)="onInputChange($event)"
          (keyup.enter)="updateTable()"></td>
          <td class="status-multi-select">
            <app-multi-select-dropdown
              [dropdownItems]="orderStatusList"
              [selectPlaceHolder]="'Select Status'"
              [defaultSelectedItems]="selectedStatuses"
              (selectedOption)="onOrderStatusSelect($event)">
            </app-multi-select-dropdown>
          </td>
        <td></td>
      </tr>
      <tr class="spacer-row"><td colspan="9"></td></tr>
      <ng-container *ngFor="let item of adjustedFilteredOrdersList; let i = index">
        <tr [attr.id]="'my-orders-' + (i + 1)"
            [ngClass]="{'clickable-row': item['parcelDetails']?.length > 1, 'expanded-row': expandedRows[i]}"
            (click)="item['parcelDetails']?.length > 1 ? onOrderWithMultipleParcelNumbersClick(i) : null">
          <td class="parcel-number-column">
            <div class="parcel-number-container">
              <ng-container *ngIf="item['parcelDetails'] && item['parcelDetails'].length === 1; else checkParcelNumber">
                {{ item['parcelDetails'][0]['parcelKey'] }}
              </ng-container>
              <ng-template #checkParcelNumber>
                <ng-container *ngIf="item['parcelDetails'] && item['parcelDetails'].length > 1; else hasNoParcelNumberYet">
                  <img [src]="expandedRows[i] ? 'assets/gls_caret_up_white.png' : 'assets/gls_caret_down.png'" class="caret-icon">
                  <span class="with-caret">{{ item['parcelDetails'][0]['parcelKey'] }}</span>
                </ng-container>
                <ng-template #hasNoParcelNumberYet>
                  <ng-container>
                    <!-- <a href="#"><span class="pendingParelNumber">{{ item['parcelNumber'] }}</span></a> -->
                    <a class="pendingParelNumber" (click)="onPendingParcelClick(item)">{{ item['parcelNumber'] }}</a>
                  </ng-container>
                  <ng-template #emptyField>
                  </ng-template>
                </ng-template>
              </ng-template>
            </div>
          </td>
          <td>{{ item['creationDate'] | date:'dd.MM.yyyy'}}</td>
          <td>{{ item['pickUpDate'] | date:'dd.MM.yyyy'}}</td>
          <td>{{ item['requesterName']}}</td>
          <td>{{ item['requesterLocation']}}</td>
          <td>{{ item['pickupLocation']}}</td>
          <td>{{ item['deliveryLocation']}}</td>
          <td>{{ item['orderStatus']}}</td>
          <td class="options-icons-wrapper">
            <img class="three-points-settings-icon" (click)="onOptionsClick($event, i)" src="assets/three-points-settings-icon.png" (click)="stopEvent($event)">
            <div *ngIf="popoverIndex === i" class="popup">
              <div #popupItem class="popup-item" *ngIf="item['parcelNumber'] === 'Pending' && isPaxOrderCreator" (click)="onEditOrderClick(item)">
                <img class="edit-order-icon" src="assets/edit-order-icon.svg" (click)="stopEvent($event)">
                <span class="popup-label">Edit</span>
              </div>
              <div #popupItem class="popup-item" (click)="onSeeOrderDetailsClick(item)">
                <img class="popup-icon view-detail-icon" src="assets/view-detail-icon-eye.svg">
                <span class="popup-label">Details</span>
              </div>
              <div #popupItem class="popup-item" *ngIf="isPaxOrderCreator" (click)="useExistingOrderAsTemplate(item)">
                <img class="popup-icon copy-to-form-icon" src="assets/copy-to-form-icon.svg">
                <span class="popup-label">Use as template</span>
              </div>
            </div>
          </td>
        </tr>
        <ng-container *ngIf="expandedRows[i]">
          <tr *ngFor="let parcel of item['parcelDetails']" class="sub-row">
            <td>{{ parcel.parcelKey }}</td>
            <td>{{ item['creationDate'] | date:'dd.MM.yyyy'}}</td>
            <td>{{ item['pickUpDate'] | date:'dd.MM.yyyy'}}</td>
            <td>{{ item['requesterName']}}</td>
            <td>{{ item['requesterLocation']}}</td>
            <td>{{ item['pickupLocation']}}</td>
            <td>{{ item['deliveryLocation']}}</td>
            <td>{{ getParcelStatus(item, parcel.parcelKey) }}</td>
            <td class="options-icons-wrapper">
              <!-- <img class="three-points-settings-icon" (click)="onOptionsClick($event, i)" src="assets/three-points-settings-icon.png" (click)="stopEvent($event)">
              <div *ngIf="popoverIndex === i" class="popup">
                <div #popupItem class="popup-item" (click)="onSeeOrderDetailsClick(item)">
                  <img class="popup-icon view-detail-icon" src="assets/view-detail-icon-eye.svg">
                  <span class="popup-label">Details</span>
                </div>
                <div #popupItem class="popup-item" (click)="useExistingOrderAsTemplate(item)">
                  <img class="popup-icon copy-to-form-icon" src="assets/copy-to-form-icon.png">
                  <span class="popup-label">Use as template</span>
                </div>
                <div #popupItem class="popup-item" *ngIf="item['parcelNumber'] === 'Pending'" (click)="onEditOrderClick(item)">
                  <img class="edit-order-icon" src="assets/edit-order-icon.png" (click)="stopEvent($event)">
                  <span class="popup-label">Edit</span>
                </div>
              </div> -->
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
</div>
<div class="pagination-wrapper">
  <div class="pagination-controls">
    <button class="pagination-button previous-button" (click)="goToFirstPage()" title="Go to first page">
      <span class="material-symbols-outlined goToFirstPageSecondArrow">
        arrow_left
      </span>
      <span class="material-symbols-outlined goToFirstPageFirstArrow">
        arrow_left
      </span>
    </button>
    <button class="pagination-button previous-button" (click)="goToPrevPage()">
      <span class="material-symbols-outlined">
        arrow_left
      </span>
    </button>
    <span class="page-number">{{ currentPage }} of {{ totalPages }}</span>
    <button class="pagination-button next-button" (click)="goToNextPage()">
      <span class="material-symbols-outlined">
        arrow_right
      </span>
    </button>
    <button class="pagination-button next-button" (click)="goToLastPage()" title="Go to last page">
      <span class="material-symbols-outlined goToLastPageFirstArrow">
        arrow_right
      </span>
      <span class="material-symbols-outlined goToLastPageSecondArrow">
        arrow_right
      </span>
    </button>
  </div>
</div>

<app-popup-message
  #alertPopup
></app-popup-message>
