import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { selectAccessToken } from 'src/app/store/auth.selectors';
import { clearAccessToken } from 'src/app/store/auth.actions';
import { MsalService } from '@azure/msal-angular';
import { protectedResources } from 'src/app/auth-b2c-config';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CheckUserRolesService {
  isGroupAccount: boolean = false;
  isPaxOrderViewer: boolean = false;
  isPaxOrderCreator: boolean = false;
  isPaxOrderPrinter: boolean = false;
  notAuthorisedForEnvironment: boolean = false
  environment: string = '';
  tenantKey: string = '';
  allowedLocationCodes: string[] = [];
  private accessToken: string | null = null;
  private tokenExpirationTime: any = '';

  private envIndex: number  = 2;
  private roleIndex: number  = 3;
  private tenantIndex: number = 4; //None group have index 5 as well (i.e. GLS (4) _ DE (5))
  private locationIndex: number = 6;

  

  constructor(private router: Router,
              private store: Store,
              private authService: MsalService) {
    this.initAccessTokenSubscription();
  }


  /** Initialize subscription to access token */
  private initAccessTokenSubscription(): void {
    this.store.select(selectAccessToken).pipe(
      tap(accessToken => {
        if (accessToken) {
          this.accessToken = accessToken;
          this.extractRolesFromToken();
          this.extractTokenExpirationTime();
        }
      })
    ).subscribe();
  }

  /** Helper function to get the tenant key without using async functions */
  private extractRolesFromToken() {
    if (this.accessToken !== null) {
      const jwtData = this.accessToken.split('.')[1];
      const decodedJwtJsonData = window.atob(jwtData);
      const decodedJwtData = JSON.parse(decodedJwtJsonData);
      const issuer = decodedJwtData['iss'];
      let roles = [];

      // if(issuer.toLowerCase().includes('b2clogin')) {
      //   roles = decodedJwtData['roles'].filter(role => role.toLowerCase().includes('xbp_pax'));
      // } else {
        roles = decodedJwtData['groups'].filter(role => role.toLowerCase().includes('xbp_pax'));
      // }

      //TODO Remove +1 and simplify after b2 = AD 

      if(issuer.toLowerCase().includes('https://login.microsoftonline.com')) {
        roles
        .forEach(role => {
          const roleComponents = role.split('_');
          this.setTenantPermission(roleComponents, this.tenantIndex + 1); // +1 since env variable was added
          const locationCode = roleComponents[this.locationIndex + 1]; // +1 since env variable was added
          if (locationCode && !this.allowedLocationCodes.includes(locationCode)) {
            this.allowedLocationCodes.push(locationCode);
          }
          // Check for print permissions
          this.setPermissions(roleComponents, this.roleIndex + 1); // +1 since env variable was added
          this.environment = roleComponents[this.envIndex];
        });

        this.validateEnvironment(this.environment)
      } else{
        roles
        .forEach(role => {
          const roleComponents = role.split('_');
          this.setTenantPermission(roleComponents, this.tenantIndex);
          const locationCode = roleComponents[this.locationIndex];
          if (locationCode && !this.allowedLocationCodes.includes(locationCode)) {
            this.allowedLocationCodes.push(locationCode);
          }
          // Check for print permissions
          this.setPermissions(roleComponents, this.roleIndex);
        });
      }
    }
  }

  private validateEnvironment(tokenEnvironment: String) {
    console.log(tokenEnvironment);
    console.log(environment.baseUrl);
    if(environment.baseUrl.toLowerCase().includes("dev") || environment.baseUrl.toLowerCase().includes("qas")) {
      if(tokenEnvironment.toLowerCase() !== "test") {
        this.preventUnauthorisedAccess();
      }
    } else {
      if(tokenEnvironment.toLowerCase() !== "prod") {
        this.preventUnauthorisedAccess();
      }
    }
  }

  private preventUnauthorisedAccess() {
    this.isGroupAccount = false;
    this.isPaxOrderViewer = false;
    this.isPaxOrderCreator = false;
    this.isPaxOrderPrinter = false;
    this.router.navigate(['/home']);
    this.notAuthorisedForEnvironment = true;
  }

  private extractTokenExpirationTime() {
    if (this.accessToken !== null) {
      const jwtData = this.accessToken.split('.')[1];
      const decodedJwtJsonData = window.atob(jwtData);
      const decodedJwtData = JSON.parse(decodedJwtJsonData);
      this.tokenExpirationTime = decodedJwtData['exp'];
    }

    if (this.tokenExpirationTime) {
      const expirationDate = new Date(this.tokenExpirationTime * 1000);
      const currentDate = new Date();
      const timeDifference = expirationDate.getTime() - currentDate.getTime();

      if (timeDifference > 0) {
        setTimeout(() => {
          this.clearTokenAndRedirect();
        }, timeDifference);
      } else {
        this.clearTokenAndRedirect();
      }

      const minutesUntilExpiration = Math.floor(timeDifference / 1000 / 60);
      console.log(`Token expires in ${minutesUntilExpiration} minutes.`);
    }
  }

  private clearTokenAndRedirect() {
    this.authService.logoutRedirect({ postLogoutRedirectUri: protectedResources.paxApi.endpoint });
    localStorage.removeItem('accessToken');
    this.accessToken = null;
    this.store.dispatch(clearAccessToken());
    this.router.navigate(['/login']);
  }

  private setTenantPermission(roleComponents: any, index: number) {
    if (roleComponents[index]?.toLowerCase().includes("group")) {
      this.isGroupAccount = true;
    } else {
      this.tenantKey = `${roleComponents[index]}_${roleComponents[index + 1]}`;
    }
  }

  private setPermissions(roleComponents: any, index: number) {
    // Check for printer permissions
    if (roleComponents[index]?.toLowerCase().includes("printer")) {
      this.isPaxOrderPrinter = true;
    }
    // Check for viewer permissions
    if (roleComponents[index]?.toLowerCase().includes("viewer")) {
      this.isPaxOrderViewer = true;
    }
    // Check for creator permissions
    if (roleComponents[index]?.toLowerCase().includes("creator")) {
      this.isPaxOrderCreator = true;
    }
  }

  /** Getter for tenantKey */
  getTenantKey(): string {
    return this.tenantKey;
  }

  /** Getter for isPrintOptionsAllowed */
  getIsPrintOptionsAllowed(): boolean {
    return this.isPaxOrderPrinter;
  }

  /** Getter for allowedLocationCodes */
  getAllowedLocationCodes(): string[] {
    return this.allowedLocationCodes;
  }

  /** Getter for isPaxOrderViewer */
  getIsPaxOrderViewer(): boolean {
    return this.isPaxOrderViewer;
  }

  /** Getter for isPaxOrderCreator */
  getIsPaxOrderCreator(): boolean {
    return this.isPaxOrderCreator;
  }

  /** Getter for isPaxOrderPrinter */
  getIsPaxOrderPrinter(): boolean {
    return this.isPaxOrderPrinter;
  }

  getIsGroupAccount(): boolean {
    return this.isGroupAccount;
  }
}
