<form>
  <div class="multi-select-container">
    <div class="form-group">
      <div id="multiselect" class="multiselect">
        <div class="selectContainer">
        <div class="selectBox" (click)="showCheckboxes()">
          <select class="form-select">
            <option>{{ selectPlaceHolder }}</option>
          </select>
          <div class="overSelect" #multiselect></div>
        </div>
      </div>
      <div class="dropDownContainer" id="dropDownContainerId">
        <label for="select-all" *ngIf="expanded">
          <input id="select-all" type="button" (click)="selectAllToggle()" [value]=" allCheckboxesSelected ? 'Cancel all' : 'Select all' " />
        </label>
        <div id="checkboxes">
          <div *ngFor="let role of dropdownItems">
            <label for="{{role}}" (click)="CheckIfAllAreSelected()">
            <input class="checkbox" type="checkbox" id="{{role}}" (change)="onSelect($event)" [checked]="defaultSelectedItems.includes(role)"/>{{ role }}</label>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</form>
