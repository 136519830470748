import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from '../modules/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.authService.isLoggedIn().pipe(
      take(1), // Take 1 to complete the observable immediately after receiving the first value
      map(isLoggedIn => {
        if (!isLoggedIn) {
          // Navigate to the login page if not logged in
          this.router.navigateByUrl('/login'); // Ensure redirection to login
          return false;
        }
        return true;
      })
    );
  }
}
